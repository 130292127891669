import React, { useState, useEffect } from 'react';
import { LikeAProduct, Posting } from './PointsTypes';
import { UserAuth } from '../Context';

import { collection, query, getDocs, orderBy } from 'firebase/firestore';
import { db } from '../FirebaseConfig';

const pointsComponents = {
  posting: Posting,
  likeAProduct: LikeAProduct,
};

function PointsStats() {
  const { user } = UserAuth();
  const [pointsLog, setPointsLog] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch points log from Firestore
  useEffect(() => {
    const fetchPointsLog = async () => {
      if (!user) return;

      try {
        const pointsLogRef = collection(db, 'users', user.uid, 'pointsLog');
        const pointsLogQuery = query(pointsLogRef, orderBy('timestamp', 'desc'));
        const querySnapshot = await getDocs(pointsLogQuery);
        const pointsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        
        setPointsLog(pointsData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching points log:', error);
        setLoading(false);
      }
    };

    fetchPointsLog();
  }, [user]);

  if (loading) {
    return (
      <div className="spinner-grow" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    );
  }

  return (
    <div className="card">
      {/* Card header */}
      <div className="card-header border-0 pb-0">
        <h5 className="card-title">Points</h5>
      </div>

      <div className="card-body">
        <div className="timeline">
          {pointsLog.length > 0 ? (
            pointsLog.map((log) => {
              const Component = pointsComponents[log.type];
              return Component ? <Component key={log.id} {...log} /> : null;
            })
          ) : (
            <p>No activity yet.</p>
          )}
        </div>
      </div>

      {/* <div className="card-footer border-0 py-3 text-center position-relative d-grid pt-0">
        <a href="#!" role="button" className="btn btn-sm btn-loader btn-primary-soft" data-bs-toggle="button" aria-pressed="true">
          <span className="load-text">Load more activity</span>
          <div className="load-icon">
            <div className="spinner-grow spinner-grow-sm" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </a>
      </div> */}
    </div>
  );
}

export default PointsStats;
