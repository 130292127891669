import {
  collection,
  doc,
  getDoc,
  getDocs,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { db } from "../FirebaseConfig";
import { UserAuth } from "../Context";
import { toast } from "react-toastify";
import { toastOptions } from "../lib/toastOptions";
function CategorySelection() {
  const { user } = UserAuth();
  const [gender, setGender] = useState("male");
  const [categories, setCategories] = useState([]);
  const [primaryCategories, setPrimaryCategories] = useState({}); //This will have all the secondary
  const [visibleCategories, setVisibleCategories] = useState({
    "Ready to Wear": true,
    "Western Wear": true,
    Unstitched: true,
  });
  // CATEGORIES
  const fetchCategories = async () => {
    try {
      const categoriesCol = collection(db, "universalCategories");
      const categoriesSnapshot = await getDocs(categoriesCol);
      let genderArray = [];
      categoriesSnapshot.forEach((doc) => {
        let GenderData = doc.data();
        genderArray.push(GenderData);
      });
      // console.log("GenderData", genderArray);
      let maleCategories = genderArray[0].subCategories;
      // console.log("Male", maleCategories);
      let femaleCategories = genderArray[1].subCategories;
      // console.log("Female", femaleCategories);
      if (gender === "male") {
        setPrimaryCategories(maleCategories);
        // setSecondaryCategories(femaleCategories);
      } else if (gender === "female") {
        setPrimaryCategories(femaleCategories);
      }
    } catch (error) {
      console.error("Error fetching brand display names:", error);
    }
  };
  //category handlers
  const handleCategoryClick = (categoryKey, category) => {
    // console.log(categoryKey, category);
    let select = {};
    select[categoryKey] = category;
    // console.log(select);
    // Check if the category is already added
    const isAlreadyAdded = categories?.some((item) =>
      areObjectsEqual(item, categoryKey, category)
    );
    //If added then remove
    if (isAlreadyAdded) {
      setCategories((prevCategory) =>
        prevCategory.filter(
          (item) => !areObjectsEqual(item, categoryKey, category)
        )
      );
    } else {
      setCategories((prevCategory) => [...prevCategory, select]);
    }
  };
  //Check if both objects are equal
  const areObjectsEqual = (obj1, categoryKey, category) => {
    let obj2 = {};
    obj2[categoryKey] = category;
    // console.log(obj2)
    return (
      Object.keys(obj1).length === Object.keys(obj2).length &&
      Object.keys(obj1).every((key) => obj1[key] === obj2[key])
    );
  };
  const toggleCategoryVisibility = (categoryKey) => {
    // console.log(visibleCategories)
    setVisibleCategories((prevState) => ({
      ...prevState,
      [categoryKey]: !prevState[categoryKey],
    }));
  };

  useEffect(() => {
    if (gender === "") return;
    const fetchData = async () => {
      //function call
      await fetchCategories();
    };
    fetchData();
    // eslint-disable-next-line
  }, [gender]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const userDocRef = doc(db, "users", user.uid);
      await updateDoc(userDocRef, {
        favCategories: categories,
      });
      console.log("User profile updated successfully");
      toast.success("Profile updated successfully", toastOptions);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        if (user && user.uid) {
          const userDocRef = doc(db, "users", user.uid);
          const userDocSnapshot = await getDoc(userDocRef);

          if (userDocSnapshot.exists()) {
            const userDataFromDb = userDocSnapshot.data();
            console.log(userDataFromDb.birthday);
            setCategories(userDataFromDb.favCategories);
            setGender(userDataFromDb.gender);
          }
        }
      } catch (error) {
        console.error("Error fetching user data from database", error);
      }
    };

    fetchUserData();
  }, [user]);
  return (
    <div>
      <Card>
        <Card.Header className="border-0 pb-0">
          <h4>Categories</h4>
        </Card.Header>
        <Card.Body className="pt-0 ">
          {/* Brands */}
          <form onSubmit={(e) => handleSubmit(e)}>
            {/* Categories */}
            <div>
              {Object.keys(primaryCategories).map((categoryKey) => (
                <div
                  key={categoryKey}
                  className="mt-1 d-flex flex-column align-items-start"
                >
                  <li
                    className="fs-6 text-mute my-2"
                    style={{
                      listStyleType: "none",
                    }}
                  >
                    <label>
                      <b>{categoryKey}</b>
                    </label>
                  </li>
                  {visibleCategories[categoryKey] && (
                    <ul className="list-inline mb-0 d-flex flex-wrap gap-1">
                      {primaryCategories[categoryKey].map((item, index) => (
                        <li
                          key={index}
                          onClick={() =>
                            handleCategoryClick(categoryKey, item.name)
                          }
                        >
                          <div
                            className={`btn btn-outline-light brand-button btn-sm ${
                              categories?.some((element) =>
                                areObjectsEqual(element, categoryKey, item.name)
                              )
                                ? "bg-light btn-light"
                                : ""
                            }`}
                          >
                            {item.displayName}
                          </div>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              ))}
            </div>
            <div className="text-end">
              <button type="submit" className="btn btn-sm btn-primary mb-0">
                Save changes
              </button>
            </div>
          </form>
        </Card.Body>
      </Card>
    </div>
  );
}

export default CategorySelection;
