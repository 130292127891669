import React from 'react';
import { useNavigate } from 'react-router-dom';

function SearchStyle() {
  const navigate = useNavigate();

  // Function to handle navigation and fetching products
  const handleItemClick = async (subSubCategory) => {
    // Navigate to the route for the selected item
    navigate(`/${subSubCategory}`);

  };

  return (
    <div className="col-sm-6 col-lg-12">
      <div className="card m-1">
        <div className="card-header pb-0 border-0">
          <h5 className="card-title mb-0">Searching something new?</h5>
        </div>
        <div className="card-body">
          <ul className="list-inline mb-0 d-flex flex-wrap gap-2">
            <li className="list-inline-item m-0">
              <button className="btn btn-outline-light btn-sm" onClick={() => handleItemClick('1-Pc')}>1 Piece</button>
            </li>
            <li className="list-inline-item m-0">
              <button className="btn btn-outline-light btn-sm" onClick={() => handleItemClick('2-Pc')}>2 Piece</button>
            </li>
            <li className="list-inline-item m-0">
              <button className="btn btn-outline-light btn-sm" onClick={() => handleItemClick('3-Pc')}>3 Piece</button>
            </li>
            <li className="list-inline-item m-0">
              <button className="btn btn-outline-light btn-sm" onClick={() => handleItemClick('Tops')}>Kurtas & Tops</button>
            </li>
            <li className="list-inline-item m-0">
              <button className="btn btn-outline-light btn-sm" onClick={() => handleItemClick('Stoles')}>Dupattas & Shawls</button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default SearchStyle;
