import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import useModalStore from "../StatesStore/ModalStore";
import { dataFavourite, titleFavourite } from "../Content/ModalContent";
import { db } from "../FirebaseConfig";
import {
  collection,
  serverTimestamp,
  getDoc,
  doc,
  updateDoc,
  setDoc,
  arrayUnion,
  increment,
  deleteDoc,
} from "firebase/firestore";
import { toast } from "react-toastify";
import { toastOptions } from "../lib/toastOptions";
import uuid4 from "uuid4";
import useFavStore from "../StatesStore/FavStore";
import useLogActivity from "../hooks/user/useLogActivity";



function AddOrRemoveFavourites({ product, componentName, user,setFavouritesCount, type }) {
  const { openModal } = useModalStore();
  // const [isFavourite, setIsFavourite] = useState(false);
  const {  toggleFavorite } = useFavStore();
  const favorites = useFavStore((state) => state.favorites || []);
  // const addFavoriteProduct = useFavStore((state) => state.addFavorite);
  // const removeFavoriteProduct = useFavStore((state) => state.removeFavorite);
  // const favoriteProduct = useFavStore((state) => state.favorites);
  const isFavorite = favorites.includes(product?.id);
  const setFavorites = useFavStore((state) => state.setFavorites);
  console.log("favorites from ",favorites);
  
  const [isFavourite, setIsFavourite] = useState(false);
  const { logActivity } = useLogActivity(user?.uid);

  useEffect(() => {
    const checkFavoriteStatus = async () => {
      if (user && product) {
        const userRef = doc(db, "users", user.uid);
        const favoritesRef = doc(collection(userRef, "shoppingList"), "Favorites");
        const productRef = doc(collection(favoritesRef, "products"), product.id);
        const productDoc = await getDoc(productRef);
        setIsFavourite(productDoc.exists());
        if (productDoc.exists()) {
          // Update Zustand store's favorites if the product exists
          setFavorites(product.id); // Update favorites in the store
        } 
      }
    };

    checkFavoriteStatus();
  }, [user, product]);

  const handleClick = () => {
    if (!user) {
      openModal("SignInModal", "", "");
      return;
    }

    toggleFavorite(product, user,setFavouritesCount);
    setIsFavourite(!isFavourite);

    const activityData = {
      productId: product.id,
      supplier: product.supplier,
      category: product.category,
      subCategory: product.subCategory,
      subSubCategory: product.subSubCategory,
      newPrice: product.newPrice,
      discount: product.discount,
      type: "productToFavourites",
    };

    logActivity(activityData);

  };

  const iconClass = componentName === "productDescriptions" ? "me-1" : "";
  const iconClass1 = componentName === "productDescriptions" ? "icon-md" : "";

  return (
    <Link
      className={`nav-link ${
        componentName === "productDescriptions" ? "" : "icon-sm"
      } m-0 p-0 nav-item`}
      to=""
      // onClick={handleToggleFavorite}
      onClick={handleClick}
    >
      {!user && (
        <Link to="" className={`nav-link`} onClick={handleClick}>
          <i className={`bi bi-star fs-6 ${iconClass}`}></i>
          {/* {componentName === "productDescriptions" && (
            <span style={{ whiteSpace: "nowrap" }}>Add to Favorites</span>
          )} */}
        </Link>
      )}

      {user && (
        <>
          <i
            className={`${
              (isFavorite)
                ? `fa-solid fa-star fs-6 ${iconClass}`
                : `bi bi-star fs-6 ${iconClass}`
            }`}
          ></i>
          {/* {componentName === "productDescriptions" && (
            <span style={{ whiteSpace: "nowrap", fontSize: "13px" }}></span>
          )} */}
        </>
      )}
    </Link>
  );
}

export default AddOrRemoveFavourites;
