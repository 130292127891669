import React, { useEffect, useRef, useState } from "react";
import TestBrandCard from "./TestBrandCard";
import TestProductCard from "./TestProductCard";
import TestPromotionCard from "./TestPromotionCard";
import TestUserCard from "./TestUserCard";
import testdata from "./testData.json";
import Masonry from "masonry-layout";
import imagesLoaded from "imagesloaded";
import useFetch from "../../hooks/useFetch";
import "./style.css";
import TestProductCard2 from "./TestProductCard2";
import ShareFeed from "../../NewsFeed/ShareFeed";
import { UserAuth } from "../../Context";
import RecentlyOnlineUsers from "../../components/RecentlyOnlineUsers";
import NotificationOnFeed from "../../NewsFeed/NotificationOnFeed";
import RecentlyJoinedUsers from "../../components/RecentlyJoinedUsers";
import ShoppingListPostCard from "../../UserPosts/ShopPost/ShoppingListPostCard";
export default function NewsFeed() {
  const [masonry, setmasonry] = useState();
  const [newsFeedItems, setNewsFeedItems] = useState([]);
  const { user } = UserAuth();

  //api endpoint for loggedout user
  let url = process.env.REACT_APP_API + `/api/logoutFeed`;
  const { data, isLoading } = useFetch(url);
  // console.log("Fro TestMasonry The posts are", data);

  // Invoke Masonry Function
  function initMasonry() {
    var elem = document.querySelector(".grid");
    var grid = new Masonry(elem, {
      itemSelector: ".grid-item",
      columnWidth: ".grid-sizer",
      transitionDuration: "0.4s",
      stagger: 20,
    });
    //Update masonry cards as each image loads

    imagesLoaded(elem).on("progress", function() {
      // layout Masonry after each image loads
      grid.layout();
    });
    setmasonry(grid);
  }

  //Handles Expanded Effect on Click
  const handleClick = (event) => {
    const elem = event.currentTarget.parentNode.parentNode;

    // console.log(elem);
    if (!elem.classList.contains("grid-item")) {
      return;
    }
    // console.log(event.currentTarget);
    elem.classList.toggle("grid-item--width2");
    masonry.layout();
    if (elem.classList.contains("grid-item--width2")) {
      setTimeout(() => {
        elem.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
        // masonry.layout();
      }, 600);
    }
  };

  //Update Masonry upon setting NewsFeed
  useEffect(() => {
    if (isLoading) return;
    initMasonry();
    // console.log("masonry invoked");
  }, [newsFeedItems]);

  //fetch NewsFeed Items
  useEffect(() => {
    setNewsFeedItems(data || []); // Use fetched data directly for logged-out users
  }, [data]);

  //Spinner
  if (isLoading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }
  return (
    <div className="px-3">
      <div className="grid">
        <div className="grid-sizer"></div>
        {user && (
          <>
            <div className="grid-item">
              <ShareFeed />
            </div>
            <div className="grid-item grid-item--width2">
              <ShoppingListPostCard />
            </div>
            <div className="grid-item">
              <RecentlyOnlineUsers />
            </div>
            <div className="grid-item grid-item--width2">
              <NotificationOnFeed />
            </div>
          </>
        )}
        {newsFeedItems.map((item, index) => {
          // console.log("item", item);
          if (index === 5) {
            return (
              <div className="grid-item">{user && <RecentlyJoinedUsers />}</div>
            );
          }
          if (item.by == "brand") {
            return (
              <div className="grid-item">
                <TestBrandCard post={item} handleClick={handleClick} />
              </div>
            );
          } else if (item.by == "product") {
            return (
              <div
                onClick={handleClick}
                className={`grid-item ${item.advertised &&
                  "grid-item--width2"}`}
              >
                {/* <TestProductCard product={item} handleClick={handleClick} /> */}
                <TestProductCard2 product={item} handleClick={handleClick} />
              </div>
            );
          }
          // else {
          //   <div className="grid-item grid-item--width2 ">
          //     <TestPromotionCard post={post} />
          //   </div>;
          // }
        })}
      </div>
    </div>
  );
}
