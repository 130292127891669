import React, { useEffect, useState } from "react";
import { Adsense } from "@ctrl/react-adsense";

const GoogleAdCard3 = () => {
  const [isAdVisible, setIsAdVisible] = useState(true);

  useEffect(() => {
    const adSlot = document.querySelector("ins.adsbygoogle");
    if (adSlot) {
      // Poll for ad visibility for a short duration
      const adCheckInterval = setInterval(() => {
        const isRendered = adSlot.offsetHeight > 0;
        if (isRendered) {
          setIsAdVisible(true);
          clearInterval(adCheckInterval); // Stop polling once rendered
        }
      }, 500);

      // Fallback to hide ad if not loaded within 3 seconds
      setTimeout(() => {
        clearInterval(adCheckInterval);
        if (adSlot.offsetHeight === 0) setIsAdVisible(false);
      }, 3000);
    } else {
      setIsAdVisible(false); // Ad element not found
    }
  }, []);

  if (!isAdVisible) {
    return null; // Don't render the ad container if the ad is not visible
  }

  return (
    <Adsense
      style={{
        width: "238px",
        height: "250px",
        display: "block",
        marginBottom: "4px",
        marginTop: "4px",
      }}
      client="ca-pub-6917679702799024"
      slot="1093374879"
      format=""
    />
  );
};

export default GoogleAdCard3;
