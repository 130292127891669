// UserProfileHeader.js
import React, { useEffect, useState } from "react";
import { Link, NavLink, useParams } from "react-router-dom";
import {
  getDoc,
  doc,
  getDocs,
  collection,
  serverTimestamp,
} from "firebase/firestore";
import { db } from "../FirebaseConfig";
import { UserAuth } from "../Context";
import { HandleUnfriend } from "./ActiveConnections";
import {
  HandleAcceptConnectionReq,
  HandleRejectConnectionReq,
} from "./ConnectionRequest";
import handlefollowUser from "../hooks/user/followUser";
import handleUnfollowUser from "../hooks/user/unfollowUser";
import SendFriendRequest from "./SendConnectionRequest";
import ProfilePicture from "./ProfilePicture";
import { toast } from "react-toastify";
import { toastOptions } from "../lib/toastOptions";
import useModalStore from "../StatesStore/ModalStore";
import useNotification from "../hooks/useNotification";
import UpdatePFP from "./UpdatePFP";
import useFeedNotification from "../hooks/useFeedNotification";
import ChatToast from "../Chat/ChatToast";
import useSocketStore from "../StatesStore/Socket";
import { Button, Card } from "react-bootstrap";
import useFollowUser from "../hooks/useFollowUser";

const UserProfileHeader = ({ selectedOption, setSelectedOption }) => {
  const { uid } = useParams(); // Extract the "uid" parameter from the URL
  const [userData, setUserData] = useState(null);
  const { user } = UserAuth();

  const [friendUid, setfriendUid] = useState(uid);
  const [activeList, setActiveList] = useState([]);
  const [pendinglist, setPendingList] = useState([]);
  const [requestlist, setRequestList] = useState([]);
  const [followerCount, setFollowerCount] = useState(0);
  const [followingCount, setFollowingCount] = useState(0);
  const [Followed, setisFollowed] = useState();
  const { openModal } = useModalStore();
  const { addNotification, deleteNotification } = useNotification();
  const { addFeedNotification } = useFeedNotification();
  // State to track if profile picture is updated
  const [profilePictureUpdated, setProfilePictureUpdated] = useState(false);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const { isFollowed, isFollowBack, followUser, unfollowUser } = useFollowUser(
    user?.uid,
    uid
  );

  const handleChatClick = (e) => {
    e.preventDefault();
    setIsChatOpen(true);
  };

  const handleCloseChat = () => {
    setIsChatOpen(false);
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        if (uid) {
          const userDocRef = doc(db, "users", uid); // Fetch user data
          const userDocSnapshot = await getDoc(userDocRef);
          if (userDocSnapshot.exists()) {
            setUserData(userDocSnapshot.data());

            // Fetch connections for the user
            const connectionsRef = collection(
              db,
              "users",
              user?.uid,
              "connections"
            );
            const connectionsSnapshot = await getDocs(connectionsRef);

            // Filter connections by type
            const activeConnections = [];
            const pendingRequests = [];
            const requestList = [];

            connectionsSnapshot.forEach((doc) => {
              const connectionData = doc.data();
              if (connectionData.type === "active") {
                activeConnections.push(doc.id);
              } else if (connectionData.type === "pending") {
                pendingRequests.push(doc.id);
              } else if (connectionData.type === "request") {
                requestList.push(doc.id);
              }
            });

            setActiveList(activeConnections);
            setPendingList(pendingRequests);
            setRequestList(requestList);

            const followerRef = collection(userDocRef, "followers");
            const followerSnap = await getDocs(followerRef);
            setFollowerCount(followerSnap.size || 0);
            const followingRef = collection(userDocRef, "following");
            const followingSnap = await getDocs(followingRef);
            setFollowingCount(followingSnap.size || 0);
          } else {
            console.log("User document does not exist.");
          }
        } else {
          console.log("No uid provided in the URL.");
        }
      } catch (error) {
        console.error("Error fetching user data:", error.message);
      }
    };

    fetchUserData();
  }, [user, uid, profilePictureUpdated]);

  useEffect(() => {
    const fetchData = async () => {
      if (!user?.uid) {
        console.error("User ID is undefined or null");
        return;
      }
      const checkBrandSnapshot = doc(
        db,
        "users",
        user?.uid,
        "following",
        friendUid
      );
      const checkBrandData = await getDoc(checkBrandSnapshot);
      if (checkBrandData.exists()) setisFollowed(true);
      else setisFollowed(false);
    };
    fetchData();
  }, [userData]);
  //to get screen widths adjusted in avatar
  console.log("pendinglist", pendinglist);

  const formatTimestamp = (timestamp) => {
    if (!timestamp || !timestamp.toDate) {
      return "N/A";
    }

    const options = { year: "numeric", month: "short", day: "numeric" };
    return timestamp.toDate().toLocaleDateString("en-US", options);
  };

  const isCurrentUserProfile = user?.uid === uid;

  // console.log('Request list fetched in userprofile header',pendinglist);
  // console.log('loggedinUser document from userprofileheader',loggedinUserDoc);
  // console.log('UserData inside userprofileheader',userData);
  //a
  const handleClick = () => {
    const DATA = {
      displayName: userData.userName
        .split(" ")
        .slice(0, 2)
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" "),
      FollowButton: setisFollowed,
      UID: user?.uid,
      friendUID: friendUid,
    };
    openModal("ConfirmUnfollow", "user", DATA); // Open modal here
  };

  const handleProfilePictureUpdate = () => {
    // Toggle the state to trigger re-render
    setProfilePictureUpdated(!profilePictureUpdated);
  };

  return (
    <>
      <ChatToast
        isOpen={isChatOpen}
        onClose={handleCloseChat}
        userData={userData}
        user={user}
        uid={uid}
      />
      <Card className="mb-2">
        <Card.Body className="py-0">
          <div className="d-flex d-sm-none align-items-end py-3 px-0 ">
            <div className="userImage">
              <div
                className="avatar avatar-xxl rounded-2"
                style={{
                  width: "115px", // Fixed width
                  height: "115px", // Fixed height
                  overflow: "hidden", // Hide overflow to maintain square shape
                }}
              >
                {userData &&
                (userData.privacySetting === undefined ||
                  userData.privacySetting.profilePicture === true ||
                  isCurrentUserProfile) ? (
                  <UpdatePFP
                    Uid={user?.uid}
                    userData={userData}
                    avatarSize="xxl"
                    className="avatar-img squared-curved border border-white border-3 rounded-2"
                    onProfilePictureUpdate={handleProfilePictureUpdate}
                  />
                ) : (
                  <ProfilePicture userData={user} />
                )}
              </div>
            </div>
            <div className="details d-flex flex-column ms-3 mb-1 w-100 ">
              <div className="userName h6 m-0 p-0">
                {userData
                  ? userData.userName
                      .split(" ")
                      .slice(0, 2)
                      .map(
                        (word) => word.charAt(0).toUpperCase() + word.slice(1)
                      )
                      .join(" ")
                  : ""}{" "}
                <i className="bi bi-patch-check-fill text-success small"></i>
              </div>
              <div className="userDetailsAndFollowButton d-flex flex-column ">
                <div
                  className="userDetails h6 fw-light hstack gap-2 mb-1 flex-wrap"
                  style={{ whiteSpace: "nowrap", fontSize: "0.7rem" }}
                >
                  {/* User Connections */}
                  <div>
                    {activeList && activeList.length > 0
                      ? `${activeList.length} connection${
                          activeList.length > 1 ? "s" : ""
                        }`
                      : "0 connections"}
                  </div>
                  {/* Follower Count */}
                  <div>{followerCount} Followers</div>
                  {/* Following Count */}
                  <div>{followingCount} Following</div>
                  {!isCurrentUserProfile &&
                    userData &&
                    (userData.privacySetting === undefined ||
                      userData.privacySetting.message === true) && (
                      <Button
                        className="p-0 me-1"
                        variant="light"
                        size="sm"
                        style={{
                          width: "2rem",
                          height: "1.5rem",
                          fontSize: "0.8rem",
                        }}
                        data-target="chatToast"
                        onClick={handleChatClick}
                      >
                        {/* <a class=" btn btn-light p-0" href="messaging.html"> */}
                        <i className="bi bi-chat-left-text-fill fs-7"> </i>
                        {/* </a> */}
                      </Button>
                    )}
                </div>

                <div className="followButton my-2">
                  {!isCurrentUserProfile &&
                    userData &&
                    (userData.privacySetting === undefined ||
                      userData.privacySetting.friendRequest === true) && (
                      <Button
                        className="p-0"
                        variant="danger"
                        size="sm"
                        style={{
                          width: "6rem",
                          height: "1.5rem",
                          fontSize: "0.8rem",
                        }}
                        onClick={async () => {
                          if (activeList && activeList.includes(uid)) {
                            await HandleUnfriend(friendUid, user?.uid);
                            setActiveList(
                              activeList.filter((id) => id !== uid)
                            );
                          } else if (pendinglist && pendinglist.includes(uid)) {
                            await HandleAcceptConnectionReq(
                              friendUid,
                              user?.uid
                            );
                            setPendingList(
                              pendinglist.filter((id) => id !== uid)
                            );
                            setActiveList([...activeList, uid]);
                          } else if (requestlist && requestlist.includes(uid)) {
                            await HandleRejectConnectionReq(
                              friendUid,
                              user?.uid
                            );
                            setRequestList(
                              requestlist.filter((id) => id !== uid)
                            );
                          } else {
                            await SendFriendRequest(user?.uid, friendUid);
                            setRequestList([...requestlist, uid]);
                            await handlefollowUser(user?.uid, friendUid);
                            toast.success(
                              "Friend request has been sent",
                              toastOptions
                            );
                          }
                        }}
                      >
                        {activeList && activeList.includes(uid) ? (
                          <>
                            <i className="bi bi-person-dash"></i>
                            {" Unfriend"}
                          </>
                        ) : pendinglist && pendinglist.includes(uid) ? (
                          <>
                            <i className="bi bi-person-plus"></i>
                            {" Respond"}
                          </>
                        ) : requestlist && requestlist.includes(uid) ? (
                          <>
                            <i className="bi bi-person-dash"></i>
                            {" Cancel Request"}
                          </>
                        ) : (
                          <>
                            <i className="bi bi-person-plus"></i>
                            {" Add Friend"}
                          </>
                        )}
                      </Button>
                    )}

                  {isCurrentUserProfile && (
                    <Button
                      className="p-0"
                      variant="primary"
                      size="sm"
                      style={{
                        width: "6rem",
                        height: "1.5rem",
                        fontSize: "0.8rem",
                      }}
                      to="/settings"
                    >
                      <i className="bi bi-pencil-fill me-1"></i>{" "}
                      {/* Add margin-right here */}
                      <span>Edit Profile</span>
                    </Button>
                  )}
                  {!isCurrentUserProfile && (
                    <Button
                      className="p-0 mx-2"
                      variant="primary"
                      size="sm"
                      style={{
                        width: "5rem",
                        height: "1.5rem",
                        fontSize: "0.8rem",
                      }}
                      onClick={isFollowed ? unfollowUser : followUser}
                    >
                      {isFollowed
                        ? "Following"
                        : isFollowBack
                        ? "Follow Back"
                        : "Follow"}
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="d-none d-sm-flex align-items-end py-3 px-0 ">
            <div className="userImage">
              <div
                className="avatar avatar-xxl "
                style={{
                  width: "150px", // Fixed width
                  height: "150px", // Fixed height
                  overflow: "hidden", // Hide overflow to maintain square shape
                  borderBottomLeftRadius: "8px",
                  borderBottomRightRadius: "8px",
                }}
              >
                {userData &&
                (userData.privacySetting === undefined ||
                  userData.privacySetting.profilePicture === true ||
                  isCurrentUserProfile) ? (
                  <UpdatePFP
                    Uid={user?.uid}
                    userData={userData}
                    avatarSize="xxl"
                    className="avatar-img squared-curved border border-white border-3"
                    style={{
                      width: "150px",
                      height: "150px",
                      objectFit: "cover", // Cover the entire container area
                      objectPosition: "center", // Center the image within the div
                      borderRadius: "8px", // Match the container's border radius
                    }}
                    onProfilePictureUpdate={handleProfilePictureUpdate}
                  />
                ) : (
                  <ProfilePicture userData={user} />
                )}
              </div>
            </div>
            <div className="details d-flex flex-column ms-4 mb-2 w-100 ">
              <div className="userName h3 fw-semibold m-0 p-0">
                {userData
                  ? userData.userName
                      .split(" ")
                      .slice(0, 2)
                      .map(
                        (word) => word.charAt(0).toUpperCase() + word.slice(1)
                      )
                      .join(" ")
                  : ""}{" "}
                <i className="bi bi-patch-check-fill text-success small"></i>
              </div>
              <div className="userDetailsAndFollowButton d-flex justify-content-between align-items-center ">
                <div
                  className="userDetails h6 fw-semibold hstack gap-4 flex-wrap m-0"
                  style={{ whiteSpace: "nowrap" }}
                >
                  {/* User Connections */}
                  <div>
                    {activeList && activeList.length > 0
                      ? `${activeList.length} connection${
                          activeList.length > 1 ? "s" : ""
                        }`
                      : "0 connections"}
                  </div>
                  {/* Follower Count */}
                  <div>{followerCount} Followers</div>
                  {/* Following Count */}
                  <div>{followingCount} Following</div>
                </div>
                <div className="followButton">
                  {!isCurrentUserProfile &&
                    userData &&
                    (userData.privacySetting === undefined ||
                      userData.privacySetting.message === true) && (
                      <button
                        className="btn btn-light me-2  btn-sm toast-btn"
                        style={{ width: "3rem" }}
                        data-target="chatToast"
                        onClick={handleChatClick}
                      >
                        {/* <a class=" btn btn-light p-0" href="messaging.html"> */}
                        <i className="bi bi-chat-left-text-fill fs-7"> </i>
                        {/* </a> */}
                      </button>
                    )}
                  {!isCurrentUserProfile &&
                    userData &&
                    (userData.privacySetting === undefined ||
                      userData.privacySetting.friendRequest === true) && (
                      <button
                        className="btn btn-danger me-2 btn-sm px-1 py-2"
                        style={{ width: "7.5rem", height: "2.2rem" }}
                        type="button"
                        onClick={async () => {
                          if (activeList && activeList.includes(uid)) {
                            await HandleUnfriend(friendUid, user?.uid);
                            setActiveList(
                              activeList.filter((id) => id !== uid)
                            );
                          } else if (pendinglist && pendinglist.includes(uid)) {
                            await HandleAcceptConnectionReq(
                              friendUid,
                              user?.uid
                            );
                            setPendingList(
                              pendinglist.filter((id) => id !== uid)
                            );
                            setActiveList([...activeList, uid]);
                          } else if (requestlist && requestlist.includes(uid)) {
                            await HandleRejectConnectionReq(
                              friendUid,
                              user?.uid
                            );
                            setRequestList(
                              requestlist.filter((id) => id !== uid)
                            );
                          } else {
                            await SendFriendRequest(user?.uid, friendUid);
                            setRequestList([...requestlist, uid]);
                            await handlefollowUser(user?.uid, friendUid);
                            toast.success(
                              "Friend request has been sent",
                              toastOptions
                            );
                          }
                        }}
                      >
                        {/* <i className="bi bi-person-plus"></i> */}
                        {activeList && activeList.includes(uid) ? (
                          <>
                            <i className="bi bi-person-dash"></i>
                            {" Unfriend "}
                          </>
                        ) : pendinglist && pendinglist.includes(uid) ? (
                          <>
                            <i className="bi bi-person-plus"></i>
                            {" Respond"}
                          </>
                        ) : requestlist && requestlist.includes(uid) ? (
                          <>
                            <i className="bi bi-person-dash"></i>
                            {" Cancel Request"}
                          </>
                        ) : (
                          <>
                            <i className="bi bi-person-dash"></i>
                            {" Add Friend"}
                          </>
                        )}
                      </button>
                    )}
                  {isCurrentUserProfile && (
                    <Link
                      className="btn btn-primary me-2 btn-sm"
                      style={{ width: "7rem" }}
                      to="/settings"
                    >
                      <i className="bi bi-pencil-fill me-1"></i>{" "}
                      {/* Add margin-right here */}
                      <span>Edit Profile</span>
                    </Link>
                  )}
                  {!isCurrentUserProfile && (
                    <Link
                      className="btn btn-primary me-2 btn-sm"
                      style={{ width: "7rem" }}
                      onClick={isFollowed ? unfollowUser : followUser}
                    >
                      {isFollowed
                        ? "Following"
                        : isFollowBack
                        ? "Follow Back"
                        : "Follow"}
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
          <ul className="list-inline mb-0 text-center text-sm-start mt-3 mt-sm-0 py-1">
            {userData && userData.userTitle && (
              <li className="list-inline-item">
                <i className="bi bi-briefcase me-1"></i> {userData.userTitle}
              </li>
            )}
            {userData &&
              (userData.privacySetting === undefined ||
                userData.privacySetting.location === true) &&
              userData.location && (
                <li className="list-inline-item">
                  <i className="bi bi-geo-alt me-1"></i> {userData.location}
                </li>
              )}
            <li className="list-inline-item">
              <i className="bi bi-calendar2-plus me-1"></i>{" "}
              {userData
                ? `Joined on ${formatTimestamp(userData.timestamp)}`
                : "Joined on N/A"}
            </li>
          </ul>
        </Card.Body>
        <hr className="m-0" />
        <Card.Footer className="py-0">
          <ul
            className={`navbar nav align-items-start justify-content-start justify-content-md-center py-2 border-0 h6`}
          >
            <li className="nav-item">
              <NavLink
                to={`/userProfile/${uid}/profile/userAbout`}
                className={`nav-link ${
                  selectedOption === "profile" ? "active" : ""
                }`}
                onClick={() => setSelectedOption("profile")}
              >
                Profile
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to={`/userProfile/${uid}/posts/myPosts`}
                className={`nav-link ${
                  selectedOption === "posts" ? "active" : ""
                }`}
                onClick={() => setSelectedOption("posts")}
              >
                Posts
              </NavLink>
            </li>
            {/* <li className="nav-item">
              <NavLink
                to={`/userProfile/${uid}/shoppinglist/myList`}
                className={`nav-link ${
                  selectedOption === "shoppinglist" ? "active" : ""
                }`}
                onClick={() => setSelectedOption("shoppinglist")}
              >
                Shopping List
              </NavLink>
            </li> */}
            <li className="nav-item">
              <NavLink
                to={`/userProfile/${uid}/network/userConnections`}
                className={`nav-link ${
                  selectedOption === "network" ? "active" : ""
                }`}
                onClick={() => setSelectedOption("network")}
              >
                Network
              </NavLink>
            </li>
          </ul>
        </Card.Footer>
      </Card>
    </>
  );
};

export default UserProfileHeader;
