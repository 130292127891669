import React, { useState } from "react";
import { Link } from "react-router-dom";
import ShareMenu2 from "../components/ShareMenu2";
import { Card } from "react-bootstrap";
import ProductReaction from "./ProductReaction";
import "./style.css";
import { UserAuth } from "../Context";
import formatPrice from "../lib/formatPrice";
import AddOrRemoveFavourites from "./AddOrRemoveFavourites";
import useActiveBrandsStore from "../StatesStore/ActiveBrands";
import ImageCarousel from "./ImageCarousel";

const ProductCard = ({ product, componentName }) => {
  const [likeCount, setLikeCount] = useState(product?.likes);
  const [favouritesCount, setFavouritesCount] = useState(product?.favourites);
  const activeBrands = useActiveBrandsStore((state) => state.activeBrands);
  const { user } = UserAuth();
  console.log("product",product);
  
  return (
    // <Card className="col-sm-6 col-md-4 shadow-lg mx-ms-1">
    <div  data-testid="product-card"
      className={`${
        componentName === "SearchResults"
          ? "col-sm-6 col-md-4 col-lg-3"
          : "col-sm-6 col-md-4"
      }`}
    >
      <div className="card h-100">
        <div className="z-1">
          <button
            style={{ top: "1%", right: "3%" }}
            className="position-absolute border border-0 mt-1 rounded-circle bg-mode text-body "
          >
            <ShareMenu2 product={product} />
          </button>

          <button
            style={{ top: "0%", right: "3%" }}
            className="position-absolute border border-0 my-5 rounded-circle bg-mode text-body "
          >
            <AddOrRemoveFavourites product={product} user={user} setFavouritesCount={setFavouritesCount}/>
          </button>
          {(componentName === "Products" ||
            componentName === "SearchResults") && (
            <div
              style={{ top: "65%", left: "3%" }}
              className="position-absolute badge rounded-pill text-bg-primary"
            >
              {product?.brandName}
            </div>
          )}
        </div>

        <ImageCarousel product={product} />
        <Link to={`/ProductDetails/${product?.id}`}>
          <Card.Body className="px-3 py-1">
            <div className="d-flex align-items-center py-1 small text-body text-decoration-none">
              <div className="d-flex align-items-center px-1">
                {" "}
                <i className="bi bi-hand-thumbs-up pe-1"></i>({likeCount})
              </div>
              <div className="d-flex align-items-center px-4">
                {" "}
                <i className="bi bi-eye pe-1"></i>({product?.views ?? 0})
              </div>
              <div className="d-flex align-items-center px-3">
                {" "}
                <i className="bi bi-star pe-1"></i>({favouritesCount ?? 0})
              </div>
            </div>

            <Card.Title
              className="fw-light"
              style={{
                marginBottom: "5px",
                marginTop: "5px",
                fontSize: "10px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {product.name}
            </Card.Title>
            <div className="d-flex align-items-center justify-content-between text-body ">
              <div className="d-flex align-items-center gap-1">
                {product?.newPrice && (
                  <p className="fs-5 m-0 fw-bold text-success">
                    PKR&nbsp;
                    {product?.newPrice
                      ? formatPrice(product.newPrice)
                      : formatPrice(product.oldPrice)}
                  </p>
                )}
                {product.oldPrice && product.newPrice ? (
                  <p className="fs-6 m-0 mx-2 text-decoration-line-through ">
                    PKR&nbsp;{formatPrice(product.oldPrice)}
                  </p>
                ) : (
                  ""
                )}
              </div>
            </div>
          </Card.Body>
        </Link>
        <div className="card-footer  p-0">
          <ProductReaction product={product} setLikeCount={setLikeCount} setFavouritesCount={setFavouritesCount}/>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
