import { doc, getDoc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import { db } from '../FirebaseConfig';

export function useFetchPostData(postId) {
    const [postData, setPostData] = useState(null);
    const [loading, setLoading] = useState(true);
   
    useEffect(() => {
       const fetchPostData = async () => {
         if (!postId) return;
         try {
           const postDocRef = doc(db, "posts", postId);
           const userDocSnapshot = await getDoc(postDocRef);
           if (userDocSnapshot.exists()) {
            setPostData({
               ...userDocSnapshot.data(),
               postId: userDocSnapshot.id,
             });
           } else {
             console.log("User document does not exist.");
           }
           setLoading(false);
         } catch (error) {
           console.error("Error fetching user data:", error.message);
         }finally{
           setLoading(false);
         }
       };
       fetchPostData();
    }, [postId]);
   
    return { postData, loading };
}
