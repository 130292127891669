import React, { useEffect, useState } from 'react';
import { getFirestore, collection, getDocs, doc, updateDoc, serverTimestamp } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import { UserAuth } from '../Context';
import { toast } from 'react-toastify';
import { toastOptions } from '../lib/toastOptions';
import { formatTimestampForPost } from '../lib/formatTimestampForPost';

const db = getFirestore();

// const formatTimestampForPost = (timestamp) => {
//   console.log('Time stamp value : ',)
//   if (!timestamp || (!timestamp.toDate && !timestamp._seconds)) {
//     return "N/A";
//   }

//   const postDate = timestamp.toDate ? timestamp.toDate() : new Date(timestamp._seconds * 1000);
//   const currentDate = new Date();
//   const timeDifference = currentDate - postDate;

//   const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

//   if (days < 30) {
//     return `${days} day${days !== 1 ? 's' : ''}`;
//   } else if (days < 365) {
//     const months = Math.floor(days / 30);
//     return `${months} month${months !== 1 ? 's' : ''}`;
//   } else {
//     const years = Math.floor(days / 365);
//     return `${years} year${years !== 1 ? 's' : ''}`;
//   }
// };

const ReviewsList = ({ productId, trigger }) => {
  const { user } = UserAuth();
  const [reviews, setReviews] = useState([]);
  const [commentText, setCommentText] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentReview, setCurrentReview] = useState(null);
  const [newRating, setNewRating] = useState(0); // For storing new rating
  const [visibleReviews, setVisibleReviews] = useState(5); // Initial visible reviews count
  
  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const reviewsRef = collection(db, `products/${productId}/Reviews`);
        const querySnapshot = await getDocs(reviewsRef);
        let reviewsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        // Separate your review from others
        if(user){
          const userReview = reviewsData.find(review => review.userId?.uid === user?.uid);
        const otherReviews = reviewsData.filter(review => review.userId?.uid !== user?.uid);

        // Sort the remaining reviews by timestamp
        otherReviews.sort((a, b) => b.timestamp.seconds - a.timestamp.seconds);

        // Place your review at the beginning of the array
        if (userReview) {
          reviewsData = [userReview, ...otherReviews];
        } else {
          reviewsData = otherReviews;
        }

        }
        
        setReviews(reviewsData);
      } catch (error) {
        console.error("Error fetching reviews:", error);
        setError("Failed to load reviews.");
      } finally {
        setLoading(false);
      }
    };

    fetchReviews();
  }, [productId, trigger]);

  const openEditModal = (review) => {
    setCurrentReview(review);
    setNewRating(review.rating); // Set the current rating as the initial value
    setCommentText(review.comment); // Set the current comment as the initial value
  };

  const handleSaveRating = async () => {
    if (!currentReview) return;

    if (!commentText.trim()) {
      toast.warn("Review text is empty.", toastOptions); 
    }

    try {
      const reviewDocRef = doc(db, `products/${productId}/Reviews`, currentReview.id);
      await updateDoc(reviewDocRef, {
        rating: newRating,
        comment: commentText,
        timestamp: serverTimestamp(), 
      });
      toast.success("Your review is updated !", toastOptions); 
      setReviews((prevReviews) =>
        prevReviews.map((r) =>
          r.id === currentReview.id ? { ...r, rating: newRating, comment: commentText, timestamp: serverTimestamp() } : r
        )
      );
    } catch (error) {
      console.error("Error updating review:", error);
    }

    // Close the modal
    setCurrentReview(null);
  };

  const loadMoreReviews = () => {
    setVisibleReviews(prev => prev + 5); // Load 5 more reviews
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="container" style={{ paddingLeft: '35px' }}>
      {reviews.length === 0 ? (
        <div className="text-center my-3">No reviews yet.</div>
      ) : (
        reviews.slice(0, visibleReviews).map((review, index) => ( // Show limited reviews
          <div
            key={review.id}
            className={`d-flex align-items-start py-3 ${index !== reviews.length - 1 ? 'border-bottom' : ''}`}
          >
            {review.userId?.photoURL ? (
              <Link to={`/userProfile/${review.userId.uid}`}>
                <img
                  src={review.userId.photoURL}
                  alt="User profile"
                  className="rounded border me-3"
                  style={{ width: '25px', height: '25px' }}
                />
              </Link>
            ) : (
              <div className="rounded border bg-secondary me-3" style={{ width: '25px', height: '25px' }} />
            )}
            <div className="flex-grow-1">
              <Link to={`/userProfile/${review.userId.uid}`}>
                <p className="fw-bold mb-1 text-body">
                  {review.userId?.displayName}
                </p>
              </Link>
              <p className="mb-1">{review.comment}</p>
              <div className="d-flex mb-1">
                {[...Array(5)].map((_, starIndex) => (
                  <span key={starIndex}>
                    {starIndex < review.rating ? (
                      <i className="bi bi-star-fill text-warning"></i>
                    ) : (
                      <i className="bi bi-star text-warning"></i>
                    )}
                  </span>
                ))}
              </div>
              <p className="text small">{formatTimestampForPost(review.timestamp)}</p>
              {review.userId.uid == user?.uid && (
              <p 
              onClick={() => openEditModal(review)} 
              style={{ textDecoration: 'underline', cursor: 'pointer', textAlign: 'right', color: 'grey' }}
            >
              Edit
            </p>
              )}
            </div>
          </div>
        ))
      )}

      {/* Show Load More button if there are more reviews to load */}
      {visibleReviews < reviews.length && (
        <div className="text-center my-3">
          <p onClick={loadMoreReviews}  style={{ textDecoration: 'underline', cursor: 'pointer', textAlign: 'center', color: 'blue' }}>
            Load More Reviews
          </p>
        </div>
      )}

      {/* Edit Rating Modal */}
      {currentReview && (
        <div className="modal show" style={{ display: 'block' }}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Edit Your Review</h5>
                <button type="button" className="btn-close" onClick={() => setCurrentReview(null)}></button>
              </div>
              <div className="modal-body">
                <div className="d-flex"> 
                  <img
                    src={currentReview.userId.photoURL}
                    alt="User profile"
                    className="rounded border me-3"
                    style={{ width: '45px', height: '45px' }}
                  />
 <p style={{ margin: 'auto 0' }}>{currentReview.userId?.displayName}</p>
                 </div>
                <br/>

                <textarea
                  className="form-control"
                  rows="3"
                  placeholder="Add a comment..."
                  value={commentText}
                  onChange={(e) => setCommentText(e.target.value)}
                ></textarea>
                <div className="d-flex justify-content-end mt-2">
                  {[...Array(5)].map((_, starIndex) => (
                    <span key={starIndex} onClick={() => setNewRating(starIndex + 1)}>
                      {starIndex < newRating ? (
                        <i className="bi bi-star-fill text-warning" style={{ cursor: 'pointer' }}></i>
                      ) : (
                        <i className="bi bi-star text-warning" style={{ cursor: 'pointer' }}></i>
                      )}
                    </span>
                  ))}
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-primary" onClick={() => setCurrentReview(null)}>
                  Cancel
                </button>
                <button type="button" className="btn btn-primary" onClick={handleSaveRating}>
                  Save changes
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ReviewsList;
