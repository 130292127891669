import React, { useEffect, useState } from "react";
import {
  doc,
  getDoc,
  setDoc,
  arrayUnion,
  writeBatch,
  serverTimestamp,
  addDoc,
  updateDoc,
  increment,
} from "firebase/firestore";
import { db } from "../FirebaseConfig";
import { UserAuth } from "../Context";
import { collection } from "firebase/firestore";
import Avatar from "react-avatar";
import { Link } from "react-router-dom";
import ProfilePicture from "../UserProfile/ProfilePicture";
import useNotification from "../hooks/useNotification";
import usePointsStore from "../StatesStore/PointsTable";
import usePoints from "../hooks/user/usePoints";
import { useFetchUserData } from "../hooks/user/useFetchUserData";
import usePostAction from "../hooks/usePostAction";
import useFeedNotification from "../hooks/useFeedNotification";

export default function AddComment({ post, setCommentCount, user, type }) {
  const [input, setInput] = useState("");
  const { addNotification } = useNotification();
  const { addPoints } = usePoints();
  const pointsTable = usePointsStore((state) => state.pointsTable);
  const { userData } = useFetchUserData(user?.uid);
  const { addPostActionNotification } = usePostAction();
  const { addFeedNotification } = useFeedNotification();
  // console.log("post", post);
  const handleSubmit = async (e) => {
    if (!user || !userData) return;

    e.preventDefault();

    if (input) {
      if (type === "comment") {
        const postRef = collection(db, "posts", post.id, "comments");

        const content = input;

        const commentObj = {
          content: input,
          timestamp: serverTimestamp(),
          userId: user.uid,
          postId: post?.id,
        };
        setInput("");
        // Create a new document with a specific id in the comments collection
        const commentDocRef = await addDoc(postRef, commentObj);
        const commentDocId = commentDocRef.id;
        setCommentCount((prev) => prev + 1);
        // Clear the input
        if (post.userId !== user.uid) {
          await addNotification({
            userId: post?.userId,
            id: user?.uid,
            timestamp: serverTimestamp(),
            type: "postComment",
            by: "user",
            fields: {
              postId: post?.id,
              comment:content
            },
          });

          //adding points
          const postingPoints = pointsTable.commentingOnUserPost || 0;
          addPoints({
            userId: user?.uid,
            type: "commentingOnUserPost",
            pointsAwarded: postingPoints,
            fields: {
              postId: post?.id,
            },
          });

          console.log("Points and points log updated successfully!");

          addFeedNotification({
            ownerId: post.userId,
            id: user?.uid,
            type: "postComment",
            by: "user",
            fields: {
              postId: post?.id,
            },
          });

          addPostActionNotification({
            ownerId: post.userId,
            actionUserId: user?.uid,
            actionType: "postComment",
            by: "user",
            postId: post?.id,
            fields: {
              commentId: commentDocId,
            },
          });
        }
      } else if (type === "reply") {
        const commentRef = collection(post?.reference, post?.id, "replies");
        const replyObj = {
          content: input,
          timestamp: serverTimestamp(),
          userId: user.uid,
          parentCommentId: post?.id,
        };
        setCommentCount((prev) => prev + 1);
        setInput("");
        await addDoc(commentRef, replyObj);
      }
    }
  };

  return (
    <>
      {/* Add comment */}
      <div className="d-flex pb-1 mb-2">
        <div className="avatar avatar-sm me-2">
          <ProfilePicture
            userData={userData}
            avatarSize={35}
            className="avatar-img rounded-2"
          />
        </div>
        {/* Comment box  */}
        <form
          className="nav nav-item w-100 position-relative"
          onSubmit={handleSubmit}
        >
          <textarea
            data-autoresize
            className="form-control bg-light rounded-2"
            rows="1"
            placeholder="Add comment"
            value={input}
            style={{ scrollbarWidth: "none" }}
            onChange={(e) => setInput(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault();
                handleSubmit(e);
              }
            }}
          ></textarea>

          <button
            className="nav-link bg-transparent px-3 position-absolute top-50 end-0 translate-middle-y border-0"
            type="submit"
          >
            <i className="bi bi-send-fill"> </i>
          </button>
        </form>
      </div>
    </>
  );
}
