import { format } from "date-fns";

export const formatTimestampForBlog = (timestamp) => {
  if (!timestamp) {
    return "N/A";
  }

  // Convert Firestore timestamp to JavaScript Date object
  let postDate;
  if (timestamp._seconds && timestamp._nanoseconds) {
    // Node.js format
    postDate = new Date(
      timestamp._seconds * 1000 + timestamp._nanoseconds / 1000000
    );
  } else if (timestamp.seconds && timestamp.nanoseconds) {
    // JavaScript format
    postDate = new Date(
      timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000
    );
  } else {
    return "Invalid timestamp format";
  }
  // More than a year ago
  return format(postDate, "MMMM dd, yyyy");
};
