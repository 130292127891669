import React, { useEffect, useState } from "react";
import {
  collection,
  addDoc,
  query,
  orderBy,
  onSnapshot,
  serverTimestamp,
  doc,
  where,
  getDoc,
  getDocs
} from "firebase/firestore";
import { db } from "../FirebaseConfig";



const isProductRated = async (productId, userId) => {
    try {
      // Reference to the product reviews collection
      const reviewsRef = collection(db, `products/${productId}/Reviews`);
  
      // Fetch all the reviews for the product
      const querySnapshot = await getDocs(reviewsRef);
  
      // Check if any review is made by the given user
      const isRated = querySnapshot.docs.some(doc => doc.data().userId?.uid === userId);
      console.log('Rated ? ',isRated);
      // Return true if the user has rated the product, otherwise false
      return isRated;
    } catch (error) {
      console.error('Error fetching product reviews:', error);
      return false; // Return false in case of any error
    }
  };
  
  export default isProductRated;