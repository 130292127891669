import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  collection,
  query,
  getDocs,
  orderBy,
  limit,
  startAfter,
  getDoc,
  doc,
  onSnapshot,
} from "firebase/firestore";
import { XMasonry, XBlock } from "react-xmasonry";
import { useInView } from "react-intersection-observer";
import { UserAuth } from "../Context";
import UserCard2 from "../Cards/UserCard2";
import { db } from "../FirebaseConfig";

export default function NewsFeed6() {
  const { subSubCategory } = useParams();
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [lastDoc, setLastDoc] = useState(null);
  const [newPostAvailable, setNewPostAvailable] = useState(false);
  const [newTestPosts, setNewTestPosts] = useState([]); // To store new posts
  const [initialTimestamp, setInitialTimestamp] = useState(null);
  const { ref: loadMoreRef, inView: loadMoreView } = useInView({
    threshold: 0.1,
  });
  const { user } = UserAuth();

  const fetchPostDetails = async (postId) => {
    try {
      const postRef = doc(db, "posts", postId);
      const postSnap = await getDoc(postRef);
      if (postSnap.exists()) {
        return { id: postSnap.id, ...postSnap.data() };
      }
      return null;
    } catch (error) {
      console.error("Error fetching post details:", error);
      return null;
    }
  };

  const fetchPosts = async (isInitialLoad = false) => {
    setLoading(true);
    try {
      let q = query(
        collection(db, "testPosts"),
        orderBy("timestamp", "desc"),
        limit(30)
      );

      if (!isInitialLoad && lastDoc) {
        q = query(q, startAfter(lastDoc));
      }

      const querySnapshot = await getDocs(q);
      const testPostsList = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      if (isInitialLoad && testPostsList.length > 0) {
        setInitialTimestamp(testPostsList[0].timestamp);
      }

      const lastVisibleDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
      setLastDoc(lastVisibleDoc);

      const postsPromises = testPostsList.map(async (testPost) => {
        const postData = await fetchPostDetails(testPost.postId);
        return postData;
      });

      const resolvedPosts = await Promise.all(postsPromises);
      const validPosts = resolvedPosts.filter((post) => post !== null);

      setPosts((prevPosts) =>
        isInitialLoad ? validPosts : [...prevPosts, ...validPosts]
      );
    } catch (error) {
      console.error("Error fetching posts:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const q = query(collection(db, "testPosts"), orderBy("timestamp", "desc"));

    const unsubscribe = onSnapshot(
      q,
      async (snapshot) => {
        if (!initialTimestamp) return;

        const newPosts = [];

        for (const change of snapshot.docChanges()) {
          if (change.type === "added") {
            const testPost = { id: change.doc.id, ...change.doc.data() };

            if (testPost.timestamp > initialTimestamp) {
              const postData = await fetchPostDetails(testPost.postId);
              if (postData) {
                newPosts.push(postData);
              }
            }
          }
        }

        if (newPosts.length > 0) {
          setNewTestPosts(newPosts); // Store new posts
          setNewPostAvailable(true);
        }
      },
      (error) => {
        console.error("Error in real-time listener:", error);
      }
    );

    return () => unsubscribe();
  }, [initialTimestamp]);

  useEffect(() => {
    fetchPosts(true);
  }, []);

  useEffect(() => {
    if (loadMoreView && !loading) {
      fetchPosts();
    }
  }, [loadMoreView]);

  const handleNewPostClick = () => {
    setNewPostAvailable(false);
    setPosts((prevPosts) => [...newTestPosts, ...prevPosts]); // Append new posts
    setNewTestPosts([]); // Clear the new posts after adding
    setInitialTimestamp(Date.now()); // Update timestamp
  };

  function getTargetBlockWidth() {
    const screenWidth = window.innerWidth;
    return screenWidth < 440 ? 200 : 250;
  }

  return (
    <>
      <XMasonry
        center={false}
        responsive={true}
        maxColumns={5}
        targetBlockWidth={getTargetBlockWidth()}
      >
        <XBlock>
          {newPostAvailable && (
            <div
              className="position-fixed top-10 start-50 translate-middle"
              style={{
                zIndex: 1050,
                transform: "translate(-50%, -50%)",
              }}
            >
              <button
                className="btn btn-primary-soft btn-sm "
                onClick={handleNewPostClick}
              >
                New Post <i class="bi bi-arrow-up"></i>
              </button>
            </div>
          )}
        </XBlock>
        {posts.map((post) => (
          <XBlock key={post.postId}>
            <UserCard2 post={post} />
          </XBlock>
        ))}
      </XMasonry>

      {loading && (
        <div className="d-flex justify-content-center align-items-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}

      <div ref={loadMoreRef}></div>
    </>
  );
}
