import React, { useEffect, useState } from "react";
import {
  collection,
  addDoc,
  query,
  orderBy,
  onSnapshot,
  serverTimestamp,
  doc,
  where,
  getDoc
} from "firebase/firestore";
import { db } from "../FirebaseConfig";
import ProfilePicture from "../UserProfile/ProfilePicture";
import { UserAuth } from "../Context";
import usePost from "../hooks/usePost";
import { toast } from "react-toastify";
import { toastOptions } from "../lib/toastOptions";
import isProductRated from "../lib/isProductRated";

function ProductFeedback({ userId, productID , onReviewSubmit}) {
  const [commentText, setCommentText] = useState("");
  const [rating, setRating] = useState(0);
  const [comments, setComments] = useState([]);
  const [loggedInUser, setLoggedInUser] = useState([]);
  const [forceRender, setForceRender] = useState(false); // New state for triggering re-render
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isReviewed, setIsReviewed] = useState(false); 
  const { user } = UserAuth();
  let url = process.env.REACT_APP_API + `/api/review/${productID}`;
  const { data: postData, post } = usePost(url);

  // const isReviewed = isProductRated(productID, user?.uid);

  useEffect(() => {
    // Check if the product is already reviewed by the user
    const checkIfReviewed = async () => {
      if (user && productID) {
        const reviewed = await isProductRated(productID, user.uid);
        setIsReviewed(reviewed); // Update the isReviewed state
      }
    };

    checkIfReviewed();
  }, [productID, user]);

  const handleSubmit = async (event) => {
    setIsSubmitting(true);
    event.preventDefault();
    if (!commentText.trim()) {
      toast.warn("Review text is empty.", toastOptions); 
    }
    let data = {
      comment: commentText,
      rating: rating,
      userId: userId,
    };

    await post(data); // Submit the data
    setCommentText(""); // Clear the text
    onReviewSubmit(); // Call the handler to trigger update
    setForceRender(prev => !prev);
    toast.success("Review submitted !", toastOptions); 
    setIsSubmitting(false);
  };

  const fetchComments = async () => {
    if (!productID) {
      setComments([]);
      return;
    }

    const q = query(
      collection(db, "productRatings"),
      where("productID", "==", productID),
      orderBy("timestamp", "desc")
    );

    const unsubscribe = onSnapshot(q, async (querySnapshot) => {
      const newComments = querySnapshot.docs.map((doc) => {
        const commentData = doc.data();
        return {
          id: doc.id,
          ...commentData,
          userName: "",
          photo: "",
        };
      });

      await Promise.all(
        newComments.map(async (comment) => {
          const userDocRef = doc(db, "users", comment.userId);
          const userSnapshot = await getDoc(userDocRef);

          if (userSnapshot.exists()) {
            comment.userName = userSnapshot.data()?.userName;
            comment.photo = userSnapshot.data()?.photo;
          } else {
            console.error(
              "User document not found for userId:",
              comment.userId
            );
          }
        })
      );

      setComments(newComments);
    });

    return unsubscribe;
  };

  useEffect(() => {
    fetchComments();
  }, [productID, forceRender]); // Use the forceRender as a dependency to re-fetch comments

  useEffect(() => {
    const fetchUser = async () => {
      if (user) {
        const userRef = doc(db, "users", user.uid);
        const userSnapshot = await getDoc(userRef);
        if (userSnapshot.exists()) {
          setLoggedInUser(userSnapshot.data());
        }
      }
    };
    fetchUser();
  }, [user, forceRender]); // Use forceRender to trigger user data fetch on re-render

  const handleStarClick = (ratingValue) => {
    setRating(ratingValue);
  };

  const handleStarMouseOver = (ratingValue) => {
    setRating(ratingValue); // Update displayed rating on hover
  };

  const handleStarMouseOut = () => {
    setRating(rating); // Reset displayed rating on mouseout
  };

  const renderStars = () => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      stars.push(
        <i
          key={i}
          className={i <= rating ? "bi bi-star-fill" : "bi bi-star"}
          style={{ color: "gold" }}
          onClick={() => handleStarClick(i)}
          onMouseOver={() => handleStarMouseOver(i)}
          onMouseOut={handleStarMouseOut}
        />
      );
    }
    return stars;
  };

  if (isReviewed == true){
    return <div className="text-center my-3">You have already reviewed this product...</div>;
  }
  else{
    return (
      <div style={{ margin: 0, paddingTop: "20px", paddingLeft: "25px" }}>
        <div className="d-flex align-items-center mb-2">
          <div className="avatar avatar-l me-3">
            <ProfilePicture
              userData={loggedInUser}
              className="avatar-img rounded border border-white border-3"
            />
          </div>
          <div className="star-rating text-warning me-1">{renderStars()}</div>
        </div>
        <form
          className="d-flex align-items-center col-12 col-md-6"
          onSubmit={handleSubmit}
        >
          <textarea
            className="form-control"
            rows="1"
            placeholder="Give your review..."
            value={commentText}
            onChange={(e) => setCommentText(e.target.value)}
          ></textarea>
          <button className="btn btn-primary text-white ms-2" type="submit" disabled={isSubmitting}>
            {isSubmitting ? "Submitting..." : "Submit"}
          </button>
        </form>
      </div>
    );
  }
}

export default ProductFeedback;
