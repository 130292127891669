import React, { useEffect, useState } from "react";
import SideBar from "./SideBar";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { db } from "../FirebaseConfig";
import { UserAuth } from "../Context";
import ConvoScreen from "./ConvoScreen";

function Chat() {
  const { user } = UserAuth();
  const [chats, setChats] = useState([{}]);
  const [selectedChat, setSelectedChat] = useState("");
  const fetchChats = async () => {
    const collectionRef = collection(db, "chats");
    const chatsRef = query(
      collectionRef,
      where("members", "array-contains", user?.uid)
    );
    const chatsSnap = await getDocs(chatsRef);
    const chatsData = await Promise.all(
      chatsSnap.docs.map(async (chat) => {
        const data = chat?.data();
        // const friendUIDs = data.members.filter(
        //   (member) => member !== user?.uid
        // );
        const friendUIDs = data.members;
        const friendsData = await Promise.all(
          friendUIDs.map(async (friend) => {
            const userRef = doc(db, "users", friend);
            const userSnap = await getDoc(userRef);
            const friendData = userSnap.data();
            return {
              id: friend,
              userName: friendData?.userName,
              photo: friendData?.photo,
            };
          })
        );
        return {
          id: chat.id,
          ...data,
          friendsData,
        };
      })
    );
    setChats(chatsData);
    setSelectedChat(chatsData[0]?.id);
    // console.log("chatsData", chatsData);
  };
  useEffect(() => {
    fetchChats();
  }, [user]);

  return (
    <main>
      <div class="container ">
        <div class="row gx-2">
          <SideBar
            chats={chats}
            selectedChat={selectedChat}
            setSelectedChat={setSelectedChat}
          />
          {/* <!-- Chat conversation START --> */}
          <ConvoScreen
            chat={
              selectedChat && chats
                ? chats.filter((chat) => chat?.id == selectedChat)[0]
                : chats[0]
            }
            selectedChat={selectedChat}
          />
        </div>
      </div>
    </main>
  );
}

export default Chat;
