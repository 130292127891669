import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import AccountSetting from "./AccountSetting";

// import PrivacySetting from './PrivacySetting';
import StaticLinks from "../components/StaticLinks";
import { UserAuth } from "../Context";
import { db } from "../FirebaseConfig";
import { doc, getDoc } from "firebase/firestore";
import BrandHandlerSettings from "../Brands/BrandHandlerSettings";
import { Grid1x2Fill, GridFill } from "react-bootstrap-icons";
import BrandSelection from "./BrandSelection";
import CategorySelection from "./CategorySelection";
import PrivacySetting from "./PrivacySetting";

const UserSettings = ({ tab = 'account' }) => {
  const [activeTab, setActiveTab] = useState(tab);
  const { user } = UserAuth();
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const [userData, setUserData] = useState(null); // State to hold fetched user data

  useEffect(() => {
    const fetchUserData = async () => {
      if (!user) return;
      const userRef = doc(db, "users", user.uid);
      try {
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          setUserData(userDoc.data());
          console.log("Fetched user data:", userDoc.data());
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, [user]);

//  const isbrandHandler = userData?.isBrandHandler;
 const isbrandHandler = true;


  return (
    <main>
      <div className="container">
        <div className="row">
          <div className="col-lg-3">
            <div className="d-flex align-items-center mb-4 d-lg-none">
              <button
                className="border-0 bg-transparent"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasNavbar"
                aria-controls="offcanvasNavbar"
              >
                <span className="btn btn-primary">
                  <i className="fa-solid fa-sliders-h"></i>
                </span>
                <span className="h6 mb-0 fw-bold d-lg-none ms-2">Settings</span>
              </button>
            </div>
            <nav className="navbar navbar-light navbar-expand-lg mx-0">
              <div
                className="offcanvas offcanvas-start"
                tabIndex="-1"
                id="offcanvasNavbar"
              >
                <div className="offcanvas-header">
                  <button
                    type="button"
                    className="btn-close text-reset ms-auto"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="offcanvas-body p-0">
                  <div className="card w-100">
                    <div className="card-body">
                      <ul className="nav nav-tabs nav-pills nav-pills-soft flex-column fw-bold gap-2 border-0">
                        <li
                          className={`nav-item ${
                            activeTab === "account" ? "active" : ""
                          }`}
                        >
                          <Link
                            className="nav-link d-flex align-items-center mb-0"
                            to="#nav-setting-tab-1"
                            data-bs-toggle="tab"
                            onClick={() => handleTabClick("account")}
                          >
                            <img
                              className="me-2 h-20px fa-fw"
                              src="assets/images/icon/person-outline-filled.svg"
                              alt=""
                            />
                            <div>Account</div>
                          </Link>
                        </li>
                        <li
                          className={`nav-item ${
                            activeTab === "brands" ? "active" : ""
                          }`}
                        >
                          <Link
                            className="nav-link d-flex align-items-center mb-0"
                            to="#nav-setting-tab-2"
                            data-bs-toggle="tab"
                            onClick={() => handleTabClick("brands")}
                          >
                            <GridFill className="fs-5" />
                            <div className="ms-2">Personalize Brands</div>
                          </Link>
                        </li>
                        <li
                          className={`nav-item ${
                            activeTab === "category" ? "active" : ""
                          }`}
                        >
                          <Link
                            className="nav-link mb-0 d-flex align-items-center  pe-0 "
                            to="#nav-setting-tab-3"
                            data-bs-toggle="tab"
                            onClick={() => handleTabClick("category")}
                          >
                            <Grid1x2Fill className="fs-5" height={"1rem"} />
                            <div className="ms-2">Personalize Categories</div>
                          </Link>
                          </li>
                          <li className={`nav-item ${
                            activeTab === "brandHandler" ? "active" : ""
                          }`}>
                          {isbrandHandler && (
                            <Link
                            className="nav-link d-flex mb-0"
                            to="#nav-setting-tab-2"
                            data-bs-toggle="tab"
                            onClick={() => handleTabClick("brandHandler")}
                          >
                            <img
                              className="me-2 h-20px fa-fw"
                              src="assets/images/icon/person-outline-filled.svg"
                              alt=""
                            />
                            <span>Brand Handler</span>
                          </Link>
                          )}
                          
                          </li>
                        <li
                          className={`nav-item ${
                            activeTab === "privacy" ? "active" : ""
                          }`}
                        >
                          <Link
                            className="nav-link mb-0 d-flex align-items-center px-auto"
                            to="#nav-setting-tab-4"
                            data-bs-toggle="tab"
                            onClick={() => handleTabClick("privacy")}
                          >
                            <img
                              className="h-20px fa-fw"
                              src="assets/images/icon/shield-outline-filled.svg"
                              alt=""
                            />
                            <div className="ms-2">Privacy Settings</div>
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className="card-footer text-center py-2">
                      <Link
                        className="btn btn-link text-secondary btn-sm"
                        to={`/userProfile/${user?.uid}`}
                      >
                        View Profile
                      </Link>
                    </div>
                  </div>
                </div>
                <StaticLinks />
              </div>
            </nav>
          </div>

          <div className="col-lg-6 vstack gap-4">
            <div className="tab-content p-0 mb-0">
              <div
                className={`tab-pane show active fade ${
                  activeTab === "account" ? "" : "d-none"
                }`}
                id="nav-setting-tab-1"
              >
                <AccountSetting />
              </div>

              <div
                className={`tab-pane show active fade ${
                  activeTab === "brandHandler" ? "" : "d-none"
                }`}
                id="nav-setting-tab-1"
              >
                <BrandHandlerSettings />
              </div>

              <div
                className={`tab-pane show active fade ${
                  activeTab === "brands" ? "" : "d-none"
                }`}
                id="nav-setting-tab-1"
              >
                <BrandSelection />
              </div>
              <div
                className={`tab-pane show active fade ${
                  activeTab === "category" ? "" : "d-none"
                }`}
                id="nav-setting-tab-1"
              >
                <CategorySelection />
              </div>

              <div
                className={`tab-pane fade show active ${
                  activeTab === "privacy" ? "" : "d-none"
                }`}
                id="nav-setting-tab-2"
              >
                <PrivacySetting />
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default UserSettings;
