import React, { useEffect, useState } from "react";
import {
  collection,
  addDoc,
  query,
  orderBy,
  onSnapshot,
  serverTimestamp,
  doc,
  where,
  getDoc,
  getDocs
} from "firebase/firestore";
import { db } from "../FirebaseConfig";
import ProfilePicture from "../UserProfile/ProfilePicture";
import { UserAuth } from "../Context";
import { toast } from "react-toastify";
import { toastOptions } from "../lib/toastOptions";

function BrandFeedback({ userId, brandName, brandDisplayName, onReviewSubmit }) {
  const [commentText, setCommentText] = useState("");
  const [rating, setRating] = useState(0);
  const [comments, setComments] = useState([]);
  const [loggedInUser, setLoggedInUser] = useState({});
  const [brandContactEmail, setBrandContactEmail] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isReviewed, setHasReviewed] = useState(false);
  const { user } = UserAuth();
  
  useEffect(() => {
    // Check if the user has already submitted a review for the brand
    const checkReviewStatus = async () => {
      const reviewsRef = collection(doc(db, "brands", brandName), "reviews");
      const q = query(reviewsRef, where("userId", "==", user.uid));
      const querySnapshot = await getDocs(q); // Use getDocs for queries that potentially match multiple documents
      setHasReviewed(!querySnapshot.empty); // Set to true if any documents are found
    };
  
    if (user) {
      checkReviewStatus();
    }
  }, [user, brandName]);

  const handleSubmit = async (event) => {
    setIsSubmitting(true);
    event.preventDefault();
    if (!commentText.trim()) {
      toast.warn("Review text is empty.", toastOptions);
      setIsSubmitting(false);
      return;
    }

    const brandRef = doc(db, "brands", brandName); // Ensure brandName is the document ID
    const reviewData = {
      comment: commentText,
      rating,
      userId: userId,
      userName : loggedInUser?.firstName,
      userPhoto: loggedInUser?.photo,
      timestamp: serverTimestamp(),
    };

    try {
      await addDoc(collection(brandRef, "reviews"), reviewData);
      setCommentText("");
      setRating(0);
      toast.success("Review submitted!", toastOptions);
      onReviewSubmit();
      setHasReviewed(true);
      sendEmailtoBrandContact(brandContactEmail, reviewData);
    } catch (error) {
      toast.error("Error submitting review: " + error.message);
    }

    setIsSubmitting(false);
  };

  const fetchComments = () => {
    const brandRef = doc(db, "brands", brandName);
    const reviewsRef = collection(brandRef, "reviews");
    const q = query(reviewsRef, orderBy("timestamp", "desc"));

    // This directly returns the unsubscribe function from onSnapshot
    return onSnapshot(q, (querySnapshot) => {
      const newComments = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        // Populate user data later
      }));
      setComments(newComments);
    }, (error) => {
      console.error("Error fetching comments:", error);
    });
  };

  useEffect(() => {
    const unsubscribe = fetchComments();  // Assign the unsubscribe function

    return () => {
      unsubscribe();  // Correct usage to clean up the subscription
    };
  }, [brandName]);


  useEffect(() => {
    const fetchBrandContact = async () => {
      const brandRef = doc(db, "brands", brandName);
      const brandSnap = await getDoc(brandRef);
      if (brandSnap.exists() && brandSnap.data().contactEmail) {
        setBrandContactEmail(brandSnap.data().contactEmail);
      }
    };
    fetchBrandContact();
  }, [brandName]);

  const sendEmailtoBrandContact = async (email, reviewData) => {
    const mailOptions = {
      to: email,
      subject: `New Feedback for ${brandName}`,
      html: `
        <p>Dear ${brandName},</p>
        <p>You have received new feedback on your brand page.</p>
        <p>Rating: ${reviewData.rating} stars</p>
        <p>Comment: "${reviewData.comment}"</p>
        <p>This feedback was submitted by ${reviewData.userName}.</p>
        <p>Best Regards,<br>Team LF</p>
      `
    };

    try {
      const response = await fetch(`https://us-central1-lookflock-test-api-s.cloudfunctions.net/api/api/sendemail`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(mailOptions)
      });
      if (response.ok) {
        console.log("Email sent successfully to the brand.");
      } else {
        throw new Error("Failed to send email.");
      }
    } catch (error) {
      console.error("Error sending email:", error);
    }
  };

  useEffect(() => {
    const fetchUser = async () => {
      if (user) {
        const userRef = doc(db, "users", user.uid);
        const userSnapshot = await getDoc(userRef);
        if (userSnapshot.exists()) {
          setLoggedInUser(userSnapshot.data());
        }
      }
    };
    fetchUser();
  }, [user]);


  
  if (isReviewed == true){
    return <div className="text-center my-3">You have already reviewed {brandDisplayName}...</div>;
  }

  return (
    <div>
  <div className="d-flex align-items-center mb-2">
    <div className="avatar avatar-l me-3">
      <ProfilePicture
        userData={loggedInUser}
        className="avatar-img rounded border border-white border-3"
      />
    </div>
    <div className="star-rating text-warning me-1">
      {[...Array(5)].map((_, i) => (
        <i
          key={i}
          className={i < rating ? "bi bi-star-fill" : "bi bi-star"}
          style={{ color: "gold", cursor: "pointer" }}
          onClick={() => setRating(i + 1)}
        />
      ))}
    </div>
  </div>
  <form className="d-flex align-items-center" onSubmit={handleSubmit}>
    <textarea
      className="form-control"
      style={{ flexGrow: 1, marginRight: '8px' }}  // Apply flex-grow and margin-right for spacing
      rows="1"
      placeholder="Give your review..."
      value={commentText}
      onChange={(e) => setCommentText(e.target.value)}
      required
    ></textarea>
    <button className="btn btn-primary text-white" type="submit" disabled={isSubmitting}>
      {isSubmitting ? "Submitting..." : "Submit"}
    </button>
  </form>
</div>
  );
}

export default BrandFeedback;
