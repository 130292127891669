import React, { useEffect, useState } from "react";
import { UserAuth } from "../Context";
import { Link } from "react-router-dom";
import { onSnapshot, doc, collection, getDoc } from "firebase/firestore";
import { db } from "../FirebaseConfig";
import ProfilePicture from "../UserProfile/ProfilePicture";
import ShareMenu from "../Referral/ShareMenu";
import FriendRequestNotification from "../Notifications/NewFriendRequestNotification";
import Notification from "../Notifications/Notification";
import { useFetchUserData } from "../hooks/user/useFetchUserData";
import { Dropdown } from "react-bootstrap";
import Chat from "../Chat/Chat";
function UserMenuHeader() {
  const { user, logOut } = UserAuth(); //state defined for theme
  const [theme, setTheme] = useState("");
  const [friendRequestslist, setRequestlist] = useState([]);
  const [totalFavoritesCount, setTotalFavorites] = useState(0);
  const [totalShoppingListCount, setTotalShoppingList] = useState(0);
  const { userData } = useFetchUserData(user?.uid);

  React.useEffect(() => {
    setTheme(localStorage.getItem("theme"));
  }, [theme]);

  const signOutUser = async () => {
    logOut();
  };
  useEffect(() => {
    if (!user) {
      setTotalFavorites(0);
      setTotalShoppingList(0);
    }
  }, [user]);

  useEffect(() => {
    const fetchFavoritesCount = async () => {
      if (!user) return;

      const favoritesRef = collection(db, "users", user.uid, "favourites");
      try {
        const unsubscribeFavorites = onSnapshot(favoritesRef, (snapshot) => {
          const favoritesCount = snapshot.size; // Get the size of the snapshot, which represents the number of documents in the collection
          console.log("favoritesCount", favoritesCount);
          setTotalFavorites(favoritesCount);
        });

        return () => unsubscribeFavorites(); // Unsubscribe when the component unmounts
      } catch (error) {
        console.error("Error fetching favorites count:", error);
      }
    };

    const fetchShoppingListCount = async () => {
      if (!user) return;
      const userRef = doc(db, "users", user.uid);
      try {
        const unsubscribeShoppingList = onSnapshot(userRef, (doc) => {
          const userData = doc.data();
          const shoppingListCount = userData?.shoppingListCount || 0;
          setTotalShoppingList(shoppingListCount);
        });

        return () => unsubscribeShoppingList(); // Unsubscribe when the component unmounts
      } catch (error) {
        console.error("Error fetching shopping list count:", error);
      }
    };

    fetchFavoritesCount();
    fetchShoppingListCount();
  }, [user]);
  const fetchCurrentUser = async () => {
    const loggedInUserRef = doc(db, "users", user.uid);
    const loggedUserDocSnapshot = await getDoc(loggedInUserRef);
    const requestlist = loggedUserDocSnapshot?.data()?.requestList || [];

    setRequestlist(requestlist);
  };

  // console.log('Hello from navigation friendrequestlist',friendRequestslist);

  // const renderAvatar = () => {
  //   const avatarContainerStyle = {
  //     marginRight: "10px",
  //   };

  //   if (userData) {
  //     const avatarInitials = userData.userName
  //       ? userData.userName[0].toUpperCase()
  //       : "";

  //     if (userData.photo) {
  //       const isUrl =
  //         userData.photo.startsWith("http") || userData.photo.startsWith("www");

  //       return (
  //         <div style={avatarContainerStyle} className="me-3 userHeaderAvatar">
  //           <Avatar
  //             name={avatarInitials}
  //             size={40}
  //             round={false}
  //             className="  rounded-2"
  //             src={
  //               isUrl
  //                 ? userData.photo
  //                 : `data:image/jpeg;base64,${userData.photo}`
  //             }
  //           />
  //         </div>
  //       );
  //     } else {
  //       return (
  //         <div
  //           style={avatarContainerStyle}
  //           className="me-3 nav-item ms-3 dropdown userHeaderAvatar "
  //         >
  //           <Avatar
  //             name={avatarInitials}
  //             size={40}
  //             round={false}
  //             className="bg-primary rounded-2 "
  //           />
  //         </div>
  //       );
  //     }
  //   }
  // };

  const handleTheme = (mode) => {
    setTheme(mode);
    document.documentElement.setAttribute("data-bs-theme", mode);
  };
  const handleOutsideClick = () => {
    const navbarCollapsible = document.getElementById("navbarCollapse");
    function fun() {
      if (navbarCollapsible.classList.contains("show"))
        navbarCollapsible.classList.remove("show");
    }
    setTimeout(fun, 400);
  };
  if (!userData) return;
  return (
    <>
      <li
        className="nav-item ms-2"
        onClick={() => {
          handleOutsideClick();
        }}
      >
        <Link
          className="nav-link icon-md btn btn-light p-0 position-relative "
          to="/chat"
        >
          <i className="bi bi-chat-left-text-fill fs-6"></i>
        </Link>
      </li>

      <li
        className="nav-item ms-2"
        onClick={() => {
          handleOutsideClick();
        }}
      >
        <Link
          className="nav-link icon-md btn btn-light p-0 shopping-list-link position-relative "
          to="/shoppingLists"
        >
          {totalShoppingListCount > 0 && (
            <span
              className={`d-flex justify-content-center align-items-center position-absolute translate-middle badge rounded-circle ${
                totalShoppingListCount > 9 ? "px-1" : ""
              } bg-danger`}
              style={{
                top: "14%",
                left: "87%",
                fontSize: "10px",
                minHeight: "18px",
                minWidth: "18px",
              }}
            >
              {totalShoppingListCount}
            </span>
          )}
          <i className="bi bi-list-task fs-6"></i>
        </Link>
      </li>
      <span onClick={() => handleOutsideClick()}>
        <Notification user={user} userData={userData} />
      </span>

      <li className="nav-item ms-2 dropdown">
        <style jsx>
          {`
            .dropdown-toggle:after {
              display: none;
            }
          `}
        </style>
        <Dropdown align="end" autoClose>
          <Dropdown.Toggle variant="link" className="nav-link btn icon-md p-0">
            <ProfilePicture
              userData={userData}
              className="avatar-img rounded-2"
            />
          </Dropdown.Toggle>

          <Dropdown.Menu className="dropdown-animation pt-3 small me-md-n3">
            {/* Profile Info */}
            <Dropdown.Item as="div" className="px-3">
              <div className="d-flex align-items-center position-relative">
                <div className="avatar me-3">
                  <Link
                    to={
                      user
                        ? `/userProfile/${user.uid}/profile/userAbout`
                        : "/userProfile"
                    }
                  >
                    <ProfilePicture
                      userData={userData}
                      className="avatar-img rounded-2"
                    />
                  </Link>
                </div>
                <div>
                  <Link
                    className="h6 stretched-link text-nowrap"
                    to={
                      user
                        ? `/userProfile/${user.uid}/profile/userAbout`
                        : "/userProfile"
                    }
                  >
                    {userData
                      ? userData.userName
                          .split(" ")
                          .slice(0, 2)
                          .map(
                            (word) =>
                              word.charAt(0).toUpperCase() + word.slice(1)
                          )
                          .join(" ")
                      : ""}
                  </Link>
                  <p className="small m-0">
                    <strong>{userData ? userData.userTitle : ""}</strong>
                  </p>
                </div>
              </div>
              <Link
                className="dropdown-item btn btn-primary-soft btn-sm my-2 text-center rounded-1"
                to={
                  user
                    ? `/userProfile/${user.uid}/profile/userAbout`
                    : "/userProfile"
                }
              >
                View profile
              </Link>
              <span>
                <ShareMenu />
              </span>
            </Dropdown.Item>

            <Dropdown.Divider />

            <Dropdown.Item
              as={Link}
              to="/SignIn"
              className="bg-danger-soft-hover"
              onClick={signOutUser}
            >
              <i className="bi bi-power fa-fw me-2"></i>Sign Out
            </Dropdown.Item>

            <Dropdown.Divider />

            {/* Dark Mode Options */}
            <Dropdown.Item
              as="div"
              className="modeswitch-item theme-icon-active d-flex justify-content-center gap-3 align-items-center p-2 pb-0"
            >
              <span>Mode:</span>
              <button
              aria-label="Light Mode"
                type="button"
                className={`btn btn-modeswitch nav-link text-primary-hover mb-0 ${
                  theme === "light" ? "active text-white" : ""
                }`}
                onClick={() => {
                  handleTheme("light");
                  localStorage.setItem("theme", "light");
                }}
              >
                <i className="bi bi-sun fa-fw"></i>
              </button>
              <button
              aria-label="Dark Mode"
                type="button"
                className={`btn btn-modeswitch nav-link text-primary-hover mb-0 ${
                  theme === "dark" ? "active text-white" : ""
                }`}
                onClick={() => {
                  handleTheme("dark");
                  localStorage.setItem("theme", "dark");
                }}
              >
                <i className="bi bi-moon-stars fa-fw"></i>
              </button>
              <button
              aria-label="Auto Mode"
                type="button"
                className={`btn btn-modeswitch nav-link text-primary-hover mb-0 ${
                  theme === "" || theme === "auto" ? "active text-white" : ""
                }`}
                onClick={() => {
                  handleTheme("auto");
                  localStorage.setItem("theme", "auto");
                }}
              >
                <i className="bi bi-circle-half fa-fw"></i>
              </button>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </li>
    </>
  );
}

export default UserMenuHeader;
