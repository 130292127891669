import React from "react";
import formatTimestamp from "../lib/formatTimestamp";

function PointsItem({ message, imageUrl, ...props }) {
  return (
    <div class="timeline-item">
      {/* <!-- Timeline icon --> */}
      <div class="timeline-icon">
        <div class="avatar text-center">
          <img class="avatar-img rounded" src={imageUrl} alt="" />
        </div>
      </div>
      {/* <!-- Timeline content --> */}
      <div class="timeline-content">
        <div class="d-sm-flex justify-content-between">
          <div>
            <p class="small mb-0"> {message}.</p>
            <p class="small mb-0">
              {" "}
              🏆 {props.pointsAwarded}
            </p>
          </div>
          <p class="small ms-sm-3 mt-2 mt-sm-0 text-nowrap">
            {formatTimestamp(props.timestamp)}
          </p>
        </div>
      </div>
    </div>
  );
}

export default PointsItem;
