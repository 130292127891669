import { useEffect, useState } from "react";
import { db } from "../../FirebaseConfig";
import { doc, getDoc } from "firebase/firestore";

export function useFetchProductData(productId) {
    const [productData, setProductData] = useState(null);
    const [loading, setLoading] = useState(true);
   
    useEffect(() => {
       const fetchProductData = async () => {
         if (!productId) return;
         try {
           const productDocRef = doc(db, "products", productId);
           const userDocSnapshot = await getDoc(productDocRef);
           if (userDocSnapshot.exists()) {
            setProductData({
               ...userDocSnapshot.data(),
               productId: userDocSnapshot.id,
             });
           } else {
             console.log("User document does not exist.");
           }
           setLoading(false);
         } catch (error) {
           console.error("Error fetching user data:", error.message);
         }finally{
           setLoading(false);
         }
       };
       fetchProductData();
    }, [productId]);
   
    return { productData, loading };
}
