import React, { useEffect, useState } from 'react';
import { initializeApp, getApp, getApps } from "firebase/app";
import { getFirestore, collection, getDocs, doc, updateDoc, getDoc } from "firebase/firestore";

// Configuration for live Firebase instance
const liveConfig = {
  apiKey: "AIzaSyCIMP4tW-41UihwYHtb4APvxwiYJQFQIF4",
  authDomain: "lookflock-live.firebaseapp.com",
  projectId: "lookflock-live",
  storageBucket: "lookflock-live.appspot.com",
  messagingSenderId: "850744346924",
  appId: "1:850744346924:web:8ccb61ddfc8b4ac36d8489",
};

// Configuration for test Firebase instance
const testConfig = {
  apiKey: "AIzaSyAmuCukMucqGuixzJInrI-31M_EPlcaEe8",
  authDomain: "lookflock-test-e44e1.firebaseapp.com",
  projectId: "lookflock-test-e44e1",
  storageBucket: "lookflock-test-e44e1.appspot.com",
  messagingSenderId: "1046242076270",
  appId: "1:1046242076270:web:7b62cf8d96d7bd0bb2287f",
};

// Initialize Firebase instances
const liveApp = getApps().find(app => app.name === "live") || initializeApp(liveConfig, "live");
const testApp = getApps().find(app => app.name === "test") || initializeApp(testConfig, "test");

// Get Firestore references for both instances
const liveDb = getFirestore(liveApp);
const testDb = getFirestore(testApp);

function TransferLogoUrlsComponent() {
  const [status, setStatus] = useState('Idle');

  useEffect(() => {
    async function transferLogoUrls() {
      setStatus('Transferring...');
      try {
        const liveBrandsRef = collection(liveDb, "brands");
        const liveBrandsSnapshot = await getDocs(liveBrandsRef);
        const liveBrands = liveBrandsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));

        for (const brand of liveBrands) {
          if (brand.logoUrl) {
            const testBrandDocRef = doc(testDb, "brands", brand.id);
            const testBrandDocSnapshot = await getDoc(testBrandDocRef);
            if (testBrandDocSnapshot.exists()) {
              await updateDoc(testBrandDocRef, { logoUrl: brand.logoUrl });
            } else {
              console.log(`Skipping update for brand ${brand.id} as it does not exist in the test database.`);
            }
          }
        }

        setStatus('Transfer complete.');
        console.log("Logo URLs transferred successfully.");
      } catch (error) {
        console.error("Error transferring logo URLs:", error);
        setStatus('Error during transfer.');
      }
    }

    transferLogoUrls();
  }, []);

}

export default TransferLogoUrlsComponent;
