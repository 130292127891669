import React, { useEffect, useState } from 'react';
import { collection, getDocs, query, orderBy, doc, updateDoc } from 'firebase/firestore';
import { db } from "../FirebaseConfig";
import { Link } from 'react-router-dom';
import { UserAuth } from '../Context';
import { toast } from 'react-toastify';
import { toastOptions } from '../lib/toastOptions';

const formatTimestampForPost = (timestamp) => {

  if (!timestamp || (!timestamp.toDate && !timestamp.seconds)) {
    return "N/A";
  }

  const postDate = timestamp.toDate ? timestamp.toDate() : new Date(timestamp.seconds * 1000);
  const currentDate = new Date();
  const timeDifference = currentDate - postDate;

  const hours = Math.floor(timeDifference / 3600000);
  if (hours < 1) {
    return "Less than an hour ago";
  } else if (hours < 24) {
    return `${hours} hour${hours > 1 ? 's' : ''} ago`;
  } else {
    const days = Math.floor(hours / 24);
    return `${days} day${days > 1 ? 's' : ''} ago`;
  }
};

const ReviewsList = ({ brandId, trigger }) => {
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { user } = UserAuth();
  const [commentText, setCommentText] = useState("");
  const [newRating, setNewRating] = useState(0);
  const [currentReview, setCurrentReview] = useState(null);


  const openEditModal = (review) => {
    setCurrentReview(review);
    setCommentText(review.comment);
    setNewRating(review.rating);
  };



  const handleSaveRating = async () => {
    if (!currentReview) return;

    if (!commentText.trim()) {
      toast.warn("Review text is empty.", toastOptions);
      return;
    }

    try {
      const reviewRef = doc(db, `brands/${brandId}/reviews`, currentReview.id);
      await updateDoc(reviewRef, {
        comment: commentText,
        rating: newRating
      });
      setReviews(reviews.map(r => r.id === currentReview.id ? { ...r, comment: commentText, rating: newRating } : r));
      setCurrentReview(null);
      toast.success("Review updated successfully!", toastOptions);
    } catch (error) {
      console.error("Error updating review:", error);
    }
  };


  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const reviewsRef = collection(db, `brands/${brandId}/reviews`);
        const q = query(reviewsRef, orderBy("timestamp", "desc"));
        const querySnapshot = await getDocs(q);
        const reviewsData = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));

        setReviews(reviewsData);
      } catch (error) {
        console.error("Error fetching reviews:", error);
        setError("Failed to load reviews.");
      } finally {
        setLoading(false);
      }
    };

    fetchReviews();
  }, [brandId, trigger]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="container" style={{ paddingLeft: '35px' }}>
      {reviews.length === 0 ? (
        <div className="text-center my-3">No reviews yet.</div>
      ) : (
        reviews.map((review, index) => (
          <div 
            key={review.id}
            className={`d-flex align-items-start py-3 ${index !== reviews.length - 1 ? 'border-bottom' : ''}`}
          >
            {review.userPhoto ? (

<div className="avatar avatar-l me-3">
<Link to={`/userProfile/${review.userId}`}>
      <img 
            src={review.userPhoto}
         className="avatar-img rounded border border-white border-3"
      />
                    </Link>

    </div>
            ) : (
              <div
                className="rounded border bg-secondary me-3"
                style={{ width: '50px', height: '50px' }}
              >
                <i className="bi bi-person-fill" style={{ fontSize: "24px", color: "#FFF" }}></i>
              </div>
            )}
            <div className="flex-grow-1">
            <Link to={`/userProfile/${review.userId}`}>
                <p className="fw-bold mb-1 text-body">
                  {review.userName}
                </p>
              </Link>
              <p className="mb-1">{review.comment}</p>
              <div className="d-flex mb-1">
                {[...Array(5)].map((_, starIndex) => (
                  <span key={starIndex}>
                    {starIndex < review.rating ? (
                      <i className="bi bi-star-fill text-warning"></i>
                    ) : (
                      <i className="bi bi-star text-warning"></i>
                    )}
                  </span>
                ))}
              </div>
              <p className="text small">{formatTimestampForPost(review.timestamp)}</p>
              {review.userId == user?.uid && (
              <p 
              onClick={() => openEditModal(review)} 
              style={{ textDecoration: 'underline', cursor: 'pointer', textAlign: 'right', color: 'grey' }}
            >
              Edit
            </p>
              )}
            </div>
            {reviews.length > 1 && (
            <hr/>
          )} 
          </div>
          
        ))
      )}

{currentReview && (
  <div className="modal show" style={{ display: 'block' }}>
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">Edit Your Review</h5>
          <button type="button" className="btn-close" onClick={() => setCurrentReview(null)}></button>
        </div>
        <div className="modal-body">
          {/* Assuming you have or will add a user photo URL and display name to the review data */}
          <div className="d-flex align-items-center mb-3">
            {currentReview.userPhoto ? (
              <img
                src={currentReview.userPhoto}
                alt="User profile"
                className="rounded border me-3"
                style={{ width: '45px', height: '45px' }}
              />
            ) : (
              <div
                className="rounded border bg-secondary me-3"
                style={{ width: '45px', height: '45px' }}
              >
                <i className="bi bi-person-fill" style={{ fontSize: '24px', color: 'white' }}></i>
              </div>
            )}
            <p>{currentReview.userName || "Anonymous"}</p>
          </div>
          <textarea
            className="form-control"
            rows="3"
            placeholder="Update your comment..."
            value={commentText}
            onChange={(e) => setCommentText(e.target.value)}
          ></textarea>
          <div className="d-flex justify-content-end mt-2">
            {[...Array(5)].map((_, starIndex) => (
              <span key={starIndex} onClick={() => setNewRating(starIndex + 1)}>
                {starIndex < newRating ? (
                  <i className="bi bi-star-fill text-warning" style={{ cursor: 'pointer' }}></i>
                ) : (
                  <i className="bi bi-star text-warning" style={{ cursor: 'pointer' }}></i>
                )}
              </span>
            ))}
          </div>
        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-primary" onClick={() => setCurrentReview(null)}>
            Cancel
          </button>
          <button type="button" className="btn btn-primary" onClick={handleSaveRating}>
            Save Changes
          </button>
        </div>
      </div>
    </div>
  </div>
)}
    </div>
  );
};

export default ReviewsList;
