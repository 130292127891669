import React, { useState, useEffect } from 'react';
import { doc, getDoc, setDoc, collection, getDocs } from 'firebase/firestore';
import useShoppingListProductsStore from '../StatesStore/ShoppingListProducts';
import { db } from '../FirebaseConfig';

function PollWidget({ listName, userId }) {
  const products = useShoppingListProductsStore((state) => state.getProductData(listName));
  const [votes, setVotes] = useState({});
  const [totalVotes, setTotalVotes] = useState(0);
  const [userVotes, setUserVotes] = useState({}); // Track which products the user has voted for

  // Initialize votes and fetch existing poll data
  useEffect(() => {
    if (products) {
      fetchPollData();
    }
  }, [products]);

  // Fetch poll data from Firestore
  const fetchPollData = async () => {
    try {
      const votesData = {};
      let votesTotal = 0;
      const userVotesData = {};

      // Reference to the poll subcollection
      const pollCollectionRef = collection(db, 'users', userId, 'shoppingList', listName, 'poll');
      const pollSnapshot = await getDocs(pollCollectionRef);

      // Process each poll document
      pollSnapshot.forEach((doc) => {
        const pollData = doc.data();
        votesData[doc.id] = pollData.voteCount || 0;
        votesTotal += pollData.voteCount || 0;
        
        // Check if current user has voted for this product
        if (pollData.votersId?.includes(userId)) {
          userVotesData[doc.id] = true;
        }
      });

      // Initialize products that don't have votes yet
      products.forEach((product) => {
        if (!(product.id in votesData)) {
          votesData[product.id] = 0;
        }
      });

      setVotes(votesData);
      setTotalVotes(votesTotal);
      setUserVotes(userVotesData);
    } catch (error) {
      console.error('Error fetching poll data:', error);
    }
  };

  // Update votes in Firestore (both voting and unvoting)
  const updateVotesInDb = async (productId, isUnvoting = false) => {
    try {
      // Reference to the specific product's poll document
      const pollDocRef = doc(db, 'users', userId, 'shoppingList', listName, 'poll', productId);
      const pollDoc = await getDoc(pollDocRef);

      if (pollDoc.exists()) {
        // Get current data
        const currentData = pollDoc.data();
        
        if (isUnvoting) {
          // Remove user from voters and decrease count
          const updatedVotersId = currentData.votersId.filter(id => id !== userId);
          await setDoc(pollDocRef, {
            voteCount: Math.max(0, (currentData.voteCount || 1) - 1), // Ensure count doesn't go below 0
            votersId: updatedVotersId
          });
        } else {
          // Add vote
          if (currentData.votersId?.includes(userId)) {
            console.log('User has already voted for this product');
            return false;
          }

          await setDoc(pollDocRef, {
            voteCount: (currentData.voteCount || 0) + 1,
            votersId: [...(currentData.votersId || []), userId]
          });
        }
      } else if (!isUnvoting) {
        // Create new document for first vote (only if not unvoting)
        await setDoc(pollDocRef, {
          voteCount: 1,
          votersId: [userId]
        });
      }
      
      return true;
    } catch (error) {
      console.error('Error updating votes:', error);
      return false;
    }
  };

  // Handle voting/unvoting for a product
  const handleVote = async (productId) => {
    const isUnvoting = userVotes[productId];
    
    // Update vote in database
    const actionSuccess = await updateVotesInDb(productId, isUnvoting);
    
    if (actionSuccess) {
      if (isUnvoting) {
        // Handle unvote
        setVotes((prevVotes) => ({
          ...prevVotes,
          [productId]: Math.max(0, (prevVotes[productId] || 1) - 1)
        }));
        setTotalVotes((prev) => Math.max(0, prev - 1));
        setUserVotes((prev) => {
          const newUserVotes = { ...prev };
          delete newUserVotes[productId];
          return newUserVotes;
        });
      } else {
        // Handle vote
        setVotes((prevVotes) => ({
          ...prevVotes,
          [productId]: (prevVotes[productId] || 0) + 1
        }));
        setTotalVotes((prev) => prev + 1);
        setUserVotes((prev) => ({
          ...prev,
          [productId]: true
        }));
      }
    }
  };

  // Calculate vote percentage for each product
  const getVotePercentage = (productId) => {
    if (totalVotes === 0) return 0;
    return ((votes[productId] / totalVotes) * 100).toFixed(2);
  };

  return (
    <>
      <li className="nav-item dropdown ms-lg-auto">
        <a className="nav-link icon-md btn btn-light p-0" href="#" id="pollWidget" data-bs-toggle="dropdown" aria-expanded="false">
          <i className="bi bi-clipboard-data fs-5"></i>
        </a>

        <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="pollWidget">
          <div className="card-body mt-0 pt-1">
            <h5 className="card-title">Poll Options</h5>

            <div className="vstack gap-4 gap-sm-3">
              {products && products.length > 0 ? (
                products.map((product) => (
                  <div
                    key={product.id}
                    className={`d-flex align-items-center justify-content-between ${
                      userVotes[product.id] ? 'opacity-75' : ''
                    }`}
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleVote(product.id)}
                  >
                    <div className="overflow-hidden w-100 me-2">
                      <div className="progress bg-primary bg-opacity-10 position-relative" style={{ height: '30px' }}>
                        <div
                          className="progress-bar bg-primary bg-opacity-25"
                          role="progressbar"
                          style={{ width: `${getVotePercentage(product.id)}%` }}
                          aria-valuenow={getVotePercentage(product.id)}
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                        <span className="position-absolute pt-1 ps-3 fs-6 fw-normal text-truncate w-100">
                          {product.name}
                          {userVotes[product.id]}
                        </span>
                      </div>
                    </div>
                    <div className="flex-shrink-0">{getVotePercentage(product.id)}%</div>
                  </div>
                ))
              ) : (
                <div>No products found for this list</div>
              )}
            </div>
          </div>
        </ul>
      </li>
    </>
  );
}

export default PollWidget;