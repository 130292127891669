// Helper function to format the timestamp
export const formatTimestampChat = (timestamp) => {
    if (timestamp?.seconds) {
      // Firestore timestamp (convert it to JS Date)
      const date = timestamp.toDate();
      return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
    } else if (typeof timestamp === "string") {
      // If it's already a string (from socket)
      const date = new Date();
      return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
    } else {
      // Fallback if timestamp is null or undefined
      const date = new Date();
      return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
     
    }
  };
  