import { addDoc, collection, query, where, getDocs, serverTimestamp } from "firebase/firestore";
import { db } from "../FirebaseConfig";

export const saveInviteToDb = async (inviteData, user) => {
  try {
    // Query to check if an invite with the same invitedBy and email already exists
    const inviteQuery = query(
      collection(db, "inviteList"),
      where("invitedBy", "==", user.uid),
      where("email", "==", inviteData.email)
    );

    const querySnapshot = await getDocs(inviteQuery);

    // If no matching invite found, proceed with adding the invite
    if (querySnapshot.empty) {
      await addDoc(collection(db, "inviteList"), {
        ...inviteData,
        invitedBy: user.uid,
        timestamp: serverTimestamp(),
        joined: false
      });
      console.log("Invite added to Firestore");
    } else {
      console.log("Invite already exists for this email and invitedBy");
    }
  } catch (error) {
    console.error("Error adding invite to Firestore: ", error);
  }
};
