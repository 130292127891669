import React from "react";
import { Link } from "react-router-dom";
import { Card } from "react-bootstrap";
const ShopProductsCard = ({ product }) => {
  //   console.log("ShopPostsCard", shopPost);
  //   console.log("ShopPostsCard product", product);

  return (
    product && (
      <div className="d-flex flex-column p-0 px-1 mt-1" id="cardHover">
        <Card className="bg-transparent border-0 ">
          <Link to={`/ProductDetails/${product?.id}`}>
            <Card.Img
              variant="top"
              className="rounded"
              src={product?.url}
              style={{
                // height: `${randomNumberInRange(280, 200)}px`,
                objectFit: "cover",
                height: "13.5rem",
                // height: "16rem",
              }}
            />
          </Link>
        </Card>
      </div>
    )
  );
};

export default ShopProductsCard;
