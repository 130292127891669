import { create } from "zustand";

const useRenderCountStore = create((set, get) => ({
  componentRenderCounts: {},
  incrementRenderCount: (componentName) => {
    set((state) => ({
      componentRenderCounts: {
        ...state.componentRenderCounts,
        [componentName]: (state.componentRenderCounts[componentName] || 0) + 1,
      },
    }));
  },
  getRenderCount: (componentName) => {
    return get().componentRenderCounts[componentName] || 0;
  },
}));

export default useRenderCountStore;