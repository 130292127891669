import React, { useEffect } from "react";
import { db } from "../FirebaseConfig"; // Firestore config
import {
  addDoc,
  collection,
  doc,
  increment,
  serverTimestamp,
  setDoc,
  Timestamp,
  updateDoc,
} from "firebase/firestore"; // Firestore imports

function UpdateLastLogin({ userID }) {
  useEffect(() => {
    const updateLastLogin = async () => {
      try {
        const userDocRef = doc(db, "lastLogin", userID); // Reference to document with userID
        // Update the document with the current timestamp
        await setDoc(
          userDocRef,
          {
            lastLogin: Timestamp.now(),
          },
          { merge: true }
        ); // Use merge to avoid overwriting existing data
        console.log(`Updated last login timestamp for user ${userID}`);
        const userLoginRef = collection(db, "users", userID, "loginActivity");
        await addDoc(userLoginRef, { timestamp: serverTimestamp() });
        const userRef = doc(db, "users", userID);
        await updateDoc(userRef, { loginCount: increment(1) });
      } catch (error) {
        console.error("Error updating last login:", error);
      }
    };

    if (userID) {
      updateLastLogin();
    }
  }, [userID]);

  return null; // No UI needed for this utility function
}

export default UpdateLastLogin;
