// updateBrandPFP.js
import React, { useCallback, useState } from "react";
// import ProfilePicture from "./ProfilePicture";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../FirebaseConfig";
import { useParams } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import Cropper from "react-easy-crop";

const UpdateBrandPFP = ({
  BrandId,  // Assume BrandId is passed to this component
  brandData,
  avatarSize,
  className,
  onLogoUpdate, // Callback to invoke after updating the logo
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const triggerFileInput = () => {
    document.getElementById("fileInput").click();
  };

  const getCroppedImg = async (imageSrc, crop) => {
    // Function to create image and canvas to crop the image
    const createImage = (url) =>
      new Promise((resolve, reject) => {
        const image = new Image();
        image.onload = () => resolve(image);
        image.onerror = reject;
        image.setAttribute("crossOrigin", "anonymous");
        image.src = url;
      });

    const image = await createImage(imageSrc);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    canvas.width = crop.width;
    canvas.height = crop.height;

    ctx.drawImage(
      image,
      crop.x,
      crop.y,
      crop.width,
      crop.height,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve) => {
      resolve(canvas.toDataURL("image/jpeg"));
    });
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setImageSrc(reader.result);
      setShowModal(true);
    };
  };

  function base64ToFile(base64String, fileName, mimeType) {
    let byteString = atob(base64String.split(",")[1]);
    let arrayBuffer = new ArrayBuffer(byteString.length);
    let uint8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      uint8Array[i] = byteString.charCodeAt(i);
    }
    return new File([uint8Array], fileName, { type: mimeType });
  }

  const handleSaveCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels);
      setShowModal(false);
      const file = base64ToFile(croppedImage, "brandLogo.jpg", "image/jpeg");
      const storage = getStorage();
      const storageRef = ref(storage, `brandLogos/${BrandId}`);

      const snapshot = await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(snapshot.ref);
      console.log('brand Id in save crop :',BrandId);
      const brandDocRef = doc(db, "brands", BrandId);
      await updateDoc(brandDocRef, {
        logoUrl: downloadURL,
      });

      console.log("Brand logo updated successfully:", downloadURL);
      if (onLogoUpdate) {
        onLogoUpdate(downloadURL); // Notify the parent to update the UI or re-fetch data
      }
    } catch (error) {
      console.error("Error uploading brand logo:", error);
    }
  }, [croppedAreaPixels, imageSrc]);

  return (
    <>
      <div
        className={className}
        style={{
          width: avatarSize,
          height: avatarSize,
          position: "relative",
          borderRadius: "10px",
          overflow: "hidden",
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <img
          src={brandData?.logoUrl || "defaultLogoPath.jpg"} // Use a default path if logoUrl is not available
          alt="Brand Logo"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover", // Ensures the image covers the div without distortion
          }}
          onClick={triggerFileInput}
        />
        <span
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0, 0, 0, 0.6)",
            color: "white",
            fontSize: "12px",
            fontWeight: "bold",
            // borderRadius: "50%",
            pointerEvents: "none", // Ensure clicks go to the input
            opacity: isHovered ? 1 : 0, // Change opacity based on isHovered
            transition: "opacity 0.3s", // Smooth transition
          }}
          
        >
          Change Picture
        </span>
      </div>
      <input
        type="file"
        id="fileInput"
        style={{ display: "none" }}
        onChange={handleFileChange}
        accept="image/*"
      />
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Crop Your Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="crop-container" style={{ height: 300, width: "100%", backgroundColor: "#f8f9fa" }}>
            <Cropper
              image={imageSrc}
              crop={crop}
              zoom={zoom}
              aspect={1}
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onCropComplete={onCropComplete}
              cropShape="square"
            />
          </div>
          <div className="mt-3">
            <label>Zoom:</label>
            <input
              type="range"
              value={zoom}
              min={1}
              max={3}
              step={0.1}
              onChange={(e) => setZoom(e.target.value)}
              className="form-range"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>Cancel</Button>
          <Button variant="primary" onClick={handleSaveCroppedImage}>Save Cropped Image</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
  
};

export default UpdateBrandPFP;
