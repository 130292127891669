import React, { useEffect, useRef, useState } from "react";
import ProfilePicture from "../UserProfile/ProfilePicture";
import useSocketStore from "../StatesStore/Socket";
import { saveMessageToDB } from "./lib.js/saveMsgToDb";
import { getMessagesFromDB } from "./lib.js/getMessagesFromDB";
import { formatTimestampChat } from "./lib.js/formatTimestampChat";
import { doc, getDoc, serverTimestamp } from "firebase/firestore";
import { db } from "../FirebaseConfig";
import ProductCard5 from "../Products/ProductCard5";
import UserCard from "../Cards/UserCard";
import { decryptChat, encryptChat } from "../ShoppingList/Encryption";

function ChatToast({ isOpen, onClose, userData, user, uid }) {
  const [msg, setMsg] = useState("");
  const [msgs, setMsgs] = useState([]);
  const [isRecipientOnline, setIsRecipientOnline] = useState(false);
  const socket = useSocketStore((state) => state.socket);
  const endRef = useRef();
  const messagesEndRef = useRef(null);
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  useEffect(() => {
    scrollToBottom();
  }, [msgs]);

  const fetchDetails = async (type, id) => {
    try {
      const collectionName = type === "product" ? "products" : "posts";
      const docRef = doc(db, collectionName, id);
      const docSnapshot = await getDoc(docRef);
      return docSnapshot.exists() ? docSnapshot.data() : null;
    } catch (error) {
      console.error(`Error fetching ${type} details:`, error);
      return null;
    }
  };
  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const fetchedMessages = await getMessagesFromDB(user, uid);

        const enrichedMessages = await Promise.all(
          fetchedMessages.map(async (msg) => {
            // Select the correct ID based on message type
            const id =
              msg?.type === "product"
                ? msg?.productId
                : msg?.type === "post"
                ? msg?.postId
                : null;

            if (id) {
              const details = await fetchDetails(msg?.type, id);
              return { ...msg, details };
            }
            return msg;
          })
        );

        console.log("enrichedMessages", enrichedMessages);
        setMsgs(enrichedMessages);
      } catch (error) {
        console.error("Error fetching messages:", error);
      }
    };

    fetchMessages();

    socket.on("chat msg", (msgrecv) => {
      setMsgs((prevMsgs) => [...prevMsgs, msgrecv]);
    });
    socket.on("user status", ({ userId, status }) => {
      if (userId === uid) {
        setIsRecipientOnline(status === "online");
      }
    });

    // Request initial status of the recipient
    socket.emit("get user status", uid);
    // return () => socket.close();
  }, [user?.uid, uid]);
  useEffect(() => {
    // Scroll to the bottom whenever the msgs array changes (new message received or sent)
    if (endRef.current) {
      endRef.current.scrollIntoView({ behavior: "smooth" });
      endRef.current.scrollTop = endRef.current.scrollHeight;
    }
  }, [msgs]);
  const sendMsg = (e) => {
    e.preventDefault();

    if (msg?.trim() === "") {
      return;
    }

    const msgToBeSent = {
      content: encryptChat(msg),
      receiverId: uid,
      senderId: user?.uid,
      type: "text",
    };

    if (socket) {
      socket.emit("chat msg", msgToBeSent);
      setMsgs((prevMsgs) => [...prevMsgs, msgToBeSent]);
    }
    setMsg("");
    endRef.current.scrollIntoView({ behavior: "smooth" });
    saveMessageToDB(msgToBeSent);
  };

  return (
    <>
      <div
        aria-live="polite"
        aria-atomic="true"
        className="position-relative"
        data-bs-scroll="true"
      >
        <div className="toast-container toast-chat d-flex gap-3 align-items-end custom-scrollbar">
          <div
            id="chatToast"
            className={`toast mb-0 bg-mode ${isOpen ? "show" : ""}`}
            role="alert"
            aria-live="assertive"
            aria-atomic="true"
            data-bs-autohide="false"
          >
            <div className="toast-header bg-mode">
              <div className="d-flex justify-content-between align-items-center w-100">
                <div className="d-flex">
                  <div className="flex-shrink-0 avatar me-2 ">
                    <ProfilePicture
                      userData={userData}
                      className="avatar-img rounded"
                    />
                  </div>
                  <div className="flex-grow-1">
                    <h6 className="mb-0 mt-1">{userData?.userName}</h6>
                    <div className="small text-secondary">
                      <i
                        className={`fa-solid fa-circle ${
                          isRecipientOnline ? "text-success" : "text-muted"
                        } me-1`}
                      ></i>
                      {isRecipientOnline ? "Online" : "Offline"}
                    </div>
                  </div>
                </div>
                <div className="d-flex">
                  <a
                    className="btn btn-secondary-soft-hover py-1 px-2"
                    data-bs-toggle="collapse"
                    href="#collapseChat"
                    aria-expanded="false"
                    aria-controls="collapseChat"
                  >
                    <i className="bi bi-dash-lg"></i>
                  </a>
                  <button
                    className="btn btn-secondary-soft-hover py-1 px-2"
                    onClick={onClose}
                    aria-label="Close"
                  >
                    <i className="fa-solid fa-xmark"></i>
                  </button>
                </div>
              </div>
            </div>
            <div className="toast-body collapse show" id="collapseChat">
              <div className="chat-conversation-content custom-scrollbar h-300px overflow-y-auto">
                {msgs.map((msgItem, index) => (
                  <div
                    key={index}
                    className={`d-flex mb-1 ${
                      msgItem.senderId === user?.uid
                        ? "justify-content-end"
                        : ""
                    }`}
                  >
                    {msgItem.senderId !== user?.uid && (
                      <div className="flex-shrink-0 avatar avatar-xs me-2">
                        <ProfilePicture
                          userData={userData}
                          className="avatar-img rounded"
                        />
                      </div>
                    )}
                    <div className="flex-grow-1">
                      <div className="w-100">
                        <div
                          className={`d-flex flex-column ${
                            msgItem.senderId === user?.uid
                              ? "align-items-end"
                              : "align-items-start"
                          }`}
                        >
                          {msgItem.type === "product" && msgItem.details && (
                            <ProductCard5
                              product={msgItem.details}
                              isStrip={false}
                            />
                          )}
                          {msgItem.type === "post" && msgItem.details && (
                            <UserCard post={msgItem.details} />
                          )}
                          {msgItem.content && (
                            <div
                              className={`p-2 px-3 rounded-2 ${
                                msgItem.senderId === user?.uid
                                  ? "bg-primary text-white"
                                  : "bg-light text-secondary"
                              }`}
                            >
                              {decryptChat(msgItem.content)}
                            </div>
                          )}
                          <div className="small my-2 ">
                            {formatTimestampChat(msgItem.timestamp)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                <div ref={messagesEndRef} />
              </div>
              <div className="mt-2">
                <textarea
                  className="form-control mb-sm-0 mb-3"
                  placeholder="Type a message"
                  rows="1"
                  value={msg}
                  onChange={(e) => setMsg(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      sendMsg(e);
                    }
                  }}
                ></textarea>
                <div className="d-sm-flex align-items-end mt-2">
                  <button
                    className="btn btn-sm btn-primary ms-auto"
                    onClick={sendMsg}
                  >
                    Send
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div ref={endRef}></div>
    </>
  );
}

export default ChatToast;
