import React, { useEffect, useState } from "react";
import { db } from "../FirebaseConfig";
import {
  collection,
  query,
  orderBy,
  limit,
  getDocs,
} from "firebase/firestore";
import { Link } from "react-router-dom";
import ProfilePicture from "../UserProfile/ProfilePicture";
import { UserAuth } from "../Context";


export default function RecentlyJoinedUsers() {
  const [users, setUsers] = useState([]);
  const { user } = UserAuth();
    
  useEffect(() => {
    const fetchUsers = async () => {
      try {
    
        const usersCollection = collection(db, "users");
        const q = query(usersCollection, orderBy("timestamp", "desc"), limit(5));
        const snapshot = await getDocs(q);

        const userList = [];
        snapshot.forEach((doc) => {
          userList.push({ ...doc.data(), uid: doc.id });
        });

        setUsers(userList);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, [user]);


  return (
    <>
      {/* Card follow START */}
      <div className="col-sm-6 col-lg-12">
        <div className="card m-1">
          {/* Card header START */}
          <div className="d-flex  justify-content-center align-items-center bg-transparent  p-1 border-0">
            {users && <h6 className=" pt-2">Recently Joined</h6>}
          </div>
          {/* Card header END */}
          {/* Card body START */}
        </div>
        <div className="card-body">
          <ul className="list-group list-group-flush list-unstyled ">
            {users.map((user) => (
              /* Connection item START */
              <div
                className="d-flex m-1"
                style={{
                  display: "inline-flex",
                  gap: "6px",
                  cursor: "pointer",
                }}
                key={user.uid}
              >
                {/* Avatar */}
                <div className="avatar">
                  <Link to={`/userProfile/${user.uid}`}>
                    <ProfilePicture
                      userData={user}
                      className="avatar-img rounded"
                    />
                  </Link>
                </div>
                {/* Title */}
                <Link to={`/userProfile/${user.uid}`} className="card w-100 d-flex align-items-center justify-content-center "  style={{ textDecoration: "none", color: "inherit" }}>
                  <div className="ms-3 w-100 d-flex justify-content-between  ">
                  {/* <Link to={`/userProfile/${user.uid}`}> */}
                    <div className="d-flex flex-grow-1">
                      <p className="small mb-0">
                      
                        <b>{user?.userName}</b>
                    
                      </p>
                    </div>
                    
                  </div>
                  </Link>
                {/* Button */}
              </div>
              /* Connection item END */
            ))}
          </ul>
        </div>
        {/* Card body END */}
      </div>
      {/* Card follow START */}
    </>
  );
}
