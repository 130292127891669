const handleLikeClick = async (userId, product) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API}/api/like`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ userId, product }),
    });

    await response.json();
    console.log("Liked product successfully!");
  } catch (error) {
    console.error("Error updating document: ", error);
  }

  return true;
};
export default handleLikeClick;
