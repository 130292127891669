import React, { useEffect, useState } from 'react';
import formatTimestamp from '../lib/formatTimestamp';
import { useNavigate } from 'react-router-dom';
import ProfilePicture from '../UserProfile/ProfilePicture';
import { db } from '../FirebaseConfig';
import { doc, updateDoc } from 'firebase/firestore';
import useSocketStore from '../StatesStore/Socket';
import { useFetchBrandData } from '../hooks/brand/useFetchBrandData';
import { UserAuth } from '../Context';

function BrandHandlerInvite({ notification }) {
  const {user} = UserAuth();
  const brandName = notification?.id || [];
  const route = `/settings/brandHandler?Invited=${brandName}`;
  const { BrandData } = useFetchBrandData(brandName);

  const navigate = useNavigate();
  const [isSeen, setIsSeen] = useState(notification.seen);
  const [isRecipientOnline, setIsRecipientOnline] = useState(false);
  const socket = useSocketStore((state) => state.socket);

  const message =<>
  You have been invite to become brand handler for <b>{BrandData?.displayName}</b>
  </> 


  const handleNavigate = async () => {
    try {
      navigate(route);

      
      if (!isSeen) {
        setIsSeen(true); 
        const notificationRef = doc(db, 'users', user?.uid, 'notifications', notification.notificationId);
        await updateDoc(notificationRef, { seen: true });
        
      }
    } catch (error) {
      console.error('Error updating notification: ', error);
    }
  };

  return (
    <li onClick={handleNavigate} style={{ cursor: 'pointer' }}>
         <div
        className={`list-group-item list-group-item-action rounded ${
          isSeen ? '' : 'badge-unread'
        } d-flex border-0 mb-1 p-3 position-relative`}
      >
        <div className={`avatar text-center d-none d-sm-inline-block ${isRecipientOnline?"status-online":" "}`}>
          <img
            class="avatar-img rounded"
            src={
                BrandData?.logoUrl ??
              `https://ui-avatars.com/api/?name=${BrandData?.logoUrl}`
            }
            alt=""
          />    
        </div>
        <div className="ms-sm-3 d-flex justify-content-between w-100">
          <div>
            <p className="small mb-0">
              <b>{BrandData?.DisplayName}</b> {message}
            </p>
          </div>
          <p className="small ms-3">{formatTimestamp(notification.timestamp)}</p>
        </div>
      </div>
    </li>
  );
}

export default BrandHandlerInvite;
