import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AddComment from "../components/AddComment";
import ShowComment from "../components/ShowComment";
import { UserAuth } from "../Context";
import {
  doc,
  getDoc,
  collection,
  getCountFromServer,
  increment,
  serverTimestamp,
  runTransaction,
} from "firebase/firestore";
import { db } from "../FirebaseConfig";
import useModalStore from "../StatesStore/ModalStore";
import { dataFeedReaction, titleFeedReaction } from "../Content/ModalContent";
import CommentModal from "../Modals/CommentModal";
import useNotification from "../hooks/useNotification";
import usePoints from "../hooks/user/usePoints";
import usePointsStore from "../StatesStore/PointsTable";

export default function PostReaction({ post }) {
  // console.log("I am from feed Reaction");
  const { user } = UserAuth();
  const [isLiked, setIsLiked] = useState(false);
  const [likesCount, setLikesCount] = useState(0);
  const [commentCount, setCommentCount] = useState(0);
  const [productData, setproductData] = useState();
  const { openModal } = useModalStore();
  const { addNotification, deleteNotification } = useNotification();
  const { addPoints, removePoints } = usePoints();
  const pointsTable = usePointsStore((state) => state.pointsTable);

  const [User, setUser] = useState(user);
  const [showCommentModal, setShowCommentModal] = useState(false);
  const handleShow = () => setShowCommentModal(true);
  const handleClose = () => setShowCommentModal(false);
  const handleClick = () => {
    if (!user) {
      // Check if user is not logged in before opening modal
      openModal("SignInModal", titleFeedReaction, dataFeedReaction); // Open modal here
    }
  };
  // console.log("Post from feed reaction", post);
  const likePost = async () => {
    if (user) {
      const postRef = doc(db, "posts", post?.id);
      const reactionRef = doc(db, "posts", post?.id, "reactions", user?.uid);

      try {
        await runTransaction(db, async (transaction) => {
          const reactionSnapshot = await transaction.get(reactionRef);

          if (reactionSnapshot.exists()) {
            // If the reaction already exists, remove it and decrement reactionCount
            setLikesCount((prevCount) => prevCount - 1);
            setIsLiked(false);
            transaction.delete(reactionRef);
            transaction.update(postRef, {
              reactionCount: increment(-1),
            });

            if (post.userId !== user?.uid) {
              await deleteNotification({
                userId: post?.userId,
                id: user?.uid,
                type: "postLike",
                by: "user",
                fields: {
                  postId: post?.id,
                },
              });
            }

            //deleting points
            const postingPoints = pointsTable.likeAPostByOtherUser || 0;
            removePoints({
              userId: post.userId,
              type: "likeAPostByOtherUser",
              pointsRemoved: postingPoints,
              fields: {
                postId: post?.id,
                likedBy: user?.uid,
              },
            });
          } else {
            // If no reaction exists, add it and increment reactionCount
            setLikesCount((prevCount) => prevCount + 1);
            setIsLiked(true);
            transaction.set(reactionRef, {
              timestamp: serverTimestamp(),
              type: "like",
            });
            transaction.update(postRef, {
              reactionCount: increment(1),
            });

            // Add notification if it's not the user's own post
            if (post.userId !== user.uid) {
              await addNotification({
                userId: post?.userId,
                id: user?.uid,
                timestamp: serverTimestamp(),
                type: "postLike",
                by: "user",
                fields: {
                  postId: post?.id,
                },
              });
            }

            //adding points
            const postingPoints = pointsTable.likeAPostByOtherUser || 0;

            addPoints({
              userId: post.userId,
              type: "likeAPostByOtherUser",
              pointsAwarded: postingPoints,
              fields: {
                postId: post?.id,
                likedBy: user?.uid,
              },
            });
          }
        });

        // External like product logging API
        if (productData) {
          await fetch(process.env.REACT_APP_API + `/api/brandLog/${user.uid}`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              brandName: productData.supplier,
              categories: productData.category,
              subCategories: productData.subCategory,
              subSubCategories: productData.subSubCategory,
            }),
          })
            .then((response) => response.json())
            .then((data) => {
              // console.log("Like product logging response:", data);
            })
            .catch((error) => {
              console.error("Error calling like product logging API:", error);
            });
        }
      } catch (error) {
        console.error("Transaction failed: ", error);
      }
    }
  };

  useEffect(() => {
    const fetchLikes = async () => {
      try {
        const postRef = doc(db, "posts", post?.id);
        const postSnapshot = await getDoc(postRef);
        const postData = postSnapshot.data();

        // Fetching the reactionCount directly from the post document
        if (postData) {
          setLikesCount(postData.reactionCount || 0); // Use reactionCount

          // Check if the user has liked the post by looking for the document in 'reactions'
          const reactionRef = doc(
            db,
            "posts",
            post?.id,
            "reactions",
            user?.uid
          );
          const reactionSnapshot = await getDoc(reactionRef);
          setIsLiked(reactionSnapshot.exists()); // Set whether the user has liked the post
        }
      } catch (error) {
        console.error("Error fetching likes:", error);
      }
    };

    if (post?.id && user?.uid) {
      fetchLikes();
    }
  }, [post?.id, user?.uid]);

  useEffect(() => {
    const getCount = async () => {
      try {
        const postRef = doc(db, "posts", post?.id);
        const commentsCollectionRef = collection(postRef, "comments");
        const snapshot = await getCountFromServer(commentsCollectionRef);
        setCommentCount(snapshot.data()?.count);
      } catch (error) {
        console.error("Error getting comment count:", error);
      }
    };
    getCount();
  }, [post?.id]);

  return (
    <>
      {/* Feed react START */}
      {/* {(commentCount > 0 || likesCount > 0) && (
        <ul className="nav nav-stack py-2 small">
          <li className="nav-item">
            <Link className="nav-link ">
              {" "}
              {likesCount > 0 && (
                <i className="bi bi-hand-thumbs-up-fill pe-1"></i>
              )}{" "}
              {isLiked && likesCount === 1 ? "You" : " "}
              {isLiked && likesCount > 1
                ? `You and ${likesCount - 1} other${likesCount > 2 ? "s" : ""}`
                : ""}
              {!isLiked && likesCount > 0 ? `${likesCount} ` : ""}
            </Link>
          </li>
          <li className="nav-item ms-sm-auto">
            <a
              className="nav-link mb-0 "
              data-bs-toggle="collapse"
              href={`#multiCollapseExample${post.id}`}
              role="button"
              aria-expanded="false"
              aria-controls={`multiCollapseExample${post.id}`}
              hidden={commentCount === 0} // Hide the link if there are no comments
            >
              <i className="bi bi-chat-fill pe-1"> </i>
              {commentCount === 1 ? "1 comment" : `${commentCount} comments`}
            </a>
          </li>
        </ul>
      )} */}
      {/* Feed react END */}
      {/* Feed react START */}
      <ul className="nav nav-fill nav-stack small py-1 pt-0">
        <li className="nav-item">
          {user && (
            <Link
              className={`nav-link my-0 pt-0 ${isLiked ? "active" : ""}`}
              onClick={likePost}
            >
              {" "}
              <i
                className={`bi bi-hand-thumbs-up${isLiked ? "-fill" : ""} pe-1`}
              ></i>
              Likes ({likesCount})
            </Link>
          )}

          {!user && (
            <Link className={`nav-link my-0 pt-0 `} onClick={handleClick}>
              {" "}
              <i
                className={`bi bi-hand-thumbs-up${isLiked ? "-fill" : ""} pe-1`}
              ></i>
              Like
            </Link>
          )}
        </li>
        {/* Card share action menu START */}
        <li className="nav-item dropdown">
          <a
            className="nav-link my-0 pt-0"
            data-bs-toggle="collapse"
            href={`#multiCollapseExample${post.id}`}
            role="button"
            aria-expanded="false"
            aria-controls={`multiCollapseExample${post.id}`}
          >
            <i className="bi bi-chat ps-1"></i> Comments ({commentCount})
          </a>
        </li>
        {/* Card share action menu END */}
      </ul>
      {/* <ul className="nav nav-stack py-3 small">
        <li className="nav-item">
          <Link className="nav-link " to="#!">
            {" "}
            <i className="bi bi-hand-thumbs-up-fill pe-1"></i> Liked (56)
          </Link>
        </li>
        <li className="nav-item ms-sm-auto">
          <Link className="nav-link" to="#!">
            {" "}
            <i className="bi bi-chat-fill pe-1"></i>Comments (12)
          </Link>
        </li>
      </ul> */}
      {/* Feed react END */}

      {/*  */}
      {/* <CommentModal
        show={showCommentModal}
        handleClose={handleClose}
        user={user}
        post={post}
        commentCount={commentCount}
        setCommentCount={setCommentCount}
      /> */}
      <div className="col">
        <div
          className="collapse multi-collapse"
          id={`multiCollapseExample${post.id}`}
        >
          <div>
            {user && (
              <AddComment
                post={post}
                setCommentCount={setCommentCount}
                user={user}
                type={"comment"}
              />
            )}
            {commentCount > 0 && (
              <ShowComment
                post={post}
                commentCount={commentCount}
                setCommentCount={setCommentCount}
                user={user}
              />
            )}
          </div>
        </div>
      </div>
      {/* Feed react END */}
    </>
  );
}
