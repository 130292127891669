import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../../FirebaseConfig";
import { UserAuth } from "../../Context";
import { Card } from "react-bootstrap";
import UserDisplay from "../../UserProfile/UserDisplay";
import { useFetchUserData } from "../../hooks/user/useFetchUserData";
import ShopProducts from "./ShopProducts";
import PostReaction from "../PostReaction";

function ShoppingListPostCard() {
  const { user } = UserAuth();
  const [post, setPost] = useState();
  const { userData } = useFetchUserData(post?.userId);
  const fetchShoppingListPostData = async () => {
    //Fetching mechanism will change according to the data we get
    const shopPostRef = query(
      collection(db, "posts"),
      where("userId", "==", user?.uid),
      where("postType", "==", "shoppingList"),
      orderBy("dateCreated", "desc") // Sorting by descending order
    );
    const querySnapshot = await getDocs(shopPostRef);
    const data = await Promise.all(
      querySnapshot.docs.map(async (doc) => {
        const productRef = collection(db, "posts", doc?.id, "media");
        const productSnap = await getDocs(productRef);
        const products = productSnap.docs.map((doc) => ({
          id: doc.id,
          url: doc.data().url,
        }));
        return { id: doc.id, products: products, ...doc.data() };
      })
    );
    console.log("post", data[0]);
    setPost(data[0]);
  };
  useEffect(() => {
    fetchShoppingListPostData();
  }, []);

  return (
    post && (
      <Card className=" m-2 mt-1 bg-transparent border-0">
        <UserDisplay userData={userData} post={post} />
        <div className="card m-0 mt-1 px-1 pt-2 pb-0">
          <div className="title px-2 mb-0">
            {/* <h5>{post?.title}</h5> */}
            <p className="m-0">{post?.description}</p>
          </div>
          <ShopProducts shopPost={post} />
          <div className="card-footer px-2 p-0 m-0 border-0">
            <PostReaction post={post} />
          </div>
        </div>
      </Card>
    )
  );
}

export default ShoppingListPostCard;
