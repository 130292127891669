import React, { useState, useEffect, useRef } from "react";
import {
  collection,
  addDoc,
  query,
  orderBy,
  getDoc,
  doc,
  where,
  onSnapshot,
  serverTimestamp,
  getDocs,
  updateDoc,
  setDoc,
  increment,
} from "firebase/firestore";
import Picker from "emoji-picker-react";
import { Link } from "react-router-dom";
import { db } from "../FirebaseConfig";
import { formatTimestampForPost } from "../lib/formatTimestampForPost";
import { UserAuth } from "../Context";
import ProfilePicture from "../UserProfile/ProfilePicture";
import useModalStore from "../StatesStore/ModalStore";
import { toast } from "react-toastify";
import { toastOptions } from "../lib/toastOptions";
import useNotification from "../hooks/useNotification";
import useSocketStore from "../StatesStore/Socket";
import useUserDataStore from "../StatesStore/UserData";
import { decryptChat, encryptChat } from "./Encryption";
import { saveListMessageToDb } from "./lib/saveListMessageToDb";
import ProductCard3 from "../Products/ProductCard3";
import { formatTimestampChat } from "../Chat/lib.js/formatTimestampChat";
const SharedListComments = ({
  shoppingListData,
  selectedShopList,
  setShoppingListData,
  userId,
  listId,
  friends,
  visibility,
  ownerId
}) => {
  console.log("selectedShopList from SharedListComments",selectedShopList);
  const endRef = useRef();
  const emojiPickerRef = useRef();
  const list = shoppingListData.find((list) => list.name === selectedShopList);
  const dateCreated = new Date(list?.dateCreated * 1000);
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const formattedDate = dateCreated.toLocaleDateString("en-US", options);
  // const [recentCommentTime, setRecentCommentTime] = useState(null);
  const [commentText, setCommentText] = useState("");
  const [comments, setComments] = useState([]);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const { user } = UserAuth();
  const { openModal,closeModal } = useModalStore();
  const { addNotification } = useNotification();
  const [listMembers, setListMembers] = useState(null);
  const socket = useSocketStore((state) => state.socket);
  const userData = useUserDataStore((state) => state.userData);
  const [productData, setProductData] = useState(null);
  const commentRef = useRef();

  useEffect(() => {
    const fetchMemberDocumentIds = async () => {
      if (!userId || !selectedShopList) return;

      try {
        // Reference to the members collection for the selected shopping list of the user
        const membersRef = collection(
          db,
          "users",
          ownerId,
          "shoppingList",
          selectedShopList,
          "members"
        );

        // Fetch all documents in the members collection
        const membersSnapshot = await getDocs(membersRef);

        if (!membersSnapshot.empty) {
          // Get all the document IDs
          const memberDocumentIds = membersSnapshot.docs.map((doc) => doc.id);
          setListMembers([...memberDocumentIds, userId,ownerId]);
          console.log("Member document IDs:", memberDocumentIds);
          // You can now do something with these IDs (e.g., set them in state, etc.)
        } else {
          console.log("No members found in this shopping list.");
        }
      } catch (error) {
        console.error("Error fetching member document IDs:", error);
      }
    };

    fetchMemberDocumentIds();
  }, [userId, selectedShopList]);


  useEffect(() => {
    if (listId && socket) {
      socket.emit("join list", {
        listId: listId,
        members: listMembers,
      });
    }
  }, [listId, socket, listMembers]);

  useEffect(() => {
    if (socket) {
      socket.on("list chat msg", (msg) => {
        // Add the new message
        setComments((prevComments) => [
          ...prevComments,
          {
            ...msg,
          },
        ]);

        console.log("msg from socket", msg);
      });
    }
  }, [socket]);




  const handleSubmit = async (e) => {
    if(!user){
      openModal("SignInModal");
    }else{
      closeModal()
    }
    e.preventDefault();

    // Check if visibility is 'Friends' and the user has appropriate role
  if (visibility === "Friends") {
    const canPost = friends.some(
      (friend) => friend.id === user.uid && (friend.role === "editor" || friend.role === "commentor")
    );

    if (!canPost) {
      toast.warn("You do not have permission to post a comment.", toastOptions);
      return;
    }
  }
    const text = commentText;
    // Clear the comment input field
    setCommentText("");
    console.log("CLICKED");
    try {
      const encryptedMessage = encryptChat(text);
      // Create a new comment document with specified attributes
      const msgData = {
        content: encryptedMessage,
        timestamp: new Date(),
        senderId: userId,
        listId: listId,
        userName: userData?.userName,
        photo: userData?.photo,
      };
      console.log("msgData from list", msgData);

      // Fetch the comments again to include the newly added comment
      socket.emit("list chat msg", {
        listId,
        msg: msgData,
      });
      // Fetch the comments again to include the newly added comment
      // Create a new comment document with specified attributes
      const commentDocRef = saveListMessageToDb(msgData, listId, listMembers);

      if (productData) {
        // Add if any productRef is used in chat
        const product = await addDoc(
          collection(db, "chats", commentDocRef.id, "product"),
          { ...productData }
        );
        await updateDoc(doc(db, "chats", commentDocRef.id), {
          productDocID: product?.id,
        });
        setProductData(null);
      }
      const commentDocId = commentDocRef.id;
      if (ownerId!==userId) {
        await addNotification({
          userId: ownerId,
          id: user?.uid,
          timestamp: serverTimestamp(),
          type: "listChat",
          by: "user",
          fields: {
            listId: listId,
            shopList:selectedShopList,
            commentId: commentDocId,
          },
        });
      }
      endRef.current.scrollIntoView({ behavior: "smooth" });
      const chatContainer = document.getElementById("chat-container"); // Replace with your chat container's ID
      chatContainer.scrollIntoView({ behavior: "smooth" });
      chatContainer.scrollTop = chatContainer.scrollHeight;
    } catch (error) {
      console.error("Error adding comment: ", error);
      // Handle any errors here, e.g., display an error message to the user
    }
  };
  const fetchComments = async () => {
    if (!listId) {
      setComments([]);
      return;
    }

    try {
      const messagesRef = collection(db, "chats", listId, "messages");
      const q = query(messagesRef, orderBy("timestamp", "asc"));

      // Fetch the comments using getDocs instead of onSnapshot
      const querySnapshot = await getDocs(q);

      const newComments = await Promise.all(
        querySnapshot.docs.map(async (document) => {
          console.log("document", document.data());
          let data = {};
          try {
            const productRef = doc(
              db,
              "chats",
              document.id,
              "product",
              document.data().productDocID
            );
            const productSnap = await getDoc(productRef);
            data = productSnap.data();
          } catch (error) {
            console.error(error);
          }

          const commentData = document.data();
          return {
            id: document.id,
            ...commentData,
            userName: "", // Initialize userName to an empty string
            photo: "",
            productData: data,
          };
        })
      );

      // Fetch user data for each comment asynchronously
      await Promise.all(
        newComments.map(async (comment) => {
          const userDocRef = doc(db, "users", comment.senderId);
          const userSnapshot = await getDoc(userDocRef);

          if (userSnapshot.exists()) {
            comment.userName = userSnapshot.data()?.userName; // Update userName
            comment.photo = userSnapshot.data()?.photo;
          } else {
            // Handle the case where the user document doesn't exist
            console.error(
              "User document not found for userId:",
              comment.senderId
            );
          }
        })
      );

      // Set the comments after fetching user data
      setComments(newComments);
    } catch (error) {
      console.error("Error fetching comments: ", error);
    }
  };
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        emojiPickerRef.current &&
        !emojiPickerRef.current.contains(event.target)
      ) {
        setShowEmojiPicker(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    fetchComments();
    // eslint-disable-next-line
  }, [listId]);
  const toggleEmojiPicker = () => {
    setShowEmojiPicker(!showEmojiPicker);
  };

  const addEmoji = (emojiObject) => {
    // Implement logic to add emoji to comment text
    const emoji = emojiObject.emoji;
    setCommentText(commentText + emoji);
  };

  const handleAddProductToList = async (productID, product) => {
    const userRef = doc(
      db,
      "users",
      userId,
      "shoppingList",
      selectedShopList,
      "products",
      productID
    );
    await setDoc(userRef, {
      size: "",
      timestamp: serverTimestamp(),
      likeCount: 0,
    });
    // Increment the shoppingListCount
    await updateDoc(doc(db, "users", user.uid), {
      shoppingListCount: increment(1),
    });

    let tempListData = shoppingListData;
    tempListData.forEach((list) => {
      if (list.name === selectedShopList) {
        list.products.push(product);
      }
    });
    setShoppingListData(tempListData);
    toast.success(`Product Added to ${selectedShopList} list`, toastOptions);
  };

  const handleCommentInput = async (e) => {
    setCommentText(e.target.value);
    if (e.target.value.includes("/#/ProductDetails/")) { 
      let text = e.target.value;
      // Extract the product code
      text = text.split("/#/ProductDetails/");
      // test case checking for: if no code is written is added after ProductDetails/
      if (text[1] !== "") {
        console.log("FOUNDD", text);
        //If some extra text is used after link then do not fetch again and again for product
        if (text[1].split(" ").length === 1) {
          const productRef = doc(db, "products", text[1].split(" ")[0]);
          const productSnap = await getDoc(productRef);
          if (productSnap.exists()) {
            console.log("FOUNDD", productSnap.data());
            setProductData(productSnap.data());
            if (commentRef && commentRef.current) {
              commentRef.current.scrollTop = commentRef.current.scrollHeight;
            }
          } else setProductData(null);
        }
      }
    } else {
      setProductData(null);
    }
  };
  // const compareTime = (timestamp) => {
  //   if (!recentCommentTime || recentCommentTime !== timestamp) {
  //     setRecentCommentTime(timestamp);
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };
  return (
    <>
      <div id="listCommentsAnchor" className="mt-4 my-2 mx-3 bg-transaprent border border-1 rounded-2 ">
        <h5 className="m-0 px-3 pt-3">{selectedShopList} Chat</h5>
        <small className="px-3 fw-bold text-body">
          Created {formattedDate}
        </small>
        <hr />
        {/* <div className="d-flex justify-content-center">
          <p className=" bg-light rounded-2 my-1 px-3 py-2">
            {"Thursday 24 May 2024"}
          </p>
        </div> */}
        <div className="p-3">
          <div className="clearfix"></div>
        </div>
        {/* Comments */}
        <ul className="comment-wrap list-unstyled">
          <div class="flex-grow-1">
            <div
              class="w-100"
              ref={commentRef}
              style={{
                maxHeight: "15rem",
                overflowY: "scroll",
              }}
            >
              {comments?.map((comment, index) => (
                <li key={index} className="comment-item">
                  {comment.productDocID && (
                    <div
                      className={`d-flex justify-content-${
                        comment?.senderId === user?.uid ? "end" : "start"
                      }`}
                    >
                      <div className="col-3">
                        <ProductCard3
                          product={comment?.productData}
                          isStrip={false}
                          addButtonData={{
                            isAddButton: true,
                            handleAddProductToList,
                            selectedShopList,
                            userId,
                          }}
                        />
                      </div>
                    </div>
                  )}
                  <div
                    className={`w-100 d-flex justify-content-${
                      comment?.senderId === user?.uid ? "end" : "start"
                    }`}
                  >
                    <div
                      class={`px-2 py-1 d-flex flex-column align-items-${
                        comment?.senderId === user?.uid ? "end" : "start"
                      }`}
                      style={{ maxWidth: "50%" }}
                    >
                      <div
                        className={`${
                          comment?.senderId === user?.uid
                            ? "rounded-start-4 bg-primary text-white"
                            : "bg-light text-secondary rounded-end-4"
                        } p-2 ps-3 mb-1 rounded-top-4`}
                        style={{ minWidth: `15rem` }}
                      >
                        {decryptChat(comment?.content)}
                      </div>
                      <div
                        className={`d-flex justify-content-between align-items-center mx-1 ${
                          comment?.senderId === user?.uid
                            ? "flex-row-reverse"
                            : "end"
                        }`}
                        style={{
                          width: "-webkit-fill-available",
                          maxWidth: "100%",
                        }}
                      >
                        <div
                          className={`h6 mb-2 d-flex align-items-center mx-1 ${
                            comment?.senderId === user?.uid ? "d-none" : "end"
                          }`}
                        >
                          <Link
                            to={`/userProfile/${comment?.senderId}`}
                            className="avatar avatar-xs "
                          >
                            <ProfilePicture
                              userData={comment}
                              avatarSize={15}
                              className="avatar-img rounded-circle"
                            />
                          </Link>
                          &nbsp;
                          {/* Non-breaking space */}
                          <Link
                            className="mx-1"
                            to={`/userProfile/${comment?.senderId}`}
                          >
                            {comment?.userName
                              ?.split(" ")
                              .slice(0, 2)
                              .map(
                                (word) =>
                                  word.charAt(0).toUpperCase() + word.slice(1)
                              )
                              .join(" ")}{" "}
                          </Link>
                        </div>
                        <small className="mb-2">
                          {formatTimestampChat(comment?.timestamp)}
                        </small>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </div>
          </div>
        </ul>
        <hr className="m-0" />
        {/* Comment box  */}
        <form className="position-relative m-3" onSubmit={handleSubmit}>
          <div className=" rounded input-group d-flex border justify-content-center align-items-center">
            <div className="input-group-prepend">
              <span
                className="input-group-text border-0 bg-transparent"
                onClick={toggleEmojiPicker}
                style={{ cursor: "pointer" }}
              >
                <i className="bi bi-emoji-smile"></i>
              </span>
            </div>

            <input
              className="form-control border-0"
              type="text"
              placeholder="Type a comment"
              value={commentText}
              onChange={(e) => handleCommentInput(e)}
              onKeyDown={(e) => {
                if (e.key === "Enter" && !e.shiftKey) {
                  e.preventDefault();
                  handleSubmit(e);
                }
              }}
            />
            <button
              className="input-group-text bg-transparent border-0"
              type="submit"
              disabled={commentText.length === 0}
            >
              <i className="bi bi-send-fill"> </i>
            </button>

            <div ref={emojiPickerRef} className="position-absolute bottom-0">
              <Picker
                onEmojiClick={addEmoji}
                open={showEmojiPicker}
                emojiStyle="native"
              />
            </div>
          </div>
        </form>
      </div>
      <div ref={endRef}></div>
    </>
  );
};

export default SharedListComments;
