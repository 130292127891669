import { useContext, React, useEffect } from "react";
import Main from "./Main";
import { Route, Routes, useLocation } from "react-router-dom";
import SignUp from "./Authentication/SignUp";
import SignIn from "./Authentication/SignIn";
import ResetPassword from "./Authentication/ResetPassword";
import ForgotPassword from "./Authentication/ForgotPassword";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthContextProvider, UserAuth, InterestContext } from "./Context";
import ProductDetails from "./ProductDetails";
import Header from "./Header/Header";
import Header3 from "./Header/Header3";
import BrandsPage from "./SideNav/BrandsPage";
import UserSettings from "./Authentication/UserSettings";
import ProfilePage from "./UserProfile/ProfilePage";
import RegionBrands from "./UserProfile/RegionBrands";
import StaticPages from "./StaticPages/StaticPages";
import TermsOfService from "./StaticPages/TermsOfService";
import GenderBdInput from "./Authentication/GenderBdInput";
import ModalManager from "./Modals/ModalManager";
import PrivacyPolicy from "./StaticPages/PrivacyPolicy";
import MoreQuestions from "./Authentication/MoreQuestions";
import VerifyEmail from "./Authentication/VerifyEmail";
import ContactUs from "./ContactUs";
import PhoneAuth from "./PhoneAuth";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { collection, doc, getDoc, getDocs, limit, orderBy, query } from "firebase/firestore";
import { db } from "./FirebaseConfig";
import usefavStore from "./StatesStore/FavStore";
import useSaveStore from "./StatesStore/SaveStore";
import useLikeStore from "./StatesStore/LikeStore";
import ConvertLogostoUrl from "./lib/convertLogostoUrls";
import SignInInput from "./Authentication/SignInInput";
import usePointsStore from "./StatesStore/PointsTable";
import Chat from "./Chat/Chat";
import BrandPostForm from "./Brands/CreateBrandPost";
import BrandHandlerSettings from "./Brands/BrandHandlerSettings";
import TransferLogoUrlsComponent from "./lib/convertLogostoUrls";
const queryClient = new QueryClient();

function App() {
  const { user } = UserAuth(); // Ensure that UserAuth is used after AuthContextProvider
  const { interestedProducts, setInterestedProducts } = useContext(
    InterestContext
  );
  const location = useLocation();
  const addSave = useSaveStore((state) => state.addSave);
  const savedProducts = useSaveStore((state) => state.savedProducts);
  const addLike = useLikeStore((state) => state.addLike);
  const likedProducts = useLikeStore((state) => state.likedProducts);
  const setPointsTable = usePointsStore((state) => state.setPointsTable);

  
  useEffect(() => {
    const fetchSavedData = async () => {
      if (!user) return;
      const savedPostRef = collection(db, "users", user?.uid, "saved");
      const savedPostData = await getDocs(savedPostRef);
      savedPostData.docs.map((post) => {
        addSave(post?.id);
      });
    };
    fetchSavedData();
  }, [user]);
  useEffect(() => {
    const fetchLikesData = async () => {
      if (!user) return;
      const likePostRef = collection(db, "users", user?.uid, "likeList");
      const likePostData = await getDocs(likePostRef);
      likePostData.docs.map((post) => {
        addLike(post?.id);
      });
    };
    fetchLikesData();
  }, [user]);
  useEffect(() => {
    if (!savedProducts) return;
    console.log("Save", savedProducts);
    console.log("REACT_API", process.env.REACT_APP_API);
  }, [savedProducts]);
  useEffect(() => {
    if (!likedProducts) return;
    console.log("Liked", likedProducts);
  }, [likedProducts]);

  useEffect(() => {
    const fetchPointsTable = async () => {
      try {
        // Create a query to get the most recent document based on timestamp
        const q = query(
          collection(db, 'pointsTable'), 
          orderBy('timestamp', 'desc'),  // Order by timestamp in descending order
          limit(1)  // Limit the result to 1 document
        );

        const querySnapshot = await getDocs(q);

        // Fetch the first document
        if (!querySnapshot.empty) {
          const doc = querySnapshot.docs[0];
          setPointsTable({ id: doc.id, ...doc.data() });
        }

      } catch (error) {
        console.error("Error fetching points table:", error);
      }
    };

    fetchPointsTable();
  }, []);
  return (
    <AuthContextProvider>
      <>
        <QueryClientProvider client={queryClient}>
          <ToastContainer />
          <ModalManager />
          {location.pathname === "/SignUp" ||
          location.pathname === "/SignIn" ||
          location.pathname === "/SignInInput" ||
          location.pathname === "/TempSignInInput" ||
          location.pathname === "/Reset" ||
          location.pathname === "/UserInfo" ||
          location.pathname === "/VerifyEmail" ||
          location.pathname === "/PhoneAuth" ||
          location.pathname === "/ForgotPassword" ? (
            <div className="bg-image object-fit-scale bgImg bgImgUrl">
              <Routes>
                <Route path="/SignIn" element={<SignIn />} />
                {/* <Route path="/SignInInput" element={<GenderBdInput />} /> */}
                <Route path="/SignInInput" element={<SignInInput />} />
                <Route path="/SignUp" element={<SignUp />} />
                <Route path="/Reset" element={<ResetPassword />} />
                <Route path="/ForgotPassword" element={<ForgotPassword />} />
                {/* <Route path="/UserInfo" element={<MoreQuestions />} /> */}
                <Route path="/VerifyEmail" element={<VerifyEmail />} />
                <Route path="/PhoneAuth" element={<PhoneAuth />} />
              </Routes>
            </div>
          ) : (
            // : location.pathname === "/TandC" ||
            //   location.pathname === "/PrivacyPolicy" ? (
            //   <Routes>
            //     <Route path="/TandC" element={<TermsOfService />} />
            //     <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
            //   </Routes>
            // )
            <div>
              <header className="navbar-light fixed-top header-static bg-transparent">
                <InterestContext.Provider
                  value={{ interestedProducts, setInterestedProducts }}
                >
                  {/* <Header /> */}
                  <div className="d-lg-block d-none">
                    <Header3 />
                  </div>
                  <div className="d-block d-lg-none">
                    <Header />
                  </div>
                </InterestContext.Provider>
              </header>
              <main id="main">
                <div className="container" id="App">
                  <div className="row g-5">
                    {/* <TransferLogoUrlsComponent /> */}
                    <Routes>
                      {/* Update SignIn, SignUp, and other routes to use UserAuth() */}
                      {/* <Route path="/SignIn" element={<SignIn />} />
                    <Route path="/SignInInput" element={<GenderBdInput />} />
                    <Route path="/SignUp" element={<SignUp />} />
                    <Route path="/TandC" element={<TermsOfService />} />
                    <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
                    <Route path="/Reset" element={<ResetPassword />} />
                    <Route path="/ForgotPassword" element={<ForgotPassword />} /> */}
                      <Route path="/TandC" element={<TermsOfService />} />
                      <Route
                        path="/PrivacyPolicy"
                        element={<PrivacyPolicy />}
                      />
                      <Route path="/ContactUs" element={<ContactUs />} />
                      <Route path="/PostCreate/:id" element={<BrandPostForm  />} />
                      <Route
                        path="/ProductDetails/:id"
                        element={<ProductDetails />}
                      />
                      <Route path="/brands" element={<BrandsPage />} />
                      <Route path="/settings" element={<UserSettings />} />
                      <Route path="/settings/brandHandler" element={<UserSettings tab={"brandHandler"} />} />
                      <Route
                        path="/userProfile/:uid/*"
                        element={<ProfilePage />}
                      />
                      {/* <Route path="/chat1" element={<Chat />} /> */}
                      <Route path="/staticPages/*" element={<StaticPages />} />
                      <Route path="/*" element={<Main />} />
                    </Routes>
                  </div>
                </div>
              </main>
            </div>
          )}
        </QueryClientProvider>
      </>
    </AuthContextProvider>
  );
}
export default App;
