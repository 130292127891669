import CryptoJS from 'crypto-js';

// The secret key should be securely stored and managed in production environments
const SECRET_KEY = '12345678901234567890123456789012'; // Must be 32 characters for AES-256

export function encryptChat(text) {
    const key = CryptoJS.enc.Utf8.parse(SECRET_KEY); // Convert the key from UTF8 to a word array
    const iv = CryptoJS.lib.WordArray.random(128 / 8); // Generate a random IV (16 bytes for AES)
    
    const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(text), key, {
        keySize: 256 / 32,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });

    return iv.toString() + ':' + encrypted.toString(); // Concatenate IV and the encrypted string
}
export function decryptChat(ciphertext) {
    if (!ciphertext || typeof ciphertext !== 'string') {
        console.error("Invalid input for decryption:", ciphertext);
        return ""; // Return empty string or handle error as appropriate
    }

    const ciphertextParts = ciphertext.split(':');
    if (ciphertextParts.length !== 2) {
        console.error("Ciphertext format is invalid:", ciphertext);
        return ""; // Return empty string or handle error as appropriate
    }

    const key = CryptoJS.enc.Utf8.parse(SECRET_KEY);
    const iv = CryptoJS.enc.Hex.parse(ciphertextParts[0]); // Parse the IV from the first part of the ciphertext
    const encryptedText = ciphertextParts[1]; // The actual encrypted message
    
    try {
        const decrypted = CryptoJS.AES.decrypt(encryptedText, key, {
            keySize: 256 / 32,
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
        return decrypted.toString(CryptoJS.enc.Utf8); // Convert decrypted data to a UTF8 string
    } catch (error) {
        console.error("Decryption failed:", error);
        return "";
    }
}
