import React, { useState, useEffect } from "react";
import { Link, Navigate, useLocation, useNavigate, useParams } from "react-router-dom";
import { UserAuth } from "../Context";
import { db } from "../FirebaseConfig";
import BrandPosts from "./BrandPosts";
import ProductCard from "../Products/ProductCard";
import BrandsHomeSideNav from "../SideNav/BrandsPageSideNav";
import {
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  doc,
  getDoc,
  setDoc,
  serverTimestamp,
} from "firebase/firestore";
import SideNavSearch from "../SideNav/SideNavSearch";
import SideNavBrands from "../SideNav/SideNavBrands";
import DiscountCount from "./DiscountCount";
import BrandProducts from "./BrandProducts";
import handlefollowBrand from "../hooks/brand/followBrand";
import handleUnfollowBrand from "../hooks/brand/unfollowBrand";
import { toast } from "react-toastify";
import { toastOptions } from "../lib/toastOptions";
import useModalStore from "../StatesStore/ModalStore";
import GoToTop from "../components/gotoTop";
import { Button, Card, Modal } from "react-bootstrap";
import ProfilePicture from "../UserProfile/ProfilePicture";
import algoliasearch from "algoliasearch";
import UpdateBrandPFP from "./UpdateBrandPFP";
import BrandFeedback from "./BrandFeedback";
import ReviewsList from "./renderBrandFeedbacks";
//algolia object initialization and index mapping
const client = algoliasearch("EMFUSB67CG", "0f7466afc30b24c667a61b43cf879898");
const Productindex = client.initIndex("products"); //name of index in algolia is products

// const useQuery = () => {
//   const { search } = useLocation();
//   return React.useMemo(() => new URLSearchParams(search), [search]);
// };



function BrandHome() {
  let { brandName } = useParams() || "";
  const { user } = UserAuth();
  const navigate = useNavigate();
  const [brandInfo, setBrandInfo] = useState();
  const location = useLocation();
  const [womenCategories, setWomenCategories] = useState(null);
  const [averageRating, setAverageRating] = useState(null);
  const [menCategories, setMenCategories] = useState(null);
  const [showBrandPosts, setShowBrandPosts] = useState("allProducts");
  const collectionRef = collection(db, "brands");
  const [showSearchModal, setShowSearchModal] = useState(false);
  const [handlerIds, setHandlerIds] = useState([]);
  const [FollowButton, setFollowButton] = useState(false);
  const [FollowerCount, setFollowerCount] = useState(0);
  const { openModal } = useModalStore();
  const { closeModal } = useModalStore();
  const [reviewTrigger, setReviewTrigger] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [logoUpdate, setLogoUpdated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reviewCount, setReviewCount] = useState(0);
  const [input, setInput] = useState('');
  const [showReviewModal, setShowReviewModal] = useState(false);
  // const q2 = useQuery();

  const handleReviewModal = () => setShowReviewModal(!showReviewModal);
  // const invitedUserId = q2.get('Invited');
  
// Function to handle adding a brand handler based on invitation
// const handleAddBrandHandler = async (userId) => {
//   const brandHandlersRef = collection(db, "brands", brandName, "brandHandler");

//     try {
//         const snapshot = await getDocs(brandHandlersRef);

//         if (snapshot.empty) {
//             // If no handlers exist, add new handler
//             const newHandlerRef = doc(brandHandlersRef, userId); // Creating a doc reference with userId
//             await setDoc(newHandlerRef, {
//                 "allotted on": serverTimestamp()
//             });
//             const userRef = doc(db, "users", userId);
//             await updateDoc(userRef, {
//               isBrandHandler: true
//             });

//             toast.success('You have been added as Brand handler !', toastOptions);
//             navigate(`/BrandHome/${brandName}`);
//             return true;
//         } else {
//             // Handlers exist, do not add new handler
//             toast.warn('Brand handler already assigned!', toastOptions);
//             return false; // Optionally return false to indicate no addition was made
//         }
//     } catch (error) {
//         console.error("Failed to manage brand handlers:", error);
//         toast.error('Failed to assign brand handler. Please try again.', toastOptions);
//         return false; // Return false in case of an error
//     }
// };


// useEffect(() => {
//   if (invitedUserId && user && user.uid == invitedUserId && brandName) {
//     handleAddBrandHandler(invitedUserId);
//   }
// }, [invitedUserId]);


  const renderDropdownItems = (categories, category) => {
    return Object.keys(categories)
      .sort()
      .map((categoryName) => (
        <li
          className="dropdown-submenu dropstart "
          key={categoryName}
          onClick={() => setShowBrandPosts("allProducts")}
        >
          <button
            className="dropdown-item dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {categoryName}
          </button>
          <ul className="dropdown-menu dropdown-menu-end" data-bs-popper="none">
            {categories[categoryName]
              .filter(
                (subCategory) =>
                  subCategory.name !== "None" &&
                  subCategory.displayName !== "None"
              )
              .map((subCategory, index) => (
                <li key={index}>
                  <Link
                    className="dropdown-item"
                    to={`/BrandHome/${brandName}/${category}/${categoryName}/${subCategory.name}`}
                  >
                    {subCategory.displayName}
                  </Link>
                </li>
              ))}
          </ul>
        </li>
      ));
  };


  const fetchData = async (brandname) => {
    try {
      console.log("brandname:", brandname);
      if (!brandname) {
        console.error("brandname is undefined or null");
        return;
      }

      if (!user?.uid) {
        console.error("User ID is undefined or null");
        return;
      }

      const checkBrandSnapshot = doc(
        db,
        "users",
        user?.uid,
        "following",
        brandname
      );
      const checkBrandData = await getDoc(checkBrandSnapshot);

      if (checkBrandData.exists()) setFollowButton(true);
      else setFollowButton(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchCount = async () => {
    const brandFollowerRef = collection(collectionRef, brandName, "followers");
    const brandFollowerData = await getDocs(brandFollowerRef);
    setFollowerCount(brandFollowerData.size);
  };

  const fetchHandler = async () => {
    const brandHanderdRef = collection(db, "brands", brandName, "brandHandler");
    try {
      const brandHandlerSnapshot = await getDocs(brandHanderdRef);
      const handlerIds = brandHandlerSnapshot.docs.map(doc => doc.id);  // Mapping through to get all document IDs
      console.log('Handler Document IDs in brands home: ', handlerIds);
      // return handlerIds;  // You can return these IDs if you need to use them elsewhere
      setHandlerIds(handlerIds);
    } catch (error) {
      console.error("Error fetching handler document IDs: ", error);
    }
  };
  // useEffect(() => {
  const getBrandDetails = async () => {
    const q = query(collectionRef, where("name", "==", brandName));
    const snapshot = await getDocs(q);
    const brandDoc = snapshot.docs[0];
    setBrandInfo(snapshot.docs[0].data());

    // Increment views in the database
    const brandDocRef = doc(db, "brands", brandDoc.id);
    await updateDoc(brandDocRef, {
      views: brandDoc.data().views + 1,
    });
  };
  //   getBrandDetails();
  //   fetchData(brandName);
  //   fetchCount();
  // }, [brandName]);

  useEffect(() => {
    setWomenCategories(null);
    setMenCategories(null);

    const getCategories = async () => {
      // if(user){
      //   const batch = writeBatch(db);
      //   const userRef = doc(db, 'users', user.uid);
      //   const userLogsUpdate = {};
      //   const brandname  = brandName;
      //   const timestamp = new Date(); // Get server timestamp
      //   const likedProduct = { timestamp, brandname };
      //   userLogsUpdate[`userlogs.visitedBrand`] = arrayUnion(likedProduct);
      //   batch.update(userRef, userLogsUpdate);
      //   await batch.commit();
      // }

      // console.log('Visiting Brand PAgeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee');
      const data = collection(db, "universalCategories");
      const querySnapshot = await getDocs(data);
      const categoriesData = querySnapshot.docs.map(
        (doc) => doc.data().subCategories
      );

      // Check if "Women" is present in brandInfo categories
      if (
        brandInfo?.category.includes("Women") &&
        brandInfo?.category.includes("Men")
      ) {
        // If both Men and Women categories are present, render separate dropdowns
        setWomenCategories(categoriesData[1]);
        setMenCategories(categoriesData[0]);
      } else if (brandInfo?.category.includes("Women")) {
        // If only Women category is present, set categories to Women

        setWomenCategories(categoriesData[1]);
      } else if (brandInfo?.category.includes("Men")) {
        // If only Men category is present, set categories to Men

        setMenCategories(categoriesData[0]);
      }
    };

    getCategories();
  }, [brandInfo]);

  // useEffect(() => {
  //   fetchData(brandName);
  // }, [FollowButton]);
  const handleClick = () => {
    if (!user) {
      // Check if user is not logged in before opening modal
      openModal("SignInModal", "", ""); // Open modal here
    } else {
      const DATA = {
        displayName: brandInfo.displayName,
        FollowButton: setFollowButton,
        SetFollowerCount: setFollowerCount,
        UID: user.uid,
        brandName: brandName,
      };
      openModal("ConfirmUnfollow", "brand", DATA); // Open modal here
    }
  };

  const handleSearch = async (event, brandName) => {
    // console.log('input in handler search : ',input);
    event.preventDefault();
    const itemsPerPage = 12;

    if (input.trim().length >= 3) {
      const searchQuery = `${input.trim()} ${brandName}`;
      console.log("Search input: ", searchQuery);
      try {
        const offset = 0;
        const data = await Productindex.search(searchQuery, {
          offset,
          length: itemsPerPage,
        });
        const uniqueProducts = new Set([...data.hits]);
        const updatedProducts = Array.from(uniqueProducts);
        console.log(
          `${brandName} products in search on Brand Page: `,
          updatedProducts
        );
        setSearchResults(updatedProducts);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    } else {
      console.log("Please enter at least 3 characters to search.");
    }
    if( searchResults.length > 0){
      setShowBrandPosts("searchResult");
    }
    if( searchResults.length == 0){
      toast.warn(`No products found with search query:  ${input.trim()} `,toastOptions);
    }
  };

  useEffect(() => {
    const loadAllData = async () => {
      try {
        setLoading(true); // Set loading at the start of data fetching
        await getBrandDetails();
        await fetchHandler();
        await fetchData(brandName);
        await fetchCount();
        await fetchReviews();
        setLoading(false); // Set loading to false after all data is fetched
      } catch (error) {
        console.error("Error loading data:", error);
      } finally {
        setLoading(false); // Ensure loading is set to false in case of an error
      }
    };

    if (brandName) {
      loadAllData();
    }
  }, [brandName, logoUpdate]);

  if (loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  const fetchReviews = async () => {
    try {
      const reviewsRef = collection(db, `brands/${brandName}/reviews`);
      const querySnapshot = await getDocs(reviewsRef);
      const reviewsData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // setReviews(reviewsData);
      setReviewCount(reviewsData.length);
      if (reviewsData.length > 0) {
        const totalRating = reviewsData.reduce(
          (acc, review) => acc + review.rating,
          0
        );
        const avgRating = (totalRating / reviewsData.length).toFixed(1);
        // console.log(avgRating);
        console.log('avgRating for brand: ',avgRating, reviewCount);
        setAverageRating(avgRating);
      }
    } catch (error) {
      console.error("Error fetching reviews:", error);
      // setError("Failed to load reviews.");
    }
  };

 

  const toggleReviewTrigger = () => {
    setReviewTrigger(prev => !prev);  // Toggle to force re-render
  };

  return (
    <>
      <GoToTop />
      <Card className="col-11 mx-auto px-0" style={{ width: "96%" }}>
        <Card.Body className="px-4">
          <div className="d-flex d-sm-none align-items-end py-3 px-0 ">
            <div style={{
        width: '75%',
        height: '75%',
        borderRadius: '10px'
      }}>
            {handlerIds?.includes(user?.uid) ? (
    <UpdateBrandPFP
      BrandId={brandName} // Ensure that brandInfo has an 'id' property
      brandData={brandInfo}
      className={"brandImage avatar avatar-xxxl"}// Set the size based on your UI design
      onLogoUpdate={setLogoUpdated} // Uncomment and implement if needed
    />
  ) : (
    
    <img 
      src={brandInfo?.logoUrl} 
      alt="Brand Logo"
      className=" avatar avatar-xxxl rounded-2"
    />
  )}
     </div>
            <div className="details d-flex flex-column ms-4 gap-2 w-100">
              <div className="brandName h5 m-0 p-0">
                {brandInfo?.displayName}
              </div>
              <div className="brandDetailsAndFollowButton d-flex flex-column ">
              <div className="d-flex flex-wrap justify-content-start" style={{fontSize: "12px"}}>
    {/* Product Count */}
    <div className="me-3">
      <DiscountCount brandName={brandName} /> Products
    </div>
    {/* Follower Count */}
    <div className="me-3">
      {FollowerCount} Followers
    </div>
 

  {/* Reviews Link */}
  <div className="me-3" onClick={(e) => {
    e.preventDefault(); // Prevent default link behavior
    handleReviewModal();
  }} style={{ cursor: 'pointer'}}>
    {reviewCount} Reviews
  </div>

  <div className="rating d-flex align-items-center" >
    {averageRating ? (
      <>
      <div className="d-flex">
        {[...Array(5)].map((_, starIndex) => (
          <span key={starIndex}>
            {starIndex < Math.round(averageRating) ? (
              <i className="bi bi-star-fill text-warning"></i>
            ) : (
              <i className="bi bi-star text-warning"></i>
            )}
          </span>
        ))}
      </div>
       <span className="mx-2">Rating</span>
       </>
    ) : (
      <p
        className="mb-0"
        onClick={handleReviewModal}
        style={{ cursor: "pointer"}}
      >
        Be the first to rate
      </p>
    )}
  </div>
  </div>
                <div className="followButton mb-2 my-4">
                  <Button
                    className="p-0"
                    variant="primary"
                    size="sm"
                    style={{ width: "6rem", height: "1.3rem" }}
                    onClick={async () => {
                      if (!user) handleClick();
                      else {
                        if (FollowButton) {
                          // await handleUnfollowBrand(user?.uid, brandName);
                          // setFollowButton(false);
                          // fetchCount();
                          handleClick();
                        } else {
                          setFollowButton(true);
                          toast.success(
                            "Brand followed successfully",
                            toastOptions
                          );
                          setFollowerCount((prev) => prev + 1);
                          await handlefollowBrand(user?.uid, brandName);
                        }
                      }
                    }}
                  >
                    <div>{FollowButton ? "Unfollow" : "Follow"}</div>
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="d-none d-sm-flex align-items-end py-3 px-0">

          <div className="brandImage avatar avatar-xxl">
  {handlerIds?.includes(user?.uid) ? (
    <UpdateBrandPFP
      BrandId={brandName} // Ensure that brandInfo has an 'id' property
      brandData={brandInfo}
      className={"brandImage avatar avatar-xxl"}// Set the size based on your UI design
      onLogoUpdate={setLogoUpdated} // Uncomment and implement if needed
    />
  ) : (
    <img 
      src={brandInfo?.logoUrl} // Fallback to a default logo if none is found
      alt="Brand Logo"
      className="img-fluid rounded-2"
    />
  )}
</div>
            <div className="details d-flex flex-column ms-4 gap-2 w-100">
              <div className="brandName h3 m-0 p-0">
                {brandInfo?.displayName}
              </div>
              <div className="d-flex justify-content-between align-items-center ">
              <div className="brandDetails h6 fw-semibold gap-2 gap-sm-4" style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", alignItems: "center" }}>
  {/* Flex container to ensure horizontal layout on desktop and wrap on mobile */}
  <div className="d-flex flex-wrap justify-content-start">
    {/* Product Count */}
    <div className="me-3">
      <DiscountCount brandName={brandName} /> Products
    </div>
    {/* Follower Count */}
    <div className="me-3">
      {FollowerCount} Followers
    </div>
 

  {/* Reviews Link */}
  <div className="me-3" onClick={(e) => {
    e.preventDefault(); // Prevent default link behavior
    handleReviewModal();
  }} style={{ cursor: 'pointer'}}>
    {reviewCount} Reviews
  </div>

  <div className="rating d-flex align-items-center" >
    {averageRating ? (
      <>
      <div className="d-flex">
        {[...Array(5)].map((_, starIndex) => (
          <span key={starIndex}>
            {starIndex < Math.round(averageRating) ? (
              <i className="bi bi-star-fill text-warning"></i>
            ) : (
              <i className="bi bi-star text-warning"></i>
            )}
          </span>
        ))}
      </div>
       <span className="mx-2">Rating</span>
       </>
    ) : (
      <p
        className="mb-0"
        onClick={handleReviewModal}
        style={{ cursor: "pointer" }}
      >
        Be the first to rate
      </p>
    )}
  </div>
  </div>
</div>



<Modal show={showReviewModal} onHide={handleReviewModal} centered>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <BrandFeedback
            userId={user?.uid} // Pass the logged-in user ID
            brandName={brandName}
            brandDisplayName = {brandInfo?.displayName}
            // Assuming brandName is the productID or you can adjust as per your schema
            onReviewSubmit={() => {
              toggleReviewTrigger(); // Close modal on successful submission
            }}
          />
        <ReviewsList brandId= {brandName} trigger={reviewTrigger}/>

        </Modal.Body>
      </Modal>



      <Modal show={showSearchModal} onHide={() => setShowSearchModal(false)} centered>
      <Modal.Header closeButton>
        <Modal.Title>Search Products</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={(event) => handleSearch(event, brandName)} className="d-flex">
          <input
            type="text"
            className="form-control me-2"
            placeholder="Search products..."
            value={input}
            onChange={(e) => setInput(e.target.value)}
            required
          />
          <Button variant="primary" type="submit">Search</Button>
        </form>
      </Modal.Body>
    </Modal>


                <div className="followButton mb-2">
                  <Link
                    className="btn btn-primary btn-sm"
                    style={{ width: "7rem" }}
                    onClick={async () => {
                      if (!user) handleClick();
                      else {
                        if (FollowButton) {
                          // await handleUnfollowBrand(user?.uid, brandName);
                          // setFollowButton(false);
                          // fetchCount();
                          handleClick();
                        } else {
                          setFollowButton(true);
                          toast.success(
                            "Brand followed successfully",
                            toastOptions
                          );
                          setFollowerCount((prev) => prev + 1);
                          await handlefollowBrand(user?.uid, brandName);
                        }
                      }
                    }}
                  >
                    {FollowButton ? "Unfollow" : "Follow"}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </Card.Body>
        <Card.Footer className="py-3" style={{padding:"0px"}}>
  <ul className="navbar nav d-flex align-items-center ">
    <li
      className="nav-item h6 fw-semibold m-0"
      style={{ fontSize: "0.8rem" }}
    >
      <Link
        className={`nav-link ${showBrandPosts === "newsfeed" ? "active" : ""}`}
        onClick={() => {
          setShowBrandPosts("newsfeed");
          setInput(''); // Reset input
          setSearchResults([]); // Reset search results
        }}
      >
        Newsfeed
      </Link>
    </li>
{/* 
<li class="nav-item h6 fw-semibold m-0 d-none d-md-block" style={{ fontSize: "0.8rem" }}>
  <Link className={`nav-link ${showBrandPosts === "newsfeed" ? "active" : ""}`} onClick={() => setShowBrandPosts("newsfeed")}>
    Newsfeed
  </Link>
</li>
<li class="nav-item h6 fw-semibold m-0 d-block d-md-none" style={{ fontSize: "0.8rem" }}> 
  <Link className={`nav-link ${showBrandPosts === "newsfeed" ? "active" : ""}`} onClick={() => setShowBrandPosts("newsfeed")}>
    Newsfeed
  </Link>
</li> */}

    
    <li
      className="nav-item h6 fw-semibold m-0"
      style={{ fontSize: "0.8rem" }}
    >
      <Link
        className={`nav-link ${showBrandPosts === "allProducts" ? "active" : ""}`}
        onClick={() => {
          setShowBrandPosts("allProducts");
          setInput(''); // Reset input
          setSearchResults([]); // Reset search results
        }}
      >
        All Products
      </Link>
    </li>
    <li
      className="nav-item h6 fw-semibold m-0"
      style={{ fontSize: "0.8rem" }}
    >
      <Link
        className={`nav-link ${showBrandPosts === "newArrivals" ? "active" : ""}`}
        onClick={() => {
          setShowBrandPosts("newArrivals");
          setInput(''); // Reset input
          setSearchResults([]); // Reset search results
        }}
      >
        New Arrivals
      </Link>
    </li>

 <form onSubmit={(event) => handleSearch(event, brandName)} className="ms-auto d-none d-md-block">
      <div className="input-group2">
        <input
          type="text"
          className="form-control ps-5 bg-light"
          placeholder="Search products..."
          value={input}
          onChange={(e) => setInput(e.target.value)}
          required
        />
         <button
                  className="btn bg-transparent px-2 py-0 position-absolute top-50 translate-middle-y "
                  type="submit"
                >
                  <i className="bi bi-search fs-5"> </i>
                </button>
      </div>
    </form>
    <button
      className="btn bg-transparent ms-auto d-block d-md-none"
      onClick={() => setShowSearchModal(true)}
    >
      <i className="bi bi-search"></i>
    </button>


  </ul>
</Card.Footer>
      </Card>
      {showBrandPosts !== "newsfeed" && <SideNavBrands brandName={brandName} />}
      <div className="col-md-8 col-lg-6 vstack gap-4 mt-3 ps-3">
        {showBrandPosts === "allProducts" ? (
          <BrandProducts brandName={brandName} />
        ) : showBrandPosts === "newArrivals" ? (
          <BrandPosts />
        ) : showBrandPosts === "searchResult" ? (
          <>
            <div className="card-body">
          <div className="row g-4">
          {searchResults.length > 0 && (
            searchResults.map((product, index) => (
              <ProductCard key={index} product={product} />
            ))
          )}
          </div>
        </div>
            </>
          ) : (
          // Newsfeed component will show
          <></>
        )}
      </div>
    </>
  );
}

export default BrandHome;
