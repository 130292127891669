import { collection, deleteDoc, doc, getDoc, increment, onSnapshot, serverTimestamp, setDoc, updateDoc } from 'firebase/firestore';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { db } from '../FirebaseConfig';
import { toast } from 'react-toastify';
import { toastOptions } from '../lib/toastOptions';
import { v4 as uuid4 } from 'uuid';

const useFavStore = create((set, get) => ({
  favorites: [],
  
  setFavorites: (newfavorites) => set((state) => ( {favorites: [...state.favorites,newfavorites] })),

  deleteFavorite: (productId) => set((state) => ({
    favorites: Array.isArray(state.favorites) ? state.favorites.filter((id) => id !== productId) : []
  })),

  addFavorite: async (product, user, setFavoritesCount) => {
    if (!user || !product) return;

    const userRef = doc(db, "users", user.uid);
    const favoritesRef = doc(collection(userRef, "shoppingList"), "Favorites");
    const productRef = doc(collection(favoritesRef, "products"), product.id);

    try {
      // Check if the 'Favorites' document exists
      const favoritesDoc = await getDoc(favoritesRef);
      if (!favoritesDoc.exists()) {
        // If 'Favorites' doesn't exist, create it with additional fields
        await setDoc(favoritesRef, {
          dateCreated: serverTimestamp(),
          listId: uuid4(),
          visibility: "Private",
        });
      }
      if (typeof setFavoritesCount === 'function') {
        setFavoritesCount((prevCount) => prevCount + 1);
      }

      set((state) => ({
        favorites: Array.isArray(state.favorites) 
          ? [...new Set([product.id, ...state.favorites])] 
          : [product.id]
      }));
      // Add the product to the 'Favorites' products subcollection
      await setDoc(productRef, {
        timestamp: serverTimestamp(),
        likeCount: 0,
      });

      await updateDoc(userRef, { shoppingListCount: increment(1) });
      await updateDoc(doc(db, "products", product.id), { favourites: increment(1) });

     

      

      toast.success(`${product.name} added to favorites`, toastOptions);
    } catch (error) {
      console.error("Error adding favorite:", error);
      toast.error("Failed to add to favorites. Please try again.");
    }
  },

  removeFavorite: async (product, user, setFavoritesCount) => {
    if (!user || !product) return;

    const userRef = doc(db, "users", user.uid);
    const favoritesRef = doc(collection(userRef, "shoppingList"), "Favorites");
    const productRef = doc(collection(favoritesRef, "products"), product.id);

    try {
      await deleteDoc(productRef);
      if (typeof setFavoritesCount === 'function') {
        setFavoritesCount((prevCount) => Math.max(prevCount - 1, 0));
      }

      set((state) => ({
        favorites: Array.isArray(state.favorites) 
          ? state.favorites.filter((id) => id !== product.id)
          : []
      }));

      await updateDoc(userRef, { shoppingListCount: increment(-1) });
      await updateDoc(doc(db, "products", product.id), { favourites: increment(-1) });

     

     

      toast.success(`${product.name} removed from favorites`, toastOptions);
    } catch (error) {
      console.error("Error removing favorite:", error);
      toast.error("Failed to remove from favorites. Please try again.");
    }
  },

  toggleFavorite: async (product, user, setFavouritesCount) => {
    if (!user || !product) return;

    const state = get();
    const favorites = Array.isArray(state.favorites) ? state.favorites : [];
    
    if (favorites.includes(product.id)) {
      await state.removeFavorite(product, user, setFavouritesCount);
    } else {
      await state.addFavorite(product, user, setFavouritesCount);
    }
  },

  checkFavoriteStatus: async (product, user) => {
    if (!user || !product) return false;

    try {
      const userRef = doc(db, "users", user.uid);
      const favoritesRef = doc(collection(userRef, "shoppingList"), "Favorites");
      const productRef = doc(collection(favoritesRef, "products"), product.id);
      const productDoc = await getDoc(productRef);

      if (productDoc.exists()) {
        set((state) => ({
          favorites: Array.isArray(state.favorites)
            ? [...new Set([...state.favorites, product.id])]
            : [product.id]
        }));
        return true;
      }
      return false;
    } catch (error) {
      console.error("Error checking favorite status:", error);
      return false;
    }
  },
}));


export default useFavStore;