import React, { useState, useEffect } from 'react';
import { db } from "../FirebaseConfig";
import { collection, query, where, getDocs, doc, getDoc } from "firebase/firestore";

const CheckStatus = ({ myUid, yourEmail }) => {
    const [status, setStatus] = useState('Checking...');

    useEffect(() => {
        const fetchUserByEmail = async () => {
            if (!myUid || !yourEmail) {
                setStatus('Unavailable');
                return;
            }
            try {
                // Query users collection for a document where the email field matches yourEmail
                const usersRef = collection(db, 'users');
                const q = query(usersRef, where("email", "==", yourEmail));
                const querySnapshot = await getDocs(q);
                if (!querySnapshot.empty) {
                    const userId = querySnapshot.docs[0].id;  // Assuming the email field is unique and there's only one match
                    // console.log('User ID found:', userId);

                    // Now fetching relationship status using the found userId
                    // Check 'connections'
                    const connectionsRef = doc(db, 'users', myUid, 'connections', userId);
                    const connectionsSnap = await getDoc(connectionsRef);
                    if (connectionsSnap.exists()) {
                        setStatus("Friend");
                        return;
                    }

                    // Check 'followers'
                    const followersRef = doc(db, 'users', myUid, 'followers', userId);
                    const followersSnap = await getDoc(followersRef);
                    if (followersSnap.exists()) {
                        setStatus("Follower");
                        return;
                    }

                    // Check 'following'
                    const followingRef = doc(db, 'users', myUid, 'following', userId);
                    const followingSnap = await getDoc(followingRef);
                    if (followingSnap.exists()) {
                        setStatus("Following");
                        return;
                    }
                    setStatus("Non-connection");
                } else {
                    setStatus("Non-connection");
                }
            } catch (error) {
                console.error('Error fetching user by email:', error);
                setStatus('Error fetching status');
            }
        };

        fetchUserByEmail();
    }, [myUid, yourEmail]);

    return (
        <div className="status" style={{ fontSize: '10px' }}>
            {status}
        </div>
    );
};

export default CheckStatus;
