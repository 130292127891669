import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ShareMenu2 from "../../components/ShareMenu2";
import AddToList from "../../components/AddToList";
import AddOrRemoveLike from "../../Products/AddOrRemoveLike";
import { Card, Spinner } from "react-bootstrap";
import "./style.css";
import { UserAuth } from "../../Context";
import AddOrRemoveFavourites from "../../Products/AddOrRemoveFavourites";
import BrandDisplay from "../../NewsFeed/BrandDisplay";
import formatPrice from "../../lib/formatPrice";
import { getBrandDetails } from "../../lib/getBrandDetails";
import useFetch from "../../hooks/useFetch";

const TestProductCard2 = ({ product, isStrip, handleClick }) => {
  const [likeCount, setLikeCount] = useState(product?.likes);
  const [brandData, setBrandData] = useState(null);
  const [loading, setLoading] = useState(true);
  // const activeBrands = useActiveBrandsStore((state) => state.activeBrands);
  const { user } = UserAuth();
  const { data: activeBrands } = useFetch(
    `${process.env.REACT_APP_API}/api/activeBrands`
  );
  const fetchBrandData = async () => {
    try {
      // Fetch the brand's display name and logo using the getDisplayName function
      const result = await getBrandDetails(product?.supplier, activeBrands);
      setBrandData(result);
    } catch (error) {
      console.error("Error getting brand data:", error);
    }
  };

  useEffect(() => {
    if (!product || !activeBrands) return;
    fetchBrandData();
  }, [product, activeBrands]);

  const handleImageLoad = () => {
    setLoading(false);
  };

  return (
    <div className="m-1 m-sm-1">
      <Card onClick={handleClick}>
        <div style={{ position: "relative", height: "auto" }}>
          {loading && (
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "100%",
                backgroundColor: "#eef0f2", // background for the placeholder
              }}
            >
              {/* Placeholder: Can be an image, a spinner, or any other component */}
              <Spinner
                animation="border"
                style={{ color: "grey", width: "20px", height: "20px" }}
              />
            </div>
          )}

          <Card.Img
            src={product.imageUrl}
            style={{
              height: `auto`,
              objectFit: "cover",
              opacity: loading ? 0 : 1, // Hide the image until it's fully loaded
              transition: "opacity 0.5s ease-in-out",
              cursor: "pointer",
            }}
            onLoad={handleImageLoad}
            loading="lazy"
          />
        </div>

        {/* Price Tag */}
        <div
          className="position-absolute rounded-pill top-0 start-0 m-2"
          style={{
            fontSize: "10px",
            backgroundColor: "rgba(0, 0, 0, 0.6)",
          }}
        >
          {product?.newPrice && (
            <p className="m-0 text-white px-2 py-1">
              PKR{" "}
              {product?.newPrice
                ? formatPrice(product.newPrice)
                : formatPrice(product.oldPrice)}
            </p>
          )}
        </div>

        {/* Strip */}
        {(isStrip === undefined || isStrip === true) && (
          <div
            className="d-inline-flex flex-column align-items-center justify-content-between rounded-pill py-2 text-light position-absolute bottom-0 end-0 m-2"
            style={{
              backgroundColor: "rgba(0, 0, 0, 0.6)",
            }}
          >
            <Link className="text-center text-light">
              <AddOrRemoveLike
                user={user}
                product={product}
                setLikeCount={setLikeCount}
                componentName={"strip2"}
              />
              <div style={{ fontSize: "10px", marginTop: "-4px" }}>
                {likeCount}
              </div>
            </Link>
            <Link className="text-center d-flex flex-column nav-link" to="">
              <i className="bi bi-eye fs-6"></i>
              <div
                style={{
                  fontSize: "10px",
                  marginTop: "-5px",
                  marginBottom: "-2px",
                }}
              >
                {product?.views ?? 0}
              </div>
            </Link>
            <Link className="text-center text-light">
              <AddOrRemoveFavourites product={product} user={user} />
              <div style={{ fontSize: "10px", marginTop: "-7px" }}>
                {product?.favourites}
              </div>
            </Link>
            <ShareMenu2 product={product} />
            <AddToList product={product} componentName={"strip2"} />
          </div>
        )}
      </Card>

      <div className="pe-1 pb-1">
        <div className="d-flex align-items-center">
          {/* Logo */}
          <div
            className="avatar me-2"
            style={{ width: "35px", height: "35px" }}
          >
            <Link to={"/BrandHome/" + product?.supplier}>
              <img
                className="avatar-img rounded"
                src={`assets/images/brands/${brandData?.logo}`}
                alt=""
              />
            </Link>
          </div>
          <div
            className="d-flex flex-column justify-content-start"
            style={{ width: "78%" }}
          >
            <div className="mb-0">
              <BrandDisplay
                brandData={brandData}
                post={product}
                type={"ProductCard"}
              />
            </div>
            <div
              className="fw-bold h6 position-relative"
              style={{
                marginTop: "2px",
                fontSize: "12px",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              {product?.name}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestProductCard2;
