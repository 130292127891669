import React from "react";
import Slider from "react-slick";
// import ShoppingListCard from "./ShoppingListCard";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ShopProductsCard from "./ShopProductsCard";
import { CaretLeftFill, CaretRightFill } from "react-bootstrap-icons";
const PrevArrow = ({ style, onClick }) => {
  return (
    <div
      className="slick-prev"
      style={{
        ...style,
        fontSize: "24px",
        color: "grey",
      }}
      onClick={onClick}
    >
      <span
        className=""
        style={{ filter: "brightness(0.5)", position: "absolute", left: "50%" }}
      >
        <CaretLeftFill className="fs-3" />
      </span>
    </div>
  );
};
const NextArrow = ({ style, onClick }) => {
  return (
    <div
      className="slick-next"
      style={{
        ...style,
        fontSize: "24px",
        color: "grey",
      }}
      onClick={onClick}
    >
      <span
        style={{
          filter: "brightness(0.5)",
          position: "absolute",
          right: "20%",
        }}
      >
        <CaretRightFill className="fs-3" />
      </span>
    </div>
  );
};

const ShopProducts = ({ shopPost }) => {
  console.log("shopPost in product list", shopPost);
  const productCount = shopPost ? shopPost.products.length : 0;
  const settings = {
    speed: 500,
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    swipe: productCount < 5 ? false : true,
    nextArrow: productCount < 5 ? <></> : <NextArrow />,
    prevArrow: productCount < 5 ? <></> : <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          swipe: productCount < 4 ? false : true,
          nextArrow: productCount < 4 ? <></> : <NextArrow />,
          prevArrow: productCount < 4 ? <></> : <PrevArrow />,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          swipe: productCount < 2 ? false : true,
          nextArrow: productCount < 2 ? <></> : <NextArrow />,
          prevArrow: productCount < 2 ? <></> : <PrevArrow />,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          swipe: productCount < 2 ? false : true,
          nextArrow: productCount < 2 ? <></> : <NextArrow />,
          prevArrow: productCount < 2 ? <></> : <PrevArrow />,
        },
      },
    ],
  };

  return (
    <div className="card px-1 bg-transparent border border-0 mb-1">
      <div className="card-body p-0 bg-transparent">
        <Slider {...settings} className="m-0">
          {shopPost?.products.map((product, index) => (
            <div key={index}>
              <ShopProductsCard product={product} />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default ShopProducts;
