import {
  collection,
  doc,
  getDoc,
  getDocs,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { db } from "../FirebaseConfig";
import { UserAuth } from "../Context";
import { toast } from "react-toastify";
import { toastOptions } from "../lib/toastOptions";
function BrandSelection() {
  const { user } = UserAuth();
  const [gender, setGender] = useState("male");
  const [brands, setBrands] = useState([]); //This will have all the primary categories
  const [allBrands, setAllBrands] = useState([]); // Updated to use state for allBrands  //this will contain top 7 brands
  // BRANDS
  //top brands handlers
  const handleBrandClick = (brand) => {
    // If brand is already added, remove it; otherwise, add it
    if (brands.includes(brand.name)) {
      setBrands((prevBrands) =>
        prevBrands.filter((item) => item !== brand.name)
      );
    } else {
      setBrands((prevBrands) => [...prevBrands, brand.name]);
    }
  };
  //fetch brands function
  const fetchBrands = async () => {
    try {
      const brandsCol = collection(db, "brands");
      const brandSnapshot = await getDocs(brandsCol);
      let brandsArray = [];
      brandSnapshot.forEach((doc) => {
        let brand = doc.data();
        brandsArray.push({
          value: brand.displayName,
          label: brand.displayName,
          like: brand.like,
          name: brand.name,
          category: brand.category[0],
        });
      });
      // Sort brands based on 'like' values
      brandsArray.sort((a, b) => b.like - a.like);
      setAllBrands(brandsArray);
      //   console.log("Filtered brands", filteredBrands);
    } catch (error) {
      console.error("Error fetching brand display names:", error);
    }
  };
  useEffect(() => {
    if (gender === "") return;
    const fetchData = async () => {
      //function call
      await fetchBrands();
      //function call
      //   await fetchCategories();
    };
    fetchData();
    // eslint-disable-next-line
  }, [gender]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const userDocRef = doc(db, "users", user.uid);
      await updateDoc(userDocRef, {
        favBrands: brands,
      });
      for (const brandName of brands) {
        // Get the brand document reference
        const brandDocRef = doc(db, "brands", brandName);

        // Add the user to the 'followers' subcollection in the brand document
        const followersDocRef = doc(brandDocRef, "followers", user.uid);
        await setDoc(followersDocRef, {
          dateCreated: serverTimestamp(),
        });

        // Get the user's document reference
        const userDocRef = doc(db, "users", user.uid);

        // Add a document in the user's subcollection with the brand name
        const userBrandDocRef = doc(userDocRef, "following", brandName);
        await setDoc(userBrandDocRef, {
          timestamp: serverTimestamp(),
          type: "brand",
        });
      }

      console.log("User profile updated successfully");
      toast.success("Profile updated successfully", toastOptions);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        if (user && user.uid) {
          const userDocRef = doc(db, "users", user.uid);
          const userDocSnapshot = await getDoc(userDocRef);

          if (userDocSnapshot.exists()) {
            const userDataFromDb = userDocSnapshot.data();
            console.log(userDataFromDb.birthday);
            setBrands(userDataFromDb.favBrands);
            setGender(userDataFromDb.gender);
          }
        }
      } catch (error) {
        console.error("Error fetching user data from database", error);
      }
    };

    fetchUserData();
  }, [user]);
  return (
    <div>
      <Card>
        <Card.Header className="border-0 pb-0">
          <h4>Brands</h4>
        </Card.Header>
        <Card.Body className="pt-2">
          {/* Brands */}
          <form onSubmit={(e) => handleSubmit(e)}>
            <div>
              <ul className="list-inline d-flex flex-wrap gap-3 mb-3">
                {allBrands.map((brand, index) => (
                  <li key={index} onClick={() => handleBrandClick(brand)}>
                    <div
                      className={`btn btn-outline-light brand-button btn-sm ${
                        brands?.includes(brand.name) ? "bg-light btn-light" : ""
                      }`}
                    >
                      {brand.label}{" "}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            <div className="col-12 text-end">
              <button type="submit" className="btn btn-sm btn-primary mb-0">
                Save changes
              </button>
            </div>
          </form>
        </Card.Body>
      </Card>
    </div>
  );
}

export default BrandSelection;
