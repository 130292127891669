import React, { useState, useEffect } from "react";
import WebFont from "webfontloader";
import TermsAndConditions from "./TermsAndConditions";
import useOobCodeState from "../StatesStore/OobCode";
import useApiKeyState from "../StatesStore/ApiKey";
import { useNavigate } from "react-router-dom";
import { auth, db } from "../FirebaseConfig";
import { toast } from "react-toastify";
import { toastOptions } from "../lib/toastOptions";
import {
  applyActionCode,
  checkActionCode,
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
import { UserAuth } from "../Context";
import {
  doc,
  getDoc,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { WelcomeUser } from "../EmailSystem/sendEmails";
import { Accordion } from "react-bootstrap";
import useUserStore from "../StatesStore/UserStore";
function VerifyEmail() {
  const navigate = useNavigate();
  const oobCode = useOobCodeState((state) => state.oobCode);
  const apiKey = useApiKeyState((state) => state.apikey);
  const [message, setMessage] = useState("");
  const { user } = UserAuth();
  const { setUser, createId } = useUserStore(); //for storing user

  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  useEffect(() => {
    console.log(user);
    WebFont.load({
      google: {
        families: ["Nunito Sans", "sans-serif"],
      },
    });

    if (oobCode && apiKey) {
      checkActionCode(auth, oobCode)
        .then((info) => {
          // Email verification code is valid
          // Apply the action code to complete the email verification
          applyActionCode(auth, oobCode)
            .then(async () => {
              try {
                console.log("VERIFYEMAILUSER", user);
                const userRef = doc(db, "users", user?.uid);
                await updateDoc(userRef, { isVerified: true });
                user.emailVerified = true;
                toast.success("Email verified successfully", toastOptions);
                setMessage("Email verified successfully!");
                await WelcomeUser(user?.displayName, user.email);
              } catch (error) {
                console.error(error);
              }
              // Email verified successfully
              console.log("Email verified successfully!");
              // You can now sign in the user or redirect them to the login page
              if (user) navigate("/SignInInput");
              else navigate("/SignIn");
            })
            .catch((error) => {
              // Handle Errors here.
              const errorCode = error.code;
              const errorMessage = error.message;
              setMessage(
                `Error verifying email in applying: ${errorMessage} ${errorCode}`
              );
              // console.error("Error applying action code:", errorCode, errorMessage);
            });
        })
        .catch((error) => {
          // Invalid action code
          const errorMessage = error.message;
          setMessage(`Error verifying email in checking: ${errorMessage}`);
          // console.error("Invalid action code:", errorCode, errorMessage);
        });
    } else {
      setMessage(`Hi ${user?.displayName ||
        user?.providerData[0].displayName ||
        ""}, Please verify your email address by
              clicking the link sent to ${user?.email ||
                user?.providerData[0]?.email}`);
    }
    // eslint-disable-next-line
  }, [oobCode, apiKey]);
  useEffect(() => {
    if (user.emailVerified) navigate("/");
  }, [user]);

  const resendEmailVerification = async () => {
    await sendEmailVerification(user);
    toast.success("Check your inbox for email verification link", toastOptions);
  };
  const changeEmailAddress = async () => {
    console.log("email", email);
    console.log(user?.uid);
    console.log("user?.email", user?.providerData[0]?.email);
    if (email === (user?.email || user?.providerData[0]?.email)) {
      toast.warn("New email cannot be same as old email", toastOptions);
      return;
    }
    const usersToBeDeletedRef = doc(db, "usersToBeDeleted", user?.uid);
    await setDoc(usersToBeDeletedRef, {
      email: user?.email,
      timestamp: serverTimestamp(),
    });
    const userRef = doc(db, "users", user.uid);
    const userSnap = await getDoc(userRef);
    let oldUserData = userSnap.data();
    oldUserData.email = email;
    console.log(oldUserData);
    try {
      const authUser = await createUserWithEmailAndPassword(auth, email, pass);
      const userDocRef = doc(db, "users", authUser.user.uid);
      await setDoc(userDocRef, { ...oldUserData });
      await setUser(oldUserData);
      createId(authUser.user.uid);
      await sendEmailVerification(authUser.user)
        .then(() => {
          // Email verification sent!
          toast.success(
            "Check your inbox for email verification link",
            toastOptions
          );
          setMessage(`Hi ${authUser.user?.displayName ||
            authUser.user?.providerData[0].displayName ||
            ""}, Please verify your email address by
                clicking the link sent to ${email ||
                  authUser.user?.providerData[0]?.email}`);

          // navigate("/VerifyEmail");
        })
        .catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          toast.error(
            "Error sending email verification:",
            errorCode,
            errorMessage,
            toastOptions
          );
        });
    } catch (error) {
      if (error.code === "auth/email-already-in-use") {
        toast.warn("Email already exists", toastOptions);
        return;
      }
    }
    setEmail("");
    setPass("");
    setConfirmPass("");
    try {
      // Show success message
      toast.success("Email Updated Successfully", toastOptions);
    } catch (error) {
      if (error.code === "auth/wrong-password") {
        toast.error("Incorrect Password. Please try again.");
      } else if (error.code === "auth/too-many-requests") {
        toast.error("Too many attempts. Please try again later.");
      } else {
        toast.error("Error Updating Email: " + error.message);
      }
    }
  };
  const handleSubmitForm = (e) => {
    e.preventDefault();
    if (pass !== confirmPass) {
      toast.warn("Password and Confirm Password do not match", toastOptions);
      return;
    } else if (pass.length < 6) {
      toast.warn("Password must be at least 6 characters long", toastOptions);
      return;
    } else {
      changeEmailAddress();
    }
  };
  return (
    <div className="container">
      <div className="row justify-content-center align-items-center vh-100 py-2 ">
        <div className="col-sm-10 col-md-8 col-lg-7 col-xl-6 col-xxl-5">
          <div className="card card-body text-center rounded-3 p-4 ">
            <div className="text-center">
              <div className="text-body text-center fs-icon " to="/">
                <div
                  style={{
                    fontFamily: "Nunito Sans",
                  }}
                >
                  <i>lookflock&nbsp;</i>
                </div>
              </div>
            </div>
            <p className="mt-3 fs-4 text-body">Verify your email address</p>
            <hr className="mt-0" />
            <p className="fs-5">{message}</p>
            <div
              className="btn btn-primary w-50 m-auto mb-2"
              onClick={resendEmailVerification}
            >
              Resend Email Verification
            </div>
            <Accordion flush>
              <style jsx>
                {`
                  .accordion-button::after {
                    display: none !important; /* Hides the default arrow */
                  }
                `}
              </style>

              <Accordion.Item className="bg-transparent" eventKey={0}>
                <Accordion.Header className="d-inline-block">
                  <div className="btn btn-primary ">Change Email Address</div>
                </Accordion.Header>
                <Accordion.Body>
                  <form
                    className="d-flex flex-column gap-2 justify-content-center "
                    onSubmit={(e) => handleSubmitForm(e)}
                  >
                    <div>
                      <input
                        style={{ height: "2.5rem" }}
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        className="w-100 rounded-2 p-2 border border-2"
                        type="email"
                        placeholder="New Email Address"
                        required
                      />
                    </div>
                    <div className="d-flex">
                      <input
                        style={{ height: "2.5rem" }}
                        value={pass}
                        type={showPassword ? "text" : "password"}
                        onChange={(e) => {
                          setPass(e.target.value);
                        }}
                        className="w-100 me-1 rounded-2 p-2 border border-2"
                        placeholder="New Password"
                        required
                      />
                      <div
                        className="input-group-text p-0"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        <i
                          className={`fakepasswordicon fa-solid fa-eye${
                            showPassword ? "-slash" : ""
                          } cursor-pointer p-2 w-40px`}
                        ></i>
                      </div>
                    </div>
                    <div className="d-flex">
                      <input
                        style={{ height: "2.5rem" }}
                        value={confirmPass}
                        type={showPassword ? "text" : "password"}
                        onChange={(e) => {
                          setConfirmPass(e.target.value);
                        }}
                        className="w-100 me-1 rounded-2 p-2 border border-2"
                        placeholder="Confirm Password"
                        required
                      />
                      <div
                        className="input-group-text p-0"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        <i
                          className={`fakepasswordicon fa-solid fa-eye${
                            showPassword ? "-slash" : ""
                          } cursor-pointer p-2 w-40px`}
                        ></i>
                      </div>
                    </div>
                    <button
                      className="btn btn-primary w-100 m-auto"
                      type="submit"
                    >
                      Submit
                    </button>
                  </form>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <TermsAndConditions />
          </div>
        </div>
      </div>
    </div>
  );
}

export default VerifyEmail;
