export default async function evaluateText(text) {
    console.log('Evaluating Text : ', text);

    try {
        const response = await fetch(`https://lookflock-api.ew.r.appspot.com/evaluateText?text="${text}"`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Host': 'lookflock-api.ew.r.appspot.com'
            }
        });

        console.log('Response: ', response);

        if (response.ok) {
            const data = await response.json();
            console.log('The data in eval: ', data);
            
            if (data && data.evaluation) {
                return data.evaluation === "Positive" ? 0 : 1;
            }
        } else {
            throw new Error('Failed to fetch evaluation');
        }
    } catch (error) {
        console.error('Error:', error);
        return -1; // or some error code
    }
}
