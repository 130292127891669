import React, { useEffect } from "react";
import useModalStore from "../StatesStore/ModalStore";
import {
  CheckSquare,
  CheckSquareFill,
  DashSquare,
  EnvelopeCheck,
  Facebook,
  Link45deg,
  PersonPlusFill,
  PlusSquare,
  Whatsapp,
} from "react-bootstrap-icons";
import { message } from "antd";
// import { Modal, Button, Form, InputGroup } from "react-bootstrap";
import { UserAuth } from "../Context";
import { useState } from "react";
import { db } from "../FirebaseConfig";
import { saveInviteToDb } from "../lib/saveInviteToDb";
import InviteOnList from "../ShoppingList/InviteOnList";
import { toast } from "react-toastify";
import { toastOptions } from "../lib/toastOptions";
import {
  doc,
  updateDoc,
  getDoc,
  setDoc,
  serverTimestamp,
  collection,
  getDocs,
  deleteDoc,
} from "firebase/firestore";
import { Link, useNavigate } from "react-router-dom";
import {
  Modal,
  Button,
  FormSelect,
  InputGroup,
  Form,
  FormControl,
} from "react-bootstrap";
import { IoIosCloseCircle } from "react-icons/io";
import ProfilePicture from "../UserProfile/ProfilePicture";
import { getBaseURL } from "../lib/getBaseURL";
import algoliasearch from "algoliasearch";
import CheckStatus from "../lib/checkUserStatus";
import { checkIfEmailExists } from "../lib/checkIfEmailExists";
const client = algoliasearch("EMFUSB67CG", "0f7466afc30b24c667a61b43cf879898");
const indexUsers = client.initIndex("users"); // Name of index in Algolia is users

const { ShoppingListMail } = require("../EmailSystem/sendEmails");
function InvitePeople({
  selectedShopList,
  listId,
  myName,
  listName,
  listLink,
}) {
  const { data, closeModal } = useModalStore();
  const [emailUsers, setEmailUsers] = useState([{ name: "", email: "" }]);
  const [phoneUsers, setPhoneUsers] = useState([{ cc: "", phone: "" }]);
  const { user } = UserAuth();
  const [Error, setError] = useState("");
  const regex = /^[a-zA-Z\s]*$/;
  const myEmail = user?.providerData[0].email;
  const text = "Invite Friends";
  const [inviteOption, setInviteOption] = useState("users");
  const isFirstEmailUserEmpty =
    emailUsers[0]?.name === "" &&
    emailUsers[0]?.email === "" &&
    emailUsers?.length === 1;
  const isFirstPhoneUserEmpty =
    phoneUsers[0].cc === "" &&
    phoneUsers[0].phone === "" &&
    phoneUsers.length === 1;
  const modified = listLink.replace(
    "http://localhost:3000",
    "https://lookflock.com"
  );

  const [productImgUrls, setProductImgUrls] = useState([]);
  const [profile, setProfile] = useState("");
  const [showModal, setShowModal] = useState(false);
  //InviteOnLookflock
  const [selectedRole, setSelectedRole] = useState("editor"); // Initialize with a default value
  const [friendId, setFriendId] = useState("");
  const [friendsData, setFriendsData] = useState([]);
  const [userOptions, setUserOptions] = useState([]);
  const [unsharedUsers, setUnsharedUsers] = useState([]);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [friendCount, setFriendCount] = useState(0);
  const baseURL = getBaseURL();
  // let friendCount = 0;
  const [DATA, setDATA] = useState({
    friendId: "",
    displayName: "",
  });
  const rolesOptions = [
    { name: "editor", label: "Editor" },
    { name: "viewer", label: "Viewer" },
    { name: "commentor", label: "Commentor" },
  ];
  const [input, setInput] = useState("");
  const navigate = useNavigate();
  const googleLogoPath =
    process.env.PUBLIC_URL + "/assets/images/logo/google-logo.svg";
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  // DummyContacts
  const [contacts, setContacts] = useState([
    {
      photo: "",
      userName: "Asad M.Furqan",
      email: "muhammadasad98980@gmail.com",
      phoneNumber: "+923001234567",
      isSelected: false,
    },
    {
      photo: "",
      userName: "Osama Siddique",
      email: "osama.siddiqui2017@gmail.com",
      phoneNumber: "+923001234567",
      isSelected: false,
    },
    {
      photo: "",
      userName: "Abdul Sami",
      email: "abdulsami786125@gmail.com",
      phoneNumber: "+923001234567",
      isSelected: false,
    },
    {
      photo: "",
      userName: "Fahad Ausaf",
      email: "fahad.ausaf@gmail.com",
      phoneNumber: "+923001234567",
      isSelected: false,
    },
  ]);
  const handleButtonClick = () => {
    if (selectedShopList.products.length === 0) {
      toast.warning("Kindly add some products before sharing!", toastOptions);
    } else {
      setShowModal(true);
    }
  };
  const checkEmails = async (emailUsers) => {
    const emailSet = new Set();
    emailSet.add(myEmail);
    console.log("emailSet", emailUsers);
    for (const person of emailUsers) {
      if (person.email === myEmail) {
        setError("Cannot email yourself");
        return false; // Email is same as user's email
      }
      if (emailSet.has(person.email)) {
        setError("Emails should be unique");
        return false; // Duplicate found
      }
      const emailExists = await checkIfEmailExists(person.email);
      if (emailExists.exists) {
        const handleLinkClick = () => {
          closeModal(); // Close the modal when the link is clicked
        };

        const userLink = (
          <Link
            to={`/userProfile/${emailExists.userId}`}
            onClick={handleLinkClick}
          >
            {emailExists.firstName}
          </Link>
        );

        // Set the error state with the clickable link
        setError(
          <>
            {person.email} is already on Lookflock. Add {userLink}.
          </>
        );

        return false;
      }

      emailSet.add(person.email);
    }
    return true;
  };
  const onEmailFinish = (values) => {
    values.forEach(async (userData) => {
      const { name, email } = userData;
      // console.log(user, data);
      // console.log(listLink);
      ShoppingListMail(
        myName,
        name,
        email,
        listName,
        listLink,
        productImgUrls,
        profile
      )
        .then(() => {
          message.success({
            content: `Email sent successfully to ${email}`,
            key: "success",
          });
        })
        .catch((error) => {
          message.error(`Error sending email to ${email}`);
          console.error("Error sending email:", error);
        });
      closeModal();
      await saveInviteToDb(
        {
          name,
          email,
          type: "email",
        },
        user
      );
    });
  };
  const handleAddEmailUser = () => {
    setEmailUsers([...emailUsers, { name: "", email: "" }]);
  };
  const handleRemoveEmailUser = (index) => {
    const newEmailUsers = [...emailUsers];
    if (newEmailUsers.length > 1) {
      newEmailUsers.splice(index, 1);
      setEmailUsers(newEmailUsers);
    } else if (newEmailUsers.length === 1) {
      setEmailUsers([{ name: "", email: "" }]);
    }
  };
  const handleEmailChange = (index, field, value) => {
    const newUsers = [...emailUsers];
    newUsers[index][field] = value;
    setEmailUsers(newUsers);
  };
  const fetchShoppingListProducts = async () => {
    console.log("listLink", listLink);
    console.log("listId", listId);
    const elements = listLink.split("/");
    console.log("elements", elements);
    // console.log(listLink.split("#")[0]);
    // console.log("elements[5]", elements[5]);
    // console.log(elements[6]);
    const userRef = doc(db, "users", elements[6]);
    const userSnap = await getDoc(userRef);
    const profilePhoto = userSnap?.data()?.photo;
    setProfile(profilePhoto);
    const shopListRef = collection(db, "users", elements[6], "shoppingList");
    const shopList = await getDocs(shopListRef);
    const list = shopList.docs.filter(
      (doc) => doc?.data()?.listId === elements[5]
    );
    console.log("list123", list[0]?.id);
    const productRef = collection(shopListRef, list[0]?.id, "products");
    const productSnap = await getDocs(productRef);
    const products = productSnap.docs.map((doc) => doc?.id);
    if (products) {
      const ImgUrls = await Promise.all(
        products.map(async (product) => {
          const productRef = doc(db, "products", product);
          const productSnap = await getDoc(productRef);
          return { id: productSnap?.id, image: productSnap?.data().imageUrl };
        })
      );
      setProductImgUrls(ImgUrls);
      console.log("ImgUrls", ImgUrls);
    }
  };
  const onFinishPhone = (values) => {
    console.log("onFinishPhone values", values);
    // Logic to handle phone number invitations
    values.forEach(async (userData) => {
      const { name, cc, phone } = userData;
      console.log(`Invite to phone number: ${phone} for ${name}`);
      // Add the phone invite sending logic here
      await saveInviteToDb(
        {
          phone: `${cc}${phone}`,
          type: "phone",
        },
        user
      );
    });
    message.success("Invitations sent successfully!");
    closeModal();
  };
  const handleAddPhoneUser = () => {
    setPhoneUsers([...phoneUsers, { cc: "", phone: "" }]);
  };

  const handleRemovePhoneUser = (index) => {
    const newPhoneUsers = [...phoneUsers];
    if (newPhoneUsers.length > 1) {
      newPhoneUsers.splice(index, 1);
      setPhoneUsers(newPhoneUsers);
    } else if (newPhoneUsers.length === 1) {
      setPhoneUsers([{ cc: "", phone: "" }]);
    }
  };

  const handlePhoneChange = (index, field, value) => {
    const newPhoneUsers = [...phoneUsers];
    newPhoneUsers[index][field] = value;
    setEmailUsers(newPhoneUsers);
  };
  const handleCopyLink = () => {
    const fullLink = listLink.replace(
      "http://localhost:3000",
      "https://lookflock.com"
    );
    navigator.clipboard.writeText(fullLink); // Copy link to clipboard
    message.success({
      content: `Link copied`,
      key: "success",
    });
  };

  const handleFacebookShare = () => {
    const shareQuote = encodeURIComponent(text);
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=https://lookflock.com/#/ProductDetails/${text}&quote=${shareQuote}`
    );
  };

  const handleWhatsappShare = () => {
    window.open(
      `https://api.whatsapp.com/send?text=${encodeURIComponent(
        `${text}: ${listLink}`
      )}`
    );
  };
  const handleRevokeUser = async (index, Id) => {
    setShowModal(false);
    const docRef = doc(
      db,
      "users",
      user?.uid,
      "shoppingList",
      selectedShopList?.name,
      "members",
      Id
    );
    const invitedListRef = doc(db, "users", Id, "invitedList", listId);
    const docSnap = await getDoc(docRef);
    try {
      if (docSnap.exists) {
        // Remove the friend from the array
        await deleteDoc(docRef);
        await deleteDoc(invitedListRef);
        const newfriendsData = friendsData.filter((friend) => friend.id !== Id);
        setFriendsData(newfriendsData);
        console.log("friendsData", newfriendsData);
        toast.success("Access revoked successfully!", toastOptions);
      }
    } catch (error) {
      console.error(error);
      toast.error("Error removing user!", toastOptions);
    }
  };
  const handleRoleUpdate = async (index, Id, newRole) => {
    // console.log(Id, newRole);
    // Update the friend's role in the database
    setShowModal(false);
    const docRef = doc(
      db,
      "users",
      user?.uid,
      "shoppingList",
      selectedShopList?.name,
      "members",
      Id
    );
    const invitedListRef = doc(db, "users", Id, "invitedList", listId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists) {
      const newfriendsData = friendsData;
      newfriendsData[index].role = newRole;
      setFriendsData(newfriendsData);
      // If the friend exists, update the role
      await updateDoc(docRef, {
        role: newRole,
        timestamp: serverTimestamp(),
      });
      await updateDoc(invitedListRef, {
        role: newRole,
        timestamp: serverTimestamp(),
      });
      // fetchFriends();
      toast.success(`Role updated successfully!`, toastOptions);
      console.log(`Role for friend ${Id} updated successfully!`);
    } else {
      console.log(`No friend found with ID ${Id}`);
    }
  };
  const handleSearchBar = async (event) => {
    event.preventDefault();
    const itemsPerPage = 12;

    if (input.trim().length >= 3) {
      const searchQuery = `${input.trim()}`;
      console.log("Search input: ", searchQuery);
      try {
        const offset = 0;
        const data = await indexUsers.search(searchQuery, {
          offset,
          length: itemsPerPage,
        });
        const uniqueProducts = new Set([...data.hits]);
        const updatedProducts = Array.from(uniqueProducts);
        console.log(
          `Users in search on shopping list invite modal: `,
          updatedProducts
        );
        // setSearchResults(updatedProducts);
        setFriendsData(updatedProducts);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    } else {
      console.log("Please enter at least 3 characters to search.");
    }
  };

  useEffect(() => {
    const fetchUsers = async () => {
      if (user) {
        try {
          // Reference to the connections subcollection of the logged-in user
          const connectionsRef = collection(
            db,
            "users",
            user?.uid,
            "connections"
          );
          const connectionsSnapshot = await getDocs(connectionsRef);

          const activeFriendIds = [];

          // Loop through the connections and gather active connections
          connectionsSnapshot.forEach((doc) => {
            const connectionData = doc.data();
            if (connectionData.type === "active") {
              activeFriendIds.push(doc.id); // Assuming doc.id is the friend's UID
            }
          });

          setFriendCount(activeFriendIds.length);

          // Fetch the user data for each active friend
          const userPromises = activeFriendIds.map((userId) =>
            getDoc(doc(db, "users", userId))
          );

          const userDocs = await Promise.all(userPromises);

          // Map the user data to a format suitable for the component
          const userOption = userDocs.map((userDoc) => ({
            id: userDoc.id,
            label: userDoc.data()?.userName,
          }));

          console.log("userOption", userOption);
          setUserOptions(userOption);
        } catch (error) {
          console.error("Error fetching users:", error.message);
        }
      }
    };

    fetchUsers();
  }, [user]);
  useEffect(() => {
    if (userOptions.length > 0) {
      const friendIds = friendsData.map((friend) => friend.id);
      const remainingUsers = userOptions.filter(
        (user) => !friendIds.includes(user.id)
      );
      console.log("users remainingUsers", remainingUsers);
      setUnsharedUsers(remainingUsers);
    }
  }, [friendsData, userOptions]);

  useEffect(() => {
    if (!listId) return;
    fetchShoppingListProducts();
  }, [data, listId]);

  const handleCheckboxChange = (index) => {
    // Create a new array to avoid mutating state directly
    const updatedContacts = [...contacts];
    // Toggle the checked value for the specific user
    updatedContacts[index].isSelected = !updatedContacts[index].isSelected;
    // Update the state with the new user list
    setContacts(updatedContacts);
  };
  const handleSubmitUsingGoogleContacts = async () => {
    let values = [];
    if (inviteOption === "email") {
      contacts.map((contact) => {
        if (contact.isSelected) {
          values.push({ name: contact.userName, email: contact.email });
        }
      });
      setEmailUsers(values);
      if (await checkEmails(values)) {
        onEmailFinish(values);
      }
    } else if (inviteOption === "phone") {
      // contacts.map((contact) => {
      //   if (contact.isSelected) {
      //     values.push({ cc: contact.userName, phone: contact.phoneNumber });
      //   }
      // });
      // setPhoneUsers(values);
      // if (await checkEmails(values)) {
      //   onEmailFinish(values);
      // }
    }
  };
  return (
    <>
      <li className="nav-item" onClick={() => handleButtonClick()}>
        <Link className="nav-link icon-md btn btn-light p-0">
          <PersonPlusFill className="fs-5" />
          {/* <i className="bi bi-people-fill"></i> */}
        </Link>
        <span className="mx-2 d-sm-none h6 fw-light">Invite People</span>
      </li>
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <span className="h4 fw-semibold">Share </span>
            <span className="text-primary">{selectedShopList.name} List</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-0 pb-0">
          {/* Invite via email */}
          <ul className="nav nav-bottom-line px-3">
            <li className="nav-item">
              <Link
                className={`nav-link ${
                  inviteOption === "users" ? "active" : ""
                }`}
                onClick={() => {
                  setInviteOption("users");
                  setIsContactModalOpen(false);
                }}
              >
                <span>Users</span>
              </Link>
            </li>

            <li className="nav-item">
              <Link
                className={`nav-link ${
                  inviteOption === "email" ? "active" : ""
                }`}
                onClick={() => {
                  setInviteOption("email");
                  setIsContactModalOpen(false);
                }}
              >
                <span className="d-none d-sm-block">Using Email</span>
                <span className="d-block d-sm-none">Email</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link ${
                  inviteOption === "link" ? "active" : ""
                }`}
                onClick={() => {
                  setInviteOption("link");
                  setIsContactModalOpen(false);
                }}
              >
                <span>Copy link</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link ${
                  inviteOption === "phone" ? "active" : ""
                }`}
                onClick={() => {
                  setInviteOption("phone");
                  setIsContactModalOpen(false);
                }}
              >
                <span className="d-none d-sm-block">Using Phone</span>
                <span className="d-block d-sm-none">Phone</span>
              </Link>
            </li>
          </ul>
          <div className="mt-4 px-3">
            {inviteOption === "email" ? (
              <div>
                <Form
                  onSubmit={async (e) => {
                    e.preventDefault();
                    if (await checkEmails(emailUsers)) {
                      onEmailFinish(emailUsers);
                    }
                  }}
                >
                  {emailUsers.map((user, index) => (
                    <InputGroup
                      key={index}
                      className="mb-1 d-flex justify-content-center align-items-center"
                    >
                      <Form.Control
                        className="me-1 rounded h-50"
                        placeholder="Name"
                        value={user.name}
                        onChange={(e) => {
                          if (regex.test(e.target.value)) {
                            handleEmailChange(index, "name", e.target.value);
                            setError("");
                          } else {
                            setError(
                              "Name cannot contain numbers or special characters"
                            );
                          }
                        }}
                        required
                      />
                      <Form.Control
                        className="ms-1 rounded h-50"
                        type="email"
                        placeholder="Email"
                        value={user.email}
                        onChange={(e) => {
                          handleEmailChange(index, "email", e.target.value);
                        }}
                        required
                      />
                      <button
                        className="btn border-0 mx-2 px-0 py-1"
                        onClick={() => handleRemoveEmailUser(index)}
                        disabled={isFirstEmailUserEmpty}
                      >
                        <DashSquare className="fs-4" />
                      </button>
                      <button
                        className="btn border-0 mx-2 px-0 py-1"
                        onClick={handleAddEmailUser}
                      >
                        {/* <PlusCircle type="submit" /> */}
                        <PlusSquare type="submit" className="fs-4 " />
                      </button>
                    </InputGroup>
                  ))}
                  <span className="ms-2 text-danger">{Error}</span>

                  <div className="d-flex justify-content-end">
                    <Button
                      className="rounded d-flex align-items-center"
                      type="submit"
                      variant="primary-soft"
                      size="sm"
                    >
                      <EnvelopeCheck className="fs-6 me-2" />
                      <span>Invite</span>
                    </Button>
                  </div>
                </Form>
              </div>
            ) : inviteOption === "phone" ? (
              <div>
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    onFinishPhone(phoneUsers);
                  }}
                >
                  {phoneUsers.map((user, index) => (
                    <InputGroup
                      key={index}
                      className="mb-3 flex justify-content-center align-items-center"
                    >
                      <Form.Control
                        type="number"
                        value={user.cc}
                        className="me-1 rounded h-50"
                        style={{ width: "0.5rem" }}
                        name="cc"
                        placeholder="+92"
                        onChange={(e) =>
                          handlePhoneChange(index, "cc", e.target.value)
                        }
                        required
                      />
                      <Form.Control
                        type="number"
                        className="mx-1 rounded h-50 w-50"
                        name="phone"
                        placeholder="Phone"
                        value={user.phone}
                        onChange={(e) =>
                          handlePhoneChange(index, "phone", e.target.value)
                        }
                      />
                      <button
                        className="btn border-0 mx-2 px-0 py-1"
                        onClick={() => handleRemovePhoneUser(index)}
                        disabled={isFirstPhoneUserEmpty}
                      >
                        <DashSquare className="fs-4 " />
                      </button>
                      <button
                        className="btn border-0 mx-2 px-0 py-1"
                        onClick={handleAddPhoneUser}
                      >
                        <PlusSquare className="fs-4 " />
                      </button>
                    </InputGroup>
                  ))}
                  <div className="d-flex justify-content-end">
                    <Button
                      className="rounded d-flex align-items-center"
                      type="submit"
                      variant="primary-soft"
                      size="sm"
                    >
                      <EnvelopeCheck className="fs-6 me-2" />
                      <span>Invite</span>
                    </Button>
                  </div>
                </Form>
              </div>
            ) : inviteOption === "link" ? (
              <div>
                <InputGroup className="mb-3">
                  <Form.Control
                    className="rounded me-1 text-mute"
                    value={modified}
                    readOnly
                  />
                  <Button
                    className="rounded mx-1 "
                    variant="primary-soft"
                    size="sm"
                    onClick={handleCopyLink}
                  >
                    <Link45deg className="fs-6 me-1" />
                    <span>Copy Link</span>
                  </Button>
                </InputGroup>
                <p
                  className="mb-0"
                  style={{
                    textAlign: "center",
                    color: "gray",
                  }}
                >
                  Anyone with this link can join Lookflock
                </p>
              </div>
            ) : (
              <div>
                <form onSubmit={(event) => handleSearchBar(event)}>
                  <div
                    className="input-group"
                    style={{ paddingLeft: "15px", paddingRight: "15px" }}
                  >
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search for people..."
                      value={input}
                      onChange={(e) => setInput(e.target.value)}
                      required
                    />
                    <button className="btn btn-primary" type="submit">
                      Search
                    </button>
                  </div>

                  <hr />
                  {friendsData?.length >= 0 && (
                    <>
                      <div className="ps-3">
                        <h6 className="h6 fw-semibold">Shared with: </h6>
                      </div>
                      <div className="d-flex flex-column mt-3 justify-content-center align-items-center pe-3 pb-4 pt-0">
                        {friendsData?.map((friend, index) => {
                          return (
                            <div
                              key={friend.id}
                              className="d-flex justify-content-between align-items-center w-100 my-1"
                            >
                              <div className="w-50 ms-3 d-flex align-items-center">
                                <div className="avatar  ">
                                  <img
                                    className="avatar-img rounded-2"
                                    src={friend.objectUser.photo}
                                  />
                                </div>
                                <div className="ms-2 ">
                                  <div className="h6 fw-semibold m-0">
                                    {friend.displayName}
                                  </div>
                                  <CheckStatus
                                    myUid={user?.uid}
                                    yourEmail={friend?.email}
                                  />
                                </div>
                              </div>
                              <div className="d-flex justify-content-end align-items-center">
                                <FormSelect
                                  style={{ height: "2rem" }}
                                  className="py-1 p-1 border-0 bg-transparent"
                                  onChange={async (event) => {
                                    await handleRoleUpdate(
                                      index,
                                      friend.id,
                                      event.target.value
                                    );
                                  }}
                                  value={friend.role}
                                >
                                  {rolesOptions.map((option) => (
                                    <option
                                      key={option.name}
                                      value={option.name}
                                    >
                                      {option.label}
                                    </option>
                                  ))}
                                </FormSelect>
                                <IoIosCloseCircle
                                  className="fs-4 m-0 "
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setDATA({
                                      friendId: friend?.id,
                                      displayName: friend?.userName,
                                    });
                                    console.log("data", data);
                                    setShowRemoveModal(true);
                                  }}
                                />
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </>
                  )}
                </form>
                <div className="px-3 d-flex justified-content-center align-items-center">
                  <hr className="w-50" />
                  <div className="mx-2">OR</div>
                  <hr className="w-50" />
                </div>
                <div className="px-3 mb-4">
                  <div className="h6 fw-semibold">Share link:</div>
                  <InputGroup className="mb-3">
                    <Form.Control
                      className="rounded me-1 text-mute"
                      value={`${baseURL}/#/shoppingList/${listId}/${user?.uid}`}
                      readOnly
                    />
                    <Button
                      className="rounded mx-1 "
                      variant="primary-soft"
                      size="sm"
                      onClick={handleCopyLink}
                    >
                      <Link45deg className="fs-6 me-1" />
                      <span>Copy Link</span>
                    </Button>
                  </InputGroup>
                </div>
              </div>
            )}
          </div>
        </Modal.Body>
        {(inviteOption === "email" || inviteOption === "phone") && (
          <div>
            <div className="d-flex justified-content-center align-items-center">
              <hr className="w-50 m-0" />
              <div className="mx-2">OR</div>
              <hr className="w-50 m-0" />
            </div>
            {!isContactModalOpen ? (
              <div className="mb-3 mt-2 d-flex justify-content-center">
                <button
                  className="btn mx-4 border border-primary"
                  onClick={() => setIsContactModalOpen(true)}
                >
                  <img
                    src={googleLogoPath}
                    className="mx-1"
                    alt="Google"
                    style={{
                      width: "25px",
                      height: "25px",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  />
                  <span>Invite Using Google Contacts</span>
                </button>
              </div>
            ) : (
              <div className="mb-3 mt-2 px-3">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="googleName">
                    <img
                      src={googleLogoPath}
                      className="mx-1"
                      alt="Google"
                      style={{
                        width: "25px",
                        height: "25px",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                      }}
                    />
                    <span>Google Contacts</span>
                  </div>
                  <div className="searchBar">
                    <div className="position-relative">
                      <FormControl
                        className="ps-5"
                        type="search"
                        name="input"
                        placeholder="Search..."
                        aria-label="Search"
                        value={searchInput}
                        onChange={(e) => setSearchInput(e.target.value)}
                      />
                      <Button
                        variant="mode"
                        type="submit"
                        className="bg-transparent pe-0 ps-3 py-0 position-absolute top-50 start-0 translate-middle-y"
                      >
                        <i className="bi bi-search fs-5"></i>
                      </Button>
                    </div>
                  </div>
                </div>
                <div
                  className="my-2"
                  style={{ maxHeight: "14.5rem", overflowY: "scroll" }}
                >
                  {contacts.map((contact, index) => {
                    if (
                      contact.userName
                        .toLowerCase()
                        .includes(searchInput.toLowerCase())
                    )
                      return (
                        <div className="d-flex my-3 justify-content-between align-items-center me-3">
                          <div className="d-flex">
                            <div className="avatar">
                              <ProfilePicture
                                userData={contact}
                                className={"rounded-2"}
                              />
                            </div>
                            <div className="mx-2 d-flex flex-column justify-content-center">
                              <h6 className="fw-semibold mb-0">
                                {contact.userName}
                              </h6>
                              <h6 className="fw-light mb-0">
                                {inviteOption === "email"
                                  ? contact.email
                                  : contact.phoneNumber}
                              </h6>
                            </div>
                          </div>
                          <div className="checkBox">
                            <button
                              class="btn p-0"
                              for="btncheck1"
                              onClick={() => handleCheckboxChange(index)}
                            >
                              {contact.isSelected ? (
                                <CheckSquareFill />
                              ) : (
                                <CheckSquare />
                              )}
                            </button>
                          </div>
                        </div>
                      );
                  })}
                </div>
                <div className="d-flex justify-content-end">
                  <Button
                    className="rounded d-flex align-items-center"
                    type="submit"
                    variant="primary-soft"
                    size="sm"
                    onClick={handleSubmitUsingGoogleContacts}
                  >
                    <EnvelopeCheck className="fs-6 me-2" />
                    <span>Invite</span>
                  </Button>
                </div>
              </div>
            )}
          </div>
        )}
        {inviteOption === "link" && (
          <Modal.Footer className="d-flex justify-content-center m-0 pb-2 p-0">
            <div>
              <div className="btn me-2" onClick={handleWhatsappShare}>
                <Whatsapp style={{ fontSize: "28px", color: "#00CF08" }} />
              </div>
              <div className="btn" onClick={handleFacebookShare}>
                <Facebook style={{ fontSize: "28px", color: "#0057DA" }} />
              </div>
            </div>
          </Modal.Footer>
        )}
      </Modal>
      <Modal
        show={showRemoveModal}
        onHide={() => setShowRemoveModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Revoke</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <i
            style={{ fontSize: "4.5rem" }}
            className="text-body bi bi-exclamation-triangle"
          ></i>
          <div className="mt-4 fs-5 text-body">
            Are you sure you want to revoke access from {DATA.displayName}?
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button
            variant="secondary-soft"
            className="w-25 mx-1"
            onClick={() => {
              setDATA({
                friendId: "",
                displayName: "",
              });
              setShowRemoveModal(false);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            className="w-25 mx-1"
            onClick={() => {
              console.log("data.friendId", DATA.friendId);
              handleRevokeUser("", DATA.friendId);
              setDATA({
                friendId: "",
                displayName: "",
              });
              setShowRemoveModal(false);
            }}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default InvitePeople;
