import { collection, query, where, getDocs, orderBy } from "firebase/firestore";
import { db } from "../../FirebaseConfig";
import { generateChatId } from "./generateChatId";

// Function to fetch messages from Firestore based on chatId
export const getMessagesFromDB = async (user, uid) => {
  try {
    const chatId = generateChatId(user?.uid, uid);
    // Create a reference to the messages collection under the given chatId
    const messagesRef = collection(db, "chats", chatId, "messages");

    // Query to fetch all the messages and sort them by the timestamp in ascending order
    const q = query(messagesRef, orderBy("timestamp", "asc"));

    // Execute the query
    const querySnapshot = await getDocs(q);

    // Map through the documents and return the message data
    const messages = querySnapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));

    return messages;
  } catch (error) {
    console.error("Error fetching messages from Firestore:", error);
    return [];
  }
};
