import React, { useState } from "react";
import {
  NavLink,
  Route,
  Routes,
  useLocation,
  useParams,
} from "react-router-dom";
import UserActivity from "./UserActivity";
import UserAbout from "./UserAbout";
import UserPosts from "./UserPosts";
import UserFollowing from "./UserFollowingTab";
import UserFollower from "./UserFollowerTab";

import RightSideBarProfilePage from "../SideNav/RightSideBarProfilePage";
import UserConnections from "./UserConnections";
import UserProfileHeader from "./UserProfileHeader";
import SavedPosts from "./SavedPosts";
import InviteStats from "../Referral/InviteStats";
import { useFetchUserData } from "../hooks/user/useFetchUserData";
import { UserAuth } from "../Context";
import PointsStats from "../Points/PointsStats";
//importing sidebar links here

function ProfilePage() {
  const { uid } = useParams(); // Extract the "uid" parameter from the URL
  const { user } = UserAuth();
  const { userData } = useFetchUserData(uid);
  const location = useLocation();
  const [selectedOption, setSelectedOption] = useState("profile");
  return (
    <>
      {/* // Main content START */}
      <span className="col-lg-2"></span>
      <div className="col-lg-8 vstack mt-5">
        <UserProfileHeader
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
        />
        <div>
          <ul
            className={`nav nav-bottom-line align-items-start justify-content-start justify-content-md-center mb-3 h6 profileNav fs-6`}
          >
            <style jsx>
              {`
                .profileNav .nav-item:first-child .nav-link {
                  padding: 0.75rem 0.975rem;
                }
              `}
            </style>
            {selectedOption === "profile" && (
              <>
                <li className="nav-item">
                  <NavLink
                    to={`/userProfile/${uid}/profile/userAbout`}
                    className="nav-link pt-2 pb-2"
                    activeClassName="active"
                  >
                    About
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to={`/userProfile/${uid}/profile/pointsStats`}
                    className="nav-link pt-2 pb-2"
                    activeClassName="active"
                  >
                    Points Stats
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to={`/userProfile/${uid}/profile/invite`}
                    className="nav-link pt-2 pb-2"
                    activeClassName="active"
                  >
                    Invite
                  </NavLink>
                </li>
              </>
            )}
            {selectedOption === "posts" && (
              <>
                <li className="nav-item">
                  <NavLink
                    to={`/userProfile/${uid}/posts/myPosts`}
                    className="nav-link pt-2 pb-2"
                    activeClassName="active"
                  >
                    My Posts
                  </NavLink>
                </li>
                {user?.uid === uid && (
                  <li className="nav-item">
                    <NavLink
                      to={`/userProfile/${uid}/posts/savedposts`}
                      className="nav-link pt-2 pb-2"
                      activeClassName="active"
                    >
                      Saved Posts
                    </NavLink>
                  </li>
                )}
              </>
            )}
            {selectedOption === "network" && (
              <>
                <li className="nav-item">
                  <NavLink
                    to={`/userProfile/${uid}/network/userConnections`}
                    className="nav-link pt-2 pb-2"
                    activeClassName="active"
                  >
                    Connections
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to={`/userProfile/${uid}/network/userfollowers`}
                    className="nav-link pt-2 pb-2"
                    activeClassName="active"
                  >
                    Followers
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to={`/userProfile/${uid}/network/userfollowing`}
                    className="nav-link pt-2 pb-2"
                    activeClassName="active"
                  >
                    Following
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to={`/userProfile/${uid}/network/brands`}
                    className="nav-link pt-2 pb-2"
                    activeClassName="active"
                  >
                    Brands
                  </NavLink>
                </li>
              </>
            )}
            {/* {selectedOption === "shoppinglist" && (
              <>
                <li className="nav-item">
                  <NavLink
                    to={`/userProfile/${uid}/shoppinglist/myList`}
                    className="nav-link pt-2 pb-2"
                    activeClassName="active"
                  >
                    My List
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to={`/userProfile/${uid}/shoppinglist/invitedList`}
                    className="nav-link pt-2 pb-2"
                    activeClassName="active"
                  >
                    Invited List
                  </NavLink>
                </li>
              </>
            )} */}
          </ul>
        </div>
        <Routes>
          <Route path="/userActivity" element={<UserActivity />} />
          <Route
            path="/profile/userAbout"
            element={<UserAbout userData={userData} />}
          />
          <Route path="/" element={<UserAbout userData={userData} />} />
          <Route
            path="/profile/pointsStats"
            element={<PointsStats userData={userData} />}
          />
          <Route
            path="/profile/invite"
            element={<InviteStats userData={userData} />}
          />

          <Route
            path="/posts/myPosts"
            element={<UserPosts userData={userData} user={user} />}
          />
          <Route path="/posts/savedposts" element={<SavedPosts />} />
          <Route
            path="/network/userConnections"
            element={<UserConnections userData={userData} />}
          />
          <Route
            path="/network/userfollowing"
            element={<UserFollowing userData={userData} path={"users"} />}
          />
          <Route
            path="/network/brands"
            element={<UserFollowing userData={userData} path={"brands"} />}
          />
          <Route
            path="/network/userfollowers"
            element={<UserFollower userData={userData} />}
          />
        </Routes>
      </div>
      <span className="col-lg-2"></span>
      {/* // Main content END // <RightSideBarProfilePage /> */}
    </>
  );
}

export default ProfilePage;
