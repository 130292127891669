import React, { useState } from 'react';
import { db, storage } from '../FirebaseConfig';
import { collection, addDoc, doc, updateDoc, serverTimestamp, arrayUnion, setDoc  } from "firebase/firestore";
import { Form, Button, Container, ProgressBar, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import algoliasearch from 'algoliasearch';
import { toastOptions } from '../lib/toastOptions';
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import Upload from 'antd/es/upload/Upload';
//algolia object initialization and index mapping
const client = algoliasearch("EMFUSB67CG", "0f7466afc30b24c667a61b43cf879898");
const Productindex = client.initIndex("products"); //name of index in algolia is products

function BrandPostForm({ brandName, userID, closeModal }) {
    const [files, setFiles] = useState([]);
    const [mainFile, setMainFile] = useState(null); 
    const [file, setFile] = useState(null);
    const [fileList, setFileList] = useState([]);
    const [mediaType, setMediaType] = useState("");
    const [caption, setCaption] = useState('');
    const [description, setDescription] = useState('');
    const [product, setProduct] = useState('');
    const [mediaCount, setMediaCount] = useState(0);
    const [postType, setpostType] = useState('brandPost');
    const [date, setDate] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [searchResults, setSearchResults] = useState([]);
    const [searchInput, setSearchInput] = useState('');

    // const handleFileChange = (e) => {
    //     // Handle multiple file uploads
    //     const newFiles = Array.from(e.target.files).map(file => ({
    //         file,
    //         name: file.name,
    //         type: file.type
    //     }));
    //     setFiles([...files, ...newFiles]);
    // };

    const handleFileChange = ({ fileList }) => {
        // Update the file state with the uploaded files
        const newFiles = fileList.map(({ originFileObj }) => ({
            file: originFileObj,
            name: originFileObj.name,
            type: originFileObj.type
        }));
        setFiles(newFiles);
    };

    const handleMainFileChange = (filename) => {
        // Update which file is marked as the main file
        setMainFile(filename);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
    
        const postData = {
            caption,
            description,
            product,
            mainFile,
            mediaCount: files.length,
            postType,
            brandName,
            flag: false,
            createdAt: serverTimestamp(),
        };
    
        try {
            const docRef = await addDoc(collection(db, "brandposts"), postData);
    
            if (files.length > 0) {
                const mediaCollectionRef = collection(docRef, "media");
                const uploadPromises = files.map(({ file, name }) => {
                    const fileRef = ref(storage, `brandposts/${docRef.id}/${name}`);
                    const uploadTask = uploadBytesResumable(fileRef, file);
                    return new Promise((resolve, reject) => {
                        uploadTask.on('state_changed',
                            (snapshot) => {
                                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                                setUploadProgress(progress);
                            },
                            (error) => {
                                reject(error);
                            },
                            async () => {
                                const fileUrl = await getDownloadURL(uploadTask.snapshot.ref);
                                const fileDocRef = doc(mediaCollectionRef, name);  // Using the file name as the document ID
                                await setDoc(fileDocRef, {
                                    url: fileUrl,
                                    type: file.type
                                });
                                resolve({ url: fileUrl, type: file.type, name });
                            }
                        );
                    });
                });
    
                await Promise.all(uploadPromises);
                toast.success(`Post created successfully with ${files.length} files!`, toastOptions);
            } else {
                toast.success("Post created successfully!", toastOptions);
            }
            setIsLoading(false);
            resetForm();
            closeModal();
        } catch (error) {
            console.error("Error adding document: ", error);
            toast.error("Error creating post: " + error.message, toastOptions);
            setIsLoading(false);
        }
    };

    const uploadOptions = {
        image: {
            accept: ".png,.jpg,.jpeg,.webp",
            label: "Upload Images",
            icon: "bi-image-fill"
        },
        video: {
            accept: ".mp4,.mkv,.avi",
            label: "Upload Videos",
            icon: "bi-camera-reels-fill"
        }
    };

    const handleMediaTypeSelection = (type) => {
        setMediaType(type);
        setFileList([]);  
    };


    const handleSearch = async (event) => {
        event.preventDefault();  // Correctly use preventDefault
        if (searchInput.trim().length >= 3) {
            const searchQuery = `${searchInput.trim()} ${brandName}`;
            console.log("Search input: ", searchQuery);
            try {
                const offset = 0;
                const data = await Productindex.search(searchQuery, {
                    offset,
                    length: 12,  // Define items per page here directly
                });
                const uniqueProducts = new Set(data.hits.map(item => item)); // Simplify to ensure uniqueness
                const updatedProducts = Array.from(uniqueProducts);
                console.log(`${brandName} products in search:`, updatedProducts);
                setSearchResults(updatedProducts);
            } catch (error) {
                console.error("Error fetching data: ", error);
                toast.error("Error searching products.", toastOptions);
            }
        } else {
            console.log("Please enter at least 3 characters to search.");
            toast.warn("Please enter at least 3 characters to search.", toastOptions);
        }
    };
    

    const resetForm = () => {
        setFile(null);
        setCaption('');
        setFiles([]);
        setMainFile(null);
        setDescription('');
        setProduct('');
        setMediaCount(0);
        setpostType('brandPost');
        setDate('');
        setUploadProgress(0);
    };

    return (
        <Modal show={true} onHide={closeModal}>
            <Modal.Header closeButton>
                <Modal.Title>Create a Brand Post for {brandName}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container className="mt-3">
                    <Form onSubmit={handleSubmit}>
                        <Form.Group controlId="formFile" className="mb-3">

            <Upload
              type="file"
              accept=".png,.jpg,.jpeg,.webp,.mp4,.mkv,.avi"
              className="dropzone dropzone-default card shadow-none"
              multiple={true}
            onChange={handleFileChange}
              style={{ backgroundColor: "#333", color: "white" }}
            >
              <div className="dz-message">
                <i className="bi bi-images display-3"></i>
                <p>click to upload promotion videos or images.</p>
              </div>
            </Upload>

                        </Form.Group>
                        {files.length > 0 && (
                        <Form.Label>Select Thumbnail</Form.Label>
                        )}

                        {files.map(({ name, type }) => (
                            <div key={name} className="mb-2">
                                <Form.Check
                                    type="radio"
                                    name="mainFile"
                                    id={name}
                                    label={`${name.substring(0, 40)} (${type.split('/')[0]})`}
                                    checked={mainFile === name}
                                    onChange={() => handleMainFileChange(name)}
                                />
                            </div>
                        ))}

                        <Form.Group controlId="formCaption" className="mb-3">
                            <Form.Label>Caption</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter caption"
                                value={caption}
                                onChange={(e) => setCaption(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group controlId="formDescription" className="mb-3">
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                                as="textarea"
                                placeholder="Enter description"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                required
                            />
                        </Form.Group>                       
                        <Form.Label>Product Search</Form.Label>
                          <Form.Group controlId="formSearchProduct" className="d-flex mb-3">
                            <Form.Control
                                type="text"
                                placeholder="Search for products"
                                value={searchInput}
                                onChange={(e) => setSearchInput(e.target.value)}
                            />
                        <Button onClick={(event) => handleSearch(event, brandName)} variant="outline-primary" >
                            Search
                        </Button>       
                        </Form.Group>
                        {file && (
                            <ProgressBar now={uploadProgress} label={`${Math.round(uploadProgress)}%`} />
                        )}
                        <Button variant="primary" type="submit" disabled={isLoading}>
                            {isLoading ? 'Posting...' : 'Submit'}
                        </Button>
                    </Form>
                </Container>
            </Modal.Body>
        </Modal>
    );
}

export default BrandPostForm;
