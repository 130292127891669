// hooks/useFollowUser.js
import { useState, useEffect } from 'react';
import { getDoc, doc, collection, setDoc, deleteDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../FirebaseConfig';
import useNotification from './useNotification';
import useFeedNotification from './useFeedNotification';
import { toast } from 'react-toastify';
import { toastOptions } from '../lib/toastOptions';

const useFollowUser = (currentUserId, profileUserId) => {
  const [isFollowed, setIsFollowed] = useState(false);
  const [isFollowBack, setIsFollowBack] = useState(false);
  const { addNotification } = useNotification();
  const { addFeedNotification } = useFeedNotification();

  useEffect(() => {
    const checkFollowStatus = async () => {
      if (!currentUserId || !profileUserId) return;

      // Check if current user is following the profile user
      const followingDocRef = doc(db, "users", currentUserId, "following", profileUserId);
      const followingSnapshot = await getDoc(followingDocRef);
      if (followingSnapshot.exists()) {
        setIsFollowed(true);
      } else {
        setIsFollowed(false);
      }

      // Check if profile user is following the current user (for follow-back logic)
      const followersDocRef = doc(db, "users", currentUserId, "followers", profileUserId);
      const followersSnapshot = await getDoc(followersDocRef);
      if (followersSnapshot.exists() && !isFollowed) {
        setIsFollowBack(true);
      } else {
        setIsFollowBack(false);
      }
    };

    checkFollowStatus();
  }, [currentUserId, profileUserId, isFollowed]);

  const followUser = async () => {
    if (!currentUserId || !profileUserId) return;

    try {
      // Add profileUserId to current user's following collection
      const followingRef = doc(db, "users", currentUserId, "following", profileUserId);
      await setDoc(followingRef, { type: "user", timestamp: serverTimestamp() });

      // Add currentUserId to profile user's followers collection
      const followerRef = doc(db, "users", profileUserId, "followers", currentUserId);
      await setDoc(followerRef, { type: "user", timestamp: serverTimestamp() });

      // Set state to reflect follow status
      setIsFollowed(true);
      setIsFollowBack(false);

      // Add notifications
      if (currentUserId !== profileUserId) {
        await addNotification({
          userId: profileUserId,
          id: currentUserId,
          type: 'followed',
          timestamp: serverTimestamp(),
          by: 'user',
          seen: false,
        });

        addFeedNotification({
          ownerId: profileUserId,
          id: currentUserId,
          type: 'followedUser',
          by: 'user',
        });
      }

      toast.success('User followed successfully', toastOptions);
    } catch (error) {
      console.error('Error following user:', error);
      toast.error('Failed to follow user', toastOptions);
    }
  };

  const unfollowUser = async () => {
    if (!currentUserId || !profileUserId) return;

    try {
      // Remove profileUserId from current user's following collection
      const followingRef = doc(db, "users", currentUserId, "following", profileUserId);
      await deleteDoc(followingRef);

      // Remove currentUserId from profile user's followers collection
      const followerRef = doc(db, "users", profileUserId, "followers", currentUserId);
      await deleteDoc(followerRef);

      // Set state to reflect unfollow status
      setIsFollowed(false);

      toast.success('User unfollowed successfully', toastOptions);
    } catch (error) {
      console.error('Error unfollowing user:', error);
      toast.error('Failed to unfollow user', toastOptions);
    }
  };

  return {
    isFollowed,
    isFollowBack,
    followUser,
    unfollowUser,
  };
};

export default useFollowUser;
