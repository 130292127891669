import { doc, collection, query, where, getDocs, setDoc, serverTimestamp, updateDoc, arrayUnion, arrayRemove, deleteDoc } from "firebase/firestore";
import { db } from "../FirebaseConfig";

const useNotification = () => {
  
  const addNotification = async ({ userId, id, type, by, fields }) => {
    try {
      const notificationsCollectionRef = collection(db, "users", userId, "notifications");
  
      // If the type is "listChat", directly create a new document without checking for matching ones
      if (type === "listChat" || type === "friendRequest" || type === "postComment") {
        const notificationData = {
          timestamp: serverTimestamp(),
          id: [id],
          type,
          by,
          seen:false,
          ...fields, 
        };
  
        await setDoc(doc(notificationsCollectionRef), notificationData);
        return; // Exit after creating the document
      }
  
      // Build dynamic query conditions based on type and fields for other notification types
      const notificationConditions = [where("type", "==", type)];
      
      // Dynamically add conditions based on the type and provided fields
      if (fields && typeof fields === "object") {
        Object.keys(fields)?.forEach((key) => {
          if (fields[key] !== null && fields[key] !== undefined) {
            notificationConditions.push(where(key, "==", fields[key]));
          }
        });
      }
  
      // Query for existing notifications with dynamic conditions
      const notificationQuery = query(notificationsCollectionRef, ...notificationConditions);
      const notificationSnapshot = await getDocs(notificationQuery);
  
      if (!notificationSnapshot.empty) {
        // If a matching notification exists, update it
        const docRef = notificationSnapshot.docs[0].ref;
        const existingData = notificationSnapshot.docs[0].data();
  
        // Ensure the new id is added at the start of the array
        const updatedIds = Array.isArray(existingData.id)
          ? [id, ...existingData.id.filter(existingId => existingId !== id)]
          : [id];
  
        await updateDoc(docRef, {
          timestamp: serverTimestamp(), // Update the timestamp
          id: updatedIds, // Prepend the new id to the array
          seen:false,
        });
  
        return; // Exit the function after updating the document
      }
  
      // If no existing notification was found, create a new one
      const notificationData = {
        timestamp: serverTimestamp(),
        id: [id], // Initialize id as an array
        type,
        by,
        seen:false,
        ...fields, // Include additional fields dynamically
      };
  
      await setDoc(doc(notificationsCollectionRef), notificationData);
  
    } catch (error) {
      console.error("Error adding notification: ", error);
    }
  };
  
  const deleteNotification = async ({ userId, id, type, by, fields }) => {
    try {
      const notificationsCollectionRef = collection(db, "users", userId, "notifications");

      // Build the query dynamically based on provided filters
      const notificationConditions = [
        where("id", "array-contains", id), // Check if id is in the array
        where("type", "==", type),
        where("by", "==", by),
      ];

      if (fields && typeof fields === "object") {
        Object.keys(fields)?.forEach((key) => {
          if (fields[key] !== null && fields[key] !== undefined) {
            notificationConditions.push(where(key, "==", fields[key]));
          }
        });
      }

      const notificationQuery = query(notificationsCollectionRef, ...notificationConditions);
      const notificationSnapshot = await getDocs(notificationQuery);

      notificationSnapshot.forEach(async (docSnapshot) => {
        const notificationData = docSnapshot.data();
        const currentIds = notificationData.id;

        if (Array.isArray(currentIds) && currentIds.length === 1 && currentIds[0] === id) {
          // If there's only one id, delete the whole document
          await deleteDoc(docSnapshot.ref);
        } else {
          // If there are multiple ids, remove the specific id from the array
          await updateDoc(docSnapshot.ref, {
            id: arrayRemove(id) // Remove the specific id from the array
          });
        }
      });

    } catch (error) {
      console.error("Error deleting notification: ", error);
    }
  };

  return { addNotification, deleteNotification };
};

export default useNotification;
