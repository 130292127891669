import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  increment,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import React, { useEffect, useRef, useState } from "react";
import { db } from "../FirebaseConfig";
import { UserAuth } from "../Context";
import { formatTimestampChat } from "./lib.js/formatTimestampChat";
import { Link } from "react-router-dom";
import ProductCard3 from "../Products/ProductCard3";
import ProfilePicture from "../UserProfile/ProfilePicture";
import Picker from "emoji-picker-react";
import { decryptChat, encryptChat } from "../ShoppingList/Encryption";
import useSocketStore from "../StatesStore/Socket";
import { saveListMessageToDb } from "../ShoppingList/lib/saveListMessageToDb";
import useNotification from "../hooks/useNotification";
import { toast } from "react-toastify";
import { toastOptions } from "../lib/toastOptions";
import { saveMessageToDB } from "./lib.js/saveMsgToDb";

function ConvoScreen({ chat, selectedChat }) {
  // console.log("chat", chat);
  const [messages, setMessages] = useState([{}]);
  const [commentText, setCommentText] = useState("");
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [isRecipientOnline, setIsRecipientOnline] = useState(false);
  const [productData, setProductData] = useState(null);
  const [listMembers, setListMembers] = useState(null);
  const endRef = useRef();
  const emojiPickerRef = useRef();
  const commentRef = useRef();
  const socket = useSocketStore((state) => state.socket);
  const [loading, setLoading] = useState(true);
  const { user } = UserAuth();
  useEffect(() => {
    console.log("messages", messages);
  }, [messages]);

  const fetchChatMessages = async () => {
    setLoading(true);
    const chatMessageRef = collection(db, "chats", selectedChat, "messages");
    const q = query(chatMessageRef, orderBy("timestamp", "asc"));
    const chatMessageSnap = await getDocs(q);
    const messages = await Promise.all(
      chatMessageSnap.docs.map(async (message) => {
        if (message?.data()?.type === "product") {
          const productRef = doc(
            db,
            "chats",
            selectedChat,
            "messages",
            message?.id,
            "product",
            message?.data()?.productDocID
          );
          const productSnap = await getDoc(productRef);
          if (productSnap.exists()) {
            return { ...message.data(), productData: productSnap.data() };
          }
          return message.data();
        } else return message.data();
      })
    );
    // console.log("messages", messages);
    setMessages(messages);
    setListMembers(chat?.friendsData.map((friend) => friend?.id));
    setLoading(false);
  };
  const toggleEmojiPicker = () => {
    setShowEmojiPicker(!showEmojiPicker);
  };

  const addEmoji = (emojiObject) => {
    // Implement logic to add emoji to comment text
    const emoji = emojiObject.emoji;
    setCommentText(commentText + emoji);
  };
  const handleCommentInput = async (e) => {
    setCommentText(e.target.value);
    if (e.target.value.includes("/#/ProductDetails/")) {
      let text = e.target.value;
      // Extract the product code
      text = text.split("/#/ProductDetails/");
      // test case checking for: if no code is written is added after ProductDetails/
      if (text[1] !== "") {
        console.log("FOUNDD", text);
        //If some extra text is used after link then do not fetch again and again for product
        if (text[1].split(" ").length === 1) {
          const productRef = doc(db, "products", text[1].split(" ")[0]);
          const productSnap = await getDoc(productRef);
          if (productSnap.exists()) {
            console.log("FOUNDD", productSnap.data());
            setProductData(productSnap.data());
            if (commentRef && commentRef.current) {
              commentRef.current.scrollTop = commentRef.current.scrollHeight;
            }
          } else setProductData(null);
        }
      }
    } else {
      setProductData(null);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const content = commentText;
    // Clear the comment input field
    setCommentText("");
    console.log("CLICKED");
    const encryptedMessage = encryptChat(content);
    if (chat?.type === "private") {
      try {
        const msgData = {
          content: encryptedMessage,
          receiverId:
            chat?.members[0] !== user?.uid
              ? chat?.members[0]
              : chat?.members[1],
          senderId: user?.uid,
          type: productData ? "product" : "text",
          timestamp: serverTimestamp(),
        };

        setCommentText("");
        const commentDocRef = await saveMessageToDB(msgData);
        console.log(productData, commentDocRef);
        try {
          if (productData) {
            // Add if any productRef is used in chat
            const product = await addDoc(
              collection(
                db,
                "chats",
                chat?.id,
                "messages",
                commentDocRef,
                "product"
              ),
              { ...productData }
            );
            console.log("ID", product?.id);
            await updateDoc(
              doc(db, "chats", chat?.id, "messages", commentDocRef),
              {
                productDocID: product?.id,
              }
            );
            if (socket) {
              socket.emit("chat msg", msgData);
              setMessages((prevMsgs) => [
                ...prevMsgs,
                { ...msgData, productData, productDocID: product?.id },
              ]);
            }

            setProductData(null);
          } else {
            if (socket) {
              socket.emit("chat msg", msgData);
              setMessages((prevMsgs) => [...prevMsgs, msgData]);
            }
          }
        } catch (error) {
          console.error(error);
        }
      } catch (error) {
        console.error("Error adding comment: ", error);
        // Handle any errors here, e.g., display an error message to the user
      }
    } else if (chat?.type === "list") {
      try {
        const userRef = doc(db, "users", user?.uid);
        const userSnap = await getDoc(userRef);
        const msgData = {
          content: encryptedMessage,
          senderId: user?.uid,
          listId: chat?.id,
          userName: userSnap?.data()?.userName,
          photo: userSnap?.data()?.photo,
          type: productData ? "product" : "text",
        };
        setCommentText("");
        // Fetch the comments again to include the newly added comment
        // Create a new comment document with specified attributes
        const commentDocRef = await saveListMessageToDb(
          msgData,
          chat?.id,
          listMembers
        );
        try {
          if (productData) {
            // Add if any productRef is used in chat
            const product = await addDoc(
              collection(
                db,
                "chats",
                chat?.id,
                "messages",
                commentDocRef,
                "product"
              ),
              { ...productData }
            );
            console.log("ID", product?.id);
            await updateDoc(
              doc(db, "chats", chat?.id, "messages", commentDocRef),
              {
                productDocID: product?.id,
              }
            );
            if (socket) {
              // Emit the message to all list members
              socket.emit("list chat msg", {
                listId: chat?.id,
                msg: msgData,
              });

              setMessages((prevMsgs) => [
                ...prevMsgs,
                { ...msgData, productData, productDocID: product?.id },
              ]);
            }

            setProductData(null);
          } else {
            if (socket) {
              // Emit the message to all list members
              socket.emit("list chat msg", {
                listId: chat?.id,
                msg: msgData,
              });
              setMessages((prevMsgs) => [...prevMsgs, msgData]);
            }
          }
        } catch (error) {
          console.error(error);
        }
      } catch (error) {
        console.error("Error adding comment: ", error);
        // Handle any errors here, e.g., display an error message to the user
      }
    }
  };
  const handleAddProductToList = async (productID, product) => {
    const userRef = doc(
      db,
      "users",
      user?.uid,
      "shoppingList",
      chat?.listName,
      "products",
      productID
    );
    await setDoc(userRef, {
      size: "",
      timestamp: serverTimestamp(),
      likeCount: 0,
    });
    // Increment the shoppingListCount
    await updateDoc(doc(db, "users", user.uid), {
      shoppingListCount: increment(1),
    });
    toast.success(`Product Added to ${chat?.listName} list`, toastOptions);
  };
  useEffect(() => {
    if (chat?.type === "private") {
      socket.on("chat msg", (msgrecv) => {
        setMessages((prevMsgs) => [...prevMsgs, msgrecv]);
      });
      socket.on("user status", ({ userId, status }) => {
        console.log(userId, status);
        if (userId === chat?.friendsData[0]?.id) {
          setIsRecipientOnline(status === "online");
        }
      });
      // Request initial status of the recipient
      socket.emit("get user status", chat?.friendsData[0]?.id);
    }
    // return () => socket.close();
  }, [user?.uid, chat]);

  useEffect(() => {
    if (!chat || !selectedChat) return;
    fetchChatMessages();
  }, [chat, selectedChat]);
  if (loading) {
    return (
      <div class="col-lg-8 col-xxl-9">
        <div class="card card-chat rounded-start-lg-0 border-start-lg-0">
          <div class="card-body h-75 d-flex justify-content-center align-items-center">
            <h3 className="fw-semibold">
              {!chat ? "No chats to show" : "Loading..."}
            </h3>
          </div>
        </div>
      </div>
    );
  }
  return (
    <>
      <div class="col-lg-8 col-xxl-9">
        <div class="card card-chat rounded-start-lg-0 border-start-lg-0">
          <div class="card-body h-75 pb-0">
            <div class="tab-content py-0 mb-0 h-100" id="chatTabsContent">
              <div class="fade tab-pane show active h-100">
                {/* <!-- Top avatar and status START --> */}
                <div class="d-sm-flex justify-content-between align-items-center">
                  <Link
                    class="d-flex mb-2 mb-sm-0"
                    to={`/userProfile/${chat?.friendsData[0]?.id}`}
                  >
                    {chat?.type === "private" ? (
                      <>
                        <div class="flex-shrink-0 avatar me-2">
                          <ProfilePicture userData={chat.friendsData[0]} />
                        </div>
                        <div class="d-block flex-grow-1">
                          <h6 class="mb-0 mt-1">
                            {chat?.friendsData[0]?.userName}
                          </h6>
                          <div className="small text-secondary">
                            <i
                              className={`fa-solid fa-circle ${
                                isRecipientOnline
                                  ? "text-success"
                                  : "text-muted"
                              } me-1`}
                            ></i>
                            {isRecipientOnline ? "Online" : "Offline"}
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="d-flex align-items-center">
                        {chat.friendsData.length === 1 ? (
                          <div className="flex-shrink-0 avatar">
                            <ProfilePicture userData={chat.friendsData[0]} />
                          </div>
                        ) : (
                          <ul
                            className={`avatar-group avatar-group-${
                              chat?.friendsData.length === 2 ? "two" : "three"
                            }`}
                          >
                            {chat?.friendsData.map((friend) => {
                              return (
                                <li className="avatar avatar-xs">
                                  <ProfilePicture userData={friend} />
                                </li>
                              );
                            })}
                          </ul>
                        )}
                        <div class="d-block ps-2">
                          <h6 className="mb-0 mt-1">{chat?.listName}</h6>
                          <h6 className="mb-0 fw-light">
                            {chat?.friendsData.map((friend, index) => {
                              if (friend?.id === user?.uid)
                                return `${index !== 0 ? ", You" : "You"}`;
                              else
                                return (
                                  `${index !== 0 ? ", " : ""}` +
                                  friend.userName.split(" ")[0]
                                );
                            })}
                          </h6>
                        </div>
                      </div>
                    )}
                  </Link>
                  {/* <div class="d-flex align-items-center">
                    <a
                      href="#!"
                      class="icon-md rounded-2 btn btn-primary-soft me-2 px-2"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Audio call"
                    >
                      <i class="bi bi-telephone-fill"></i>
                    </a>
                    <a
                      href="#!"
                      class="icon-md rounded-2 btn btn-primary-soft me-2 px-2"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Video call"
                    >
                      <i class="bi bi-camera-video-fill"></i>
                    </a>
                    <div class="dropdown">
                      <a
                        class="icon-md rounded-2 btn btn-primary-soft me-2 px-2"
                        href="#"
                        id="chatcoversationDropdown"
                        role="button"
                        data-bs-toggle="dropdown"
                        data-bs-auto-close="outside"
                        aria-expanded="false"
                      >
                        <i class="bi bi-three-dots-vertical"></i>
                      </a>
                      <ul
                        class="dropdown-menu dropdown-menu-end"
                        aria-labelledby="chatcoversationDropdown"
                      >
                        <li>
                          <a class="dropdown-item" href="#">
                            <i class="bi bi-check-lg me-2 fw-icon"></i>Mark as
                            read
                          </a>
                        </li>
                        <li>
                          <a class="dropdown-item" href="#">
                            <i class="bi bi-mic-mute me-2 fw-icon"></i>Mute
                            conversation
                          </a>
                        </li>
                        <li>
                          <a class="dropdown-item" href="#">
                            <i class="bi bi-person-check me-2 fw-icon"></i>
                            View profile
                          </a>
                        </li>
                        <li>
                          <a class="dropdown-item" href="#">
                            <i class="bi bi-trash me-2 fw-icon"></i>Delete chat
                          </a>
                        </li>
                        <li class="dropdown-divider"></li>
                        <li>
                          <a class="dropdown-item" href="#">
                            <i class="bi bi-archive me-2 fw-icon"></i>
                            Archive chat
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div> */}
                </div>
                {/* <!-- Top avatar and status END --> */}
                <hr />
                {/* <!-- Chat conversation START --> */}
                <div class="chat-conversation-content overflow-auto custom-scrollbar">
                  {/* <!-- Chat time --> */}
                  {/* <div class="text-center small my-2">
                    Jul 16, 2022, 06:15 am
                  </div> */}
                  {messages.map((message) => {
                    if (message?.senderId === user?.uid)
                      return (
                        <>
                          {message.type === "product" && (
                            <div
                              className={`d-flex justify-content-${
                                message?.senderId === user?.uid
                                  ? "end"
                                  : "start"
                              }`}
                            >
                              <div className="col-3">
                                <ProductCard3
                                  product={message?.productData}
                                  isStrip={false}
                                  addButtonData={
                                    chat?.type === "list" && {
                                      isAddButton: true,
                                      handleAddProductToList,
                                      selectedShopList: chat?.listName,
                                      userId: user?.uid,
                                    }
                                  }
                                />
                              </div>
                            </div>
                          )}
                          <div class="d-flex justify-content-end text-end mb-1">
                            <div class="w-100">
                              <div class="d-flex flex-column align-items-end">
                                <div class="bg-primary text-white p-2 px-3 rounded-2">
                                  {decryptChat(message?.content)}
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    else
                      return (
                        <div class="d-flex mb-1">
                          <div class="flex-shrink-0 avatar avatar-sm me-2">
                            <ProfilePicture userData={chat?.friendsData[0]} />
                          </div>
                          <div class="flex-grow-1">
                            <div class="w-100">
                              <div class="d-flex flex-column align-items-start">
                                <div class="bg-light text-secondary p-2 px-3 rounded-2">
                                  {decryptChat(message?.content)}
                                </div>
                                <div class="small my-2">
                                  {formatTimestampChat(message.timestamp)}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                  })}

                  {/* <!-- Chat message left typing (implemented when socket is applied) --> */}
                  {/* <div class="d-flex mb-1">
                    <div class="flex-shrink-0 avatar avatar-xs me-2">
                      <img
                        class="avatar-img rounded-2"
                        src="assets/images/avatar/10.jpg"
                        alt=""
                      />
                    </div>
                    <div class="flex-grow-1">
                      <div class="w-100">
                        <div class="d-flex flex-column align-items-start">
                          <div class="bg-light text-secondary p-3 rounded-2">
                            <div class="typing d-flex align-items-center">
                              <div class="dot"></div>
                              <div class="dot"></div>
                              <div class="dot"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
                {/* <!-- Chat conversation END --> */}
              </div>
            </div>
          </div>
          <div class="card-footer p-0">
            <form
              className="position-relative mx-3 my-2"
              onSubmit={(e) => handleSubmit(e)}
            >
              <div className=" rounded input-group d-flex border justify-content-center align-items-center">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text border-0 bg-transparent"
                    onClick={toggleEmojiPicker}
                    style={{ cursor: "pointer" }}
                  >
                    <i className="bi bi-emoji-smile"></i>
                  </span>
                </div>

                <input
                  className="form-control border-0"
                  type="text"
                  placeholder="Type a comment"
                  value={commentText}
                  onChange={(e) => handleCommentInput(e)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && !e.shiftKey) {
                      handleSubmit(e);
                    }
                  }}
                />
                <button
                  className="input-group-text bg-transparent border-0"
                  type="submit"
                  disabled={commentText.length === 0}
                >
                  <i className="bi bi-send-fill"> </i>
                </button>

                <div
                  ref={emojiPickerRef}
                  className="position-absolute bottom-0"
                >
                  <Picker
                    onEmojiClick={addEmoji}
                    open={showEmojiPicker}
                    emojiStyle="native"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
  {
    /* <!-- Conversation item END --> */
  }
}

export default ConvoScreen;
