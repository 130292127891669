import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import formatTimestamp from '../lib/formatTimestamp';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../FirebaseConfig';
import { UserAuth } from '../Context';

function ProductPriceChange({notification}) {
  const [isSeen, setIsSeen] = useState(notification.seen);
    const navigate = useNavigate();
    const { user } = UserAuth();
    
    const handleNavigate = async () => {
      try {
        // Navigate to the dynamic route
        navigate(`/ProductDetails/${notification.productId}`);
  
        // Update the 'seen' attribute to true in Firestore
        if (!isSeen) {
          setIsSeen(true); 
          const notificationRef = doc(db, 'users', user?.uid, 'notifications', notification.notificationId);
          await updateDoc(notificationRef, { seen: true });
          
        }
      } catch (error) {
        console.error('Error updating notification: ', error);
      }
    };

    return (
      <li onClick={handleNavigate} style={{ cursor: 'pointer' }}>
         <div
        className={`d-flex m-1 `}
        style={{ display: "inline-flex", gap: "6px", cursor: "pointer" }}
      >
          <div class="avatar text-center d-none d-sm-inline-block">
            <img
              class="avatar-img rounded"
              src={notification?.imageUrl}
              alt=""
            />
          </div>
          <div className={`card w-100 d-flex align-items-center justify-content-center ${
          isSeen ? '' : 'badge-unread2'
        } `}>
          <div className="ms-2 w-100 d-flex justify-content-between align-items-center p-1">
          <div className="d-flex flex-grow-1">
              <p class="small mb-0">
                <b>{notification?.name}</b> price has changed to <b>PKR {notification.newPrice}</b> from PKR {notification.oldPrice}.{" "}
              </p>
         
            </div>
            <p class="small me-3">{formatTimestamp(notification.timestamp)}</p>
          </div>
          </div>
        </div>
      </li>
    );
}

export default ProductPriceChange