import { create } from "zustand";

const useShoppingListProductsStore = create((set, get) => ({
  // Store product data by list name
  productsByList: {},

  // Add or update product data for a specific list
  setProductData: (listName, productData) =>
    set((state) => ({
      productsByList: {
        ...state.productsByList,
        [listName]: productData,
      },
    })),

  // Get product data for a specific list
  getProductData: (listName) => {
    return get().productsByList[listName] || [];
  },

  // Add a single product to a list
  addProduct: (listName, product) =>
    set((state) => ({
      productsByList: {
        ...state.productsByList,
        [listName]: [...(state.productsByList[listName] || []), product],
      },
    })),

  // Remove a product from a list by id
  removeProduct: (listName, productId) =>
    set((state) => ({
      productsByList: {
        ...state.productsByList,
        [listName]: (state.productsByList[listName] || []).filter(
          (product) => product.id !== productId
        ),
      },
    })),

  // Update a product in a list
  updateProduct: (listName, productId, updatedProduct) =>
    set((state) => ({
      productsByList: {
        ...state.productsByList,
        [listName]: (state.productsByList[listName] || []).map((product) =>
          product.id === productId ? { ...product, ...updatedProduct } : product
        ),
      },
    })),

  // Clear all products from a specific list
  clearList: (listName) =>
    set((state) => ({
      productsByList: {
        ...state.productsByList,
        [listName]: [],
      },
    })),

  // Clear all lists
  clearAllLists: () =>
    set({
      productsByList: {},
    }),
}));

export default useShoppingListProductsStore;