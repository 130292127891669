import React from 'react'
import PointsItem from './PointsItem';
import { useFetchProductData } from '../hooks/product/useFetchProductData';
import { useFetchPostData } from '../hooks/useFetchPostData';
import { UserAuth } from '../Context';

function Points({message,route,imageUrl,...props }) {
    const { user } = UserAuth();
    return (
      <PointsItem  
        message={message} 
        route={route}
        user={user}
        imageUrl={imageUrl}
        {...props}
      />
    )
  }
  
  
  export function Posting({ postId, ...props}) {
    const message = `You created a Post.`;
    const route = `/post/${postId}`
    const { postData } = useFetchPostData(postId);
    const imageUrl = postData?.thumbnailUrl
    return <Points  message={message}  imageUrl={imageUrl} route={route} { ...props}/>;
  }
  
  export function LikeAProduct({ productId,...props }) {
    const message = `You liked a product.`
    const route = `/ProductDetails/${productId}`;

    const { productData } = useFetchProductData(productId);
    const imageUrl = productData?.imageUrl
    return <Points  message={message} imageUrl={imageUrl} route={route} {...props} />;
  }
