import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { collection, query, where, orderBy, limit, getDocs, startAfter } from "firebase/firestore";
import { db } from "../FirebaseConfig";
import ProductCard3 from "../Products/ProductCard3";
import { XMasonry, XBlock } from "react-xmasonry";
import { useInView } from "react-intersection-observer";
import { UserAuth } from "../Context";
import ProductCard5 from "../Products/ProductCard5";

export default function CustomFeed() {
  const { subSubCategory } = useParams();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [lastDoc, setLastDoc] = useState(null); // To track the last document
  const { ref: loadMoreRef, inView: loadMoreView } = useInView({ threshold: 0.1 });
  const { user } = UserAuth();

  // Function to make 20% of products advertised
  const makeProductsAdvertised = (productsList) => {
    return productsList.map(product => ({
      ...product,
      advertised: Math.random() < 0.2 // 20% chance of being advertised
    }));
  };

  // Fetch products based on selected subSubCategory with pagination
  const fetchProducts = async (isInitialLoad = false) => {
    setLoading(true);
    try {
      let q = query(
        collection(db, "products"),
        where("subCategory", "==", "Ready to Wear"),
        where("subSubCategory", "==", subSubCategory),
        orderBy("dateCreated", "desc"),
        limit(30)
      );

      // If not the initial load, start after the last fetched document
      if (!isInitialLoad && lastDoc) {
        q = query(q, startAfter(lastDoc));
      }

      const querySnapshot = await getDocs(q);
      const productsList = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

      // Update lastDoc to the last document of the current batch
      const lastVisibleDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
      setLastDoc(lastVisibleDoc);

      // Randomly mark 20% of products as advertised
      const productsWithAds = makeProductsAdvertised(productsList);

      // Append new products if not the initial load
      setProducts((prevProducts) => isInitialLoad ? productsWithAds : [...prevProducts, ...productsWithAds]);
    } catch (error) {
      console.error("Error fetching products:", error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch products on category change
  useEffect(() => {
    if (subSubCategory) {
      fetchProducts(true); // Initial load of products
    }
  }, [subSubCategory]);

  // Load more products when inView changes to true
  useEffect(() => {
    if (loadMoreView && !loading) {
      fetchProducts(); // Load next batch when user reaches the bottom
    }
  }, [loadMoreView]);

  function getTargetBlockWidth() {
    const screenWidth = window.innerWidth;
    return screenWidth < 440 ? 200 : 250;
  }

  return (
    <>
      <XMasonry
        center={false}
        responsive={true}
        maxColumns={5}
        targetBlockWidth={getTargetBlockWidth()}
      >
        {products.map((product) => (
          product?.advertised ? (
            <XBlock width={2} key={product.id}>
              <ProductCard5 product={product} />
            </XBlock>
          ) : (
            <XBlock key={product.id}>
              <ProductCard5 product={product} />
            </XBlock>
          )
        ))}
      </XMasonry>

      {loading && (
        <div className="d-flex justify-content-center align-items-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}

      <div ref={loadMoreRef}></div>
    </>
  );
}
