import React from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import FeedReaction from "../NewsFeed/FeedReaction";
import UserDisplay from "../UserProfile/UserDisplay";
import { useFetchUserData } from "../hooks/user/useFetchUserData";
import VideoCard from "./VideoCard";
import { useInView } from "react-intersection-observer";
import AddToChat from "../components/AddToChat";
import AddToChat2 from "../components/AddToChat2";

const UserCard2 = ({ post }) => {
  const { userData } = useFetchUserData(post.userId);

  // console.log("The post data is ",post);

  const { ref, inView } = useInView({
    threshold: 0.5,
  });

  console.log("The post data is ", post);

  if (post.type === "video" && userData)
    return (
      <VideoCard
        url={post?.urls?.[0].url}
        post={post}
        userData={userData}
        ref={ref}
        inView={inView}
      />
    );

  return (
    <>
      <Card className="shadow-lg sm-mx-1 m-1">
  
        <UserDisplay userData={userData} post={post} />
        <div className="z-1">
          <button
            style={{ top: "2%", right: "3%" }}
            className="position-absolute border border-0 my-1 rounded-circle bg-mode "
          ></button>

          <button
            style={{ top: "9%", right: "3%" }}
            className="position-absolute border border-0 my-1  rounded-circle bg-mode"
          ></button>
        </div>

        <Link to={post.id}>
   
          <Card.Img
            variant="top"
            src={post?.thumbnailUrl ?? ""}
            style={{
              // height: `${randomNumberInRange(280, 200)}px`,
              objectFit: "cover",
            }}
          />
    
          {/* <Card.Body className="px-3 py-1 ">
            <div className="d-flex align-items-center ">
    

              <div className="mx-2">
                <div className="d-flex align-items-center">
                  <h4
                    className="d-flex text-body m-0 fw-semibold"
                    style={{ fontSize: "12px" }}
                  >
                  
                  </h4>
                </div>
              </div>
            </div>
            <Card.Title
              className="fw-light"
              style={{
                marginBottom: "5px",
                marginTop: "5px",
                fontSize: "10px",
              }}
            >
               {}
            </Card.Title>
         
          </Card.Body> */}
        </Link>
        <div className="card-footer  p-0 ">
        <AddToChat2 post={post}/>
          <FeedReaction post={post} />
        </div>
      </Card>
    </>
  );
};

export default UserCard2;
