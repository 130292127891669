import React from "react";
import AddOrRemoveLike from "./AddOrRemoveLike";
import { UserAuth } from "../Context";
import { Link } from "react-router-dom";
import AddToList from "../components/AddToList";

function ProductReaction({ product, setLikeCount,setFavouritesCount }) {
  const { user } = UserAuth();

  return (
    <>
      {/* Feed react START */}
      <div class="d-flex justify-content-between">
        <Link class=" p-0 m-auto text-body ">
          <AddOrRemoveLike
            user={user}
            product={product}
            setLikeCount={setLikeCount}
          />
          {/* Like */}
        </Link>
        <div class="vr"></div>
        {/* Add to List */}
        <Link class=" text-body p-0 m-auto">
          <AddToList product={product} componentName={"ProductReaction"} setFavouritesCount={setFavouritesCount}/>
        </Link>
      </div>
      {/* Feed react END */}
    </>
  );
}

export default ProductReaction;
