import React, { useState } from "react";
import { Upload, Progress } from "antd";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { db, storage } from "../FirebaseConfig";
import { v4 } from "uuid";
import {
  addDoc,
  collection,
  doc,
  FieldValue,
  increment,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import { UserAuth } from "../Context";
import ProfilePicture from "../UserProfile/ProfilePicture";
import { Link } from "react-router-dom";
import { useFetchUserData } from "../hooks/user/useFetchUserData";
import { toast } from "react-toastify";
import { toastOptions } from "../lib/toastOptions";
import usePointsStore from "../StatesStore/PointsTable";
import { Modal } from "react-bootstrap";
import evaluateText from "../lib/ValidateTextwithGemini";

function ShareFeed() {
  const { user } = UserAuth() || {};
  const [textContent, setTextContent] = useState("");
  const [fileList, setFileList] = useState([]);
  const [show, setShow] = useState(false);
  const [postType, setPostType] = useState("");
  const [videoList, setVideoList] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [showUpload, setShowUpload] = useState(true);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const { userData, loading } = useFetchUserData(user?.uid);
  const pointsTable = usePointsStore((state) => state.pointsTable);

  const handleClose = () => {
    setShow(false);
    setTitle("");
    setDescription("");
  };
  const uploadFiles = async () => {
    handleClose();
    let promises = [];
    let totalFiles = fileList.length + videoList.length;
    let completedFiles = 0;

    const updateProgress = () => {
      completedFiles += 1;
      setUploadProgress((completedFiles / totalFiles) * 100);
    };
    if (
      fileList.length === 0 &&
      videoList.length === 0 &&
      title &&
      description
    ) {
      // Handle text-only post
      createPost([]);
      setTitle("");
      setDescription("");
      return;
    }
    if (fileList.length > 0) {
      const imagePromises = fileList.map((file) => {
        return new Promise((resolve, reject) => {
          const imageRef = ref(
            storage,
            `customPosts/images/${file.name + v4()}`
          );
          const uploadTask = uploadBytesResumable(imageRef, file);

          uploadTask.on(
            "state_changed",
            (snapshot) => {
              const progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              console.log("Upload is " + progress + "% done");
            },
            (error) => {
              console.error("Error uploading image:", error);
              reject(error);
            },
            async () => {
              const imageUrl = await getDownloadURL(uploadTask.snapshot.ref);
              resolve({ type: "image", url: imageUrl });
              updateProgress();
            }
          );
        });
      });
      promises.push(...imagePromises);
    }

    if (videoList.length > 0) {
      const videoPromises = videoList.map((file) => {
        return new Promise((resolve, reject) => {
          const videoRef = ref(
            storage,
            `customPosts/videos/${file.name + v4()}`
          );
          const uploadTask = uploadBytesResumable(videoRef, file);

          uploadTask.on(
            "state_changed",
            (snapshot) => {
              const progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              console.log("Upload is " + progress + "% done");
            },
            (error) => {
              console.error("Error uploading video:", error);
              reject(error);
            },
            async () => {
              const videoUrl = await getDownloadURL(uploadTask.snapshot.ref);
              resolve({ type: "video", url: videoUrl });
              updateProgress();
            }
          );
        });
      });
      promises.push(...videoPromises);
    }

    if (promises.length > 0) {
      try {
        const uploadedFiles = await Promise.all(promises);
        console.log("uploadedFiles", uploadedFiles);
        createPost(uploadedFiles);
        setUploadProgress(0);
      } catch (error) {
        console.error("Error uploading files:", error);
        setUploadProgress(0);
      }
    } else {
      console.log("No files to upload.");
    }
  };

  const createPost = async (mediaDocs) => {
    try {
      const thumbnailUrl = mediaDocs.length > 0 ? mediaDocs[0].url : null;
      const mediaCount = mediaDocs.length;

      const flag = (await evaluateText(description)) || (await evaluateText(title));

      const newPost = {
        by: "user",
        description: description || "",
        dateCreated: serverTimestamp(),
        flagged: flag === 0,
        mediaCount: mediaCount,
        postType: mediaDocs.length > 0 ? mediaDocs[0].type : "textOnly",
        thumbnailUrl: thumbnailUrl || null,
        title: title || "",
        type: mediaDocs.length > 0 ? mediaDocs[0].type : "text",
        userId: user?.uid,
      };
      console.log('Flag is description :: ',  description, await evaluateText(description));
      console.log('Flag is title :: ',  title,  await evaluateText(title));
      const postRef = await addDoc(collection(db, "posts"), newPost);

      const mediaPromises = mediaDocs
        .slice(1)
        .map((mediaDoc) =>
          addDoc(collection(db, "posts", postRef.id, "media"), mediaDoc)
        );

      await Promise.all(mediaPromises);
      console.log("Post created successfully!");
      toast.success(`Post created successfully`, toastOptions);
      setShowUpload(false);
      setTextContent("");
      setFileList([]);
      setVideoList([]);
      // Update the user's document to increment the "posts" field by 1
      const userRef = doc(db, "users", user.uid);
      await updateDoc(userRef, {
        posts: increment(1),
      });
      // Increment points based on the pointsTable value for posting on Lookflock
      const postingPoints = pointsTable.posting || 0; // Default to 0 if not defined
      await updateDoc(userRef, {
        points: increment(postingPoints),
      });

      // Create a log entry in the pointsLog subcollection
      const pointsLogRef = collection(userRef, "pointsLog");
      await addDoc(pointsLogRef, {
        type: "posting",
        pointsAwarded: postingPoints,
        timestamp: serverTimestamp(),
        postId: postRef.id,
      });

      console.log("Points and points log updated successfully!");
    } catch (error) {
      console.error("Error creating post:", error);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  const handleRemove = (file, setFileList) => {
    setFileList((prevFileList) =>
      prevFileList.filter((item) => item.uid !== file.uid)
    );
  };
  console.log("The file List is", fileList);
  return (
    <div style={{ height: "18rem", padding: "4px" }}>
      <div class="card bg-primary text-white">
        <div class="card-body text-center">
          <i class="bi bi-plus-circle-fill display-4"></i>
          <h6 class="card-title text-white">Create your post</h6>
          <p
            class="card-text text-sm font-weight-light"
            style={{ fontSize: "10px" }}
          >
            Share your thoughts...
          </p>
          <div class="d-grid gap-2 ">
            {/* <button class="btn btn-light" type="button">Add Photos/Videos</button>
      <button class="btn btn-light" type="button">Add Shopping List</button> */}

            <Link
              className="btn btn-light "
              onClick={() => {
                setShow(true);
                setPostType("image");
              }}
            >
              {" "}
              <i className="bi bi-image-fill text-success pe-2"></i>
              Add Image
            </Link>

            <Link
              className="btn btn-light "
              onClick={() => {
                setShow(true);
                setPostType("video");
              }}
            >
              {" "}
              <i className="bi bi-camera-reels-fill text-info pe-2"></i>
              Add Video
            </Link>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <h5>Create Image Post</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex mb-3">
            <form className="w-100" onSubmit={(e) => e.preventDefault()}>
              <div className="mb-3">
                <label className="form-label">Title</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Add title here"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  required
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Description</label>
                <textarea
                  className="form-control"
                  rows="3"
                  placeholder="Add description here"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  required
                ></textarea>
              </div>
            </form>
          </div>
          <div>
            <label className="form-label">Upload attachment</label>
            {postType === "image" ? (
              <Upload
                listType="picture"
                accept=".png,.jpg,.jpeg,.webp"
                className="dropzone dropzone-default card shadow-none"
                maxCount={10}
                multiple={true}
                fileList={fileList}
                // showUploadList={fileList.length===0?false:true}
                onRemove={(file) => handleRemove(file, setFileList)}
                beforeUpload={(file) => {
                  setFileList((prevFileList) => [...prevFileList, file]);
                  return false;
                }}
                style={{ backgroundColor: "#333", color: "white" }}
              >
                <div className="dz-message">
                  <i className="bi bi-images display-3"></i>
                  <p>Drag here or click to upload photo.</p>
                </div>
              </Upload>
            ) : (
              <Upload
                listType="picture"
                accept=".mp4,.mkv,.avi"
                className="dropzone dropzone-default card shadow-none"
                maxCount={2}
                multiple={true}
                fileList={videoList}
                onRemove={(file) => handleRemove(file, setVideoList)}
                beforeUpload={(file) => {
                  setVideoList((prevVideoList) => [...prevVideoList, file]);
                  return false;
                }}
              >
                <div className="dz-message">
                  <i className="bi bi-camera-reels display-3"></i>
                  <p>Drag here or click to upload video.</p>
                </div>
              </Upload>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-danger-soft me-2 btn-sm"
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            onClick={uploadFiles}
            type="button"
            className="btn btn-primary-soft btn-sm"
          >
            Post
          </button>
        </Modal.Footer>
      </Modal>

      <div style={{ marginTop: 20 }}>
        {uploadProgress > 0 && (
          <Progress percent={Math.round(uploadProgress)} />
        )}
      </div>
    </div>
  );
}

export default ShareFeed;
