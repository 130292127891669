import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ShareMenu2 from "../components/ShareMenu2";
import AddToList from "../components/AddToList";
import AddOrRemoveLike from "./AddOrRemoveLike";
import { Card, Spinner } from "react-bootstrap";
import "./style.css";
import { UserAuth } from "../Context";
import AddOrRemoveFavourites from "./AddOrRemoveFavourites";
import BrandDisplay from "../NewsFeed/BrandDisplay";
import formatPrice from "../lib/formatPrice";
import { getBrandDetails } from "../lib/getBrandDetails";
import useFetch from "../hooks/useFetch";
import usePost from "../hooks/usePost";
import { CheckCircleFill, PlusCircleFill } from "react-bootstrap-icons";
import { db } from "../FirebaseConfig";
import { doc, getDoc } from "firebase/firestore";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import AddToChat from "../components/AddToChat";

function Skeleton() {
    return (
        <div className="m-1 m-sm-1 skeleton">
          <style jsx>{`
            .custom-tooltip {
              --bs-tooltip-bg: var(--bs-violet-bg);
              --bs-tooltip-color: var(--bs-white);
              color: white;
              max-width: 300px;
              text-align: center;
              font-size: 13px;
            }
    
            .custom-tooltip .btn {
              margin-top: 10px;
            }
          `}</style>
          <Card>
            <div style={{ position: "relative", height: "auto" }}>
           
              <Link to={`/Details/`}>
                <Card.Img
                  className="skeleton"
                  style={{
                    height: `20rem`,
                    objectFit: "cover",
                 
                    transition: "opacity 0.5s ease-in-out",
                  }}
                
                  loading="lazy"
                />
              </Link>
            </div>
    
            {/* Price Tag */}
         
            {/* Plus Button to add  in the list */}
         
            {/* Strip */}
           
          </Card>
    
          <div className="pe-1 pb-1">
            <div className="d-flex align-items-center">
              {/* Logo */}
              <div
                className="avatar me-2"
                style={{ width: "35px", height: "35px" }}
              >
                <Link to={"/BrandHome/" }>
                  <img
                    className="avatar-img rounded skeleton"
                   
                    alt=""
                  />
                </Link>
              </div>
              <div
                className="d-flex flex-column justify-content-start skeleton"
                style={{ width: "78%" }}
              >
                <div className="mb-0">
                  <BrandDisplay
              
                    type={"Card"}
                  />
                </div>
                <div
                  className="fw-bold h6 position-relative skeleton"
                  style={{
                    marginTop: "2px",
                    fontSize: "12px",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                >
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      );
}

export default Skeleton