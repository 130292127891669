import React, { useEffect, useState } from "react";
import {
  Col,
  Button,
  Card,
  Form,
  InputGroup,
  Nav,
  Badge,
} from "react-bootstrap";
import ProfilePicture from "../UserProfile/ProfilePicture";
import { decryptChat } from "../ShoppingList/Encryption";

function SideBar({ chats, selectedChat, setSelectedChat }) {
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (!chats) return;
    console.log("chats", chats);
    setLoading(false);
  }, [chats]);

  if (loading) {
    return (
      <Col lg={4} xxl={3} id="chatTabs" role="tablist">
        Loading...
      </Col>
    );
  }
  return (
    <Col lg={4} xxl={3} id="chatTabs" role="tablist">
      {/* Divider */}
      <div className="d-flex align-items-center mb-4 d-lg-none">
        <Button
          variant="primary"
          className="border-0 bg-transparent ps-0"
          data-bs-toggle="collapse"
          data-bs-target="#sidebarContent"
          aria-expanded="false"
          aria-controls="sidebarContent"
        >
          <span class="btn btn-primary">
            <i class="fa-solid fa-sliders-h"></i>
          </span>
          <span className="h6 mb-0 fw-bold d-lg-none ms-2">Chats</span>
        </Button>
      </div>
      {/* Advanced filter responsive toggler END */}

      <Card className="card-body border-end-0 border-bottom-0 rounded-bottom-0">
        <div className="d-flex justify-content-between align-items-center">
          <h1 className="h5 mb-0">
            Active chats{" "}
            <Badge bg="success" className="bg-opacity-10 text-success">
              {chats.length || 0}
            </Badge>
          </h1>
          {/* Chat new create message item START */}
          <div className="dropend position-relative">
            <Nav>
              <Button
                variant="primary"
                className="icon-md rounded-circle btn-sm btn-primary-soft nav-link"
                // href="/#/chat"
              >
                <i className="bi bi-pencil-square"></i>
              </Button>
            </Nav>
          </div>
          {/* Chat new create message item END */}
        </div>
      </Card>

      <div id="sidebarContent" className="collapse show">
        <div className="p-0">
          <Card className="card-chat-list rounded-end-lg-0 card-body border-end-lg-0 rounded-top-0">
            {/* Search chat START */}
            <Form className="position-relative">
              <InputGroup>
                <Form.Control
                  type="search"
                  placeholder="Search for chats"
                  aria-label="Search"
                  className="py-2"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
                <Button variant="transparent" type="submit">
                  <i className="bi bi-search fs-5"></i>
                </Button>
              </InputGroup>
            </Form>
            {/* Search chat END */}

            {/* Chat list tab START */}
            <div className="mt-4 h-100">
              <Nav className="flex-column nav-pills nav-pills-soft">
                {/* Chat user tab item */}
                {chats.map((chat) => {
                  if (
                    chat?.friendsData?.some((friend) =>
                      friend.userName
                        .toLowerCase()
                        .includes(search.toLowerCase())
                    ) ||
                    chat?.listName?.toLowerCase().includes(search.toLowerCase())
                  )
                    if (chat.type === "private")
                      return (
                        <Nav.Link
                          active={selectedChat === chat?.id}
                          className="text-start ps-2"
                          key={chat?.id}
                          onClick={() => setSelectedChat(chat?.id)}
                          style={{
                            maxWidth: "105%",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                          }}
                        >
                          <div className="d-flex">
                            <div className="flex-shrink-0 avatar me-2">
                              <ProfilePicture userData={chat.friendsData[0]} />
                            </div>
                            <div className="flex-grow-1 d-block">
                              <h6 className="mb-0 mt-1">
                                {chat.friendsData[0].userName
                                  .split(" ")
                                  .slice(0, 2)
                                  .join(" ")}
                              </h6>
                              <div
                                className="small text-secondary"
                                style={{
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {decryptChat(chat?.lastMessage?.content)}
                              </div>
                            </div>
                          </div>
                        </Nav.Link>
                      );
                    else if (chat.type === "list")
                      return (
                        <Nav.Link
                          active={selectedChat === chat?.id}
                          className="text-start ps-2"
                          key={chat?.id}
                          onClick={() => setSelectedChat(chat?.id)}
                          style={{
                            maxWidth: "105%",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                          }}
                        >
                          <div className="d-flex">
                            <div className="flex-shrink-0 avatar me-2">
                              {chat.friendsData.length === 1 ? (
                                <div className="flex-shrink-0 avatar me-2">
                                  <ProfilePicture
                                    userData={chat.friendsData[0]}
                                  />
                                </div>
                              ) : (
                                <ul
                                  className={`avatar-group avatar-group-${
                                    chat.friendsData.length === 2
                                      ? "two"
                                      : "three"
                                  }`}
                                >
                                  {chat.friendsData.map((friend) => {
                                    return (
                                      <li className="avatar avatar-xs">
                                        <ProfilePicture userData={friend} />
                                      </li>
                                    );
                                  })}
                                </ul>
                              )}
                            </div>
                            <div>
                              <h6 className="mb-0 mt-1">{chat?.listName}</h6>
                              <div className="small text-secondary">
                                {decryptChat(chat?.lastMessage?.content)}
                              </div>
                            </div>
                          </div>
                        </Nav.Link>
                      );
                })}

                {/* Add additional chat items as needed */}
              </Nav>
            </div>
            {/* Chat list tab END */}
          </Card>
        </div>
      </div>
    </Col>
  );
}

export default SideBar;
