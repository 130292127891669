import React, { useEffect } from 'react';
import { Adsense } from '@ctrl/react-adsense';


const GoogleAdCard2 = () => {

  return (
    <Adsense
    style={{ width: "250px", height: "250px", display: 'block',marginBottom:"4px"}}
        client="ca-pub-6917679702799024"
        slot="5663879540"
          format="fluid"
          layout="+s+rx+1+2-3"
    
    />
)

};

export default GoogleAdCard2;
