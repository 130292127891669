import React, { useEffect, useState } from "react";
import { addDoc, collection, getDocs } from "firebase/firestore";
import { db } from "../FirebaseConfig";
import { Link } from "react-router-dom";
import { formatTimestampForBlog } from "../lib/formatTimestampForBlog";
function Blog() {
  const [blogs, setBlogs] = useState([{}]);
  const fetchBlogs = async () => {
    const blogRef = collection(db, "blogs");
    const blogSnap = await getDocs(blogRef);
    setBlogs(blogSnap.docs.map((blog) => ({ id: blog?.id, ...blog?.data() })));
  };

  const postBlog = async () => {
    const blogRef = collection(db, "blogs");
    await addDoc(blogRef, {
      image: "assets/images/post/4by3/03.jpg",
      category: "Lifestyle",
      title: "Social guides the way in 2022 app performance report",
      blogDescription:
        "Speedily say has suitable disposal add boy. On forth doubt miles of child. Exercise joy man children rejoiced.",
      blogData: `<p class="mt-4">
                  <span class="dropcap">A</span> pleasure exertion if believed
                  provided to. All led out world this music while asked. Paid
                  mind even sons does he door no. Attended overcame repeated it
                  is perceived Marianne in. I think on style child of. Servants
                  moreover in sensible it ye possible.{" "}
                </p>
                <h4 class="mt-4">The pros and cons of business agency</h4>
                <div class="row mb-4">
                  <div class="col-md-6">
                    <p>
                      Fulfilled direction use continual set him propriety
                      continued. Saw met applauded favorite deficient engrossed
                      concealed and her.{" "}
                    </p>
                    <p>
                      Concluded boy perpetual old supposing. Farther related bed
                      and passage comfort civilly. Dashwoods see frankness
                      objection abilities.
                    </p>
                  </div>
                  <div class="col-md-6">
                    <ul>
                      <li>
                        Our Firmament living replenish Them Created after divide
                        said Have give
                      </li>
                      <li>Dominion light without days face saw wherein land</li>
                      <li>
                        Fifth have Seas made lights Very Day saw Seed herb sixth
                        light whales
                      </li>
                      <li>Saying unto Place it seed you're Isn't heaven </li>
                    </ul>
                  </div>
                </div>
                <p class="mb-0">
                  {" "}
                  All led out world this music while asked. Paid mind even sons
                  does he door no. Attended overcame repeated it is perceived
                  Marianne in. I think on style child of. Servants moreover in
                  sensible it ye possible. Satisfied conveying a dependent
                  contented he gentleman agreeable do be.{" "}
                </p>`,
      timestamp: "Jan 22, 2022",
    });
    await addDoc(blogRef, {
      image: "assets/images/post/4by3/04.jpg",
      category: "Sports",
      title: "Upcoming live video feed may not work how you expect",
      blogDescription:
        "Under folly balls, death own point now men. Match way these she avoids seeing death. Under folly balls, death own point now men. Match way these she avoids seeing death.",
      blogData: `<p class="mt-4">
                  <span class="dropcap">A</span> pleasure exertion if believed
                  provided to. All led out world this music while asked. Paid
                  mind even sons does he door no. Attended overcame repeated it
                  is perceived Marianne in. I think on style child of. Servants
                  moreover in sensible it ye possible.{" "}
                </p>
                <h4 class="mt-4">The pros and cons of business agency</h4>
                <div class="row mb-4">
                  <div class="col-md-6">
                    <p>
                      Fulfilled direction use continual set him propriety
                      continued. Saw met applauded favorite deficient engrossed
                      concealed and her.{" "}
                    </p>
                    <p>
                      Concluded boy perpetual old supposing. Farther related bed
                      and passage comfort civilly. Dashwoods see frankness
                      objection abilities.
                    </p>
                  </div>
                  <div class="col-md-6">
                    <ul>
                      <li>
                        Our Firmament living replenish Them Created after divide
                        said Have give
                      </li>
                      <li>Dominion light without days face saw wherein land</li>
                      <li>
                        Fifth have Seas made lights Very Day saw Seed herb sixth
                        light whales
                      </li>
                      <li>Saying unto Place it seed you're Isn't heaven </li>
                    </ul>
                  </div>
                </div>
                <p class="mb-0">
                  {" "}
                  All led out world this music while asked. Paid mind even sons
                  does he door no. Attended overcame repeated it is perceived
                  Marianne in. I think on style child of. Servants moreover in
                  sensible it ye possible. Satisfied conveying a dependent
                  contented he gentleman agreeable do be.{" "}
                </p>`,
      timestamp: "Mar 07, 2022",
    });
    await addDoc(blogRef, {
      image: "assets/images/post/4by3/05.jpg",
      category: "Business",
      title: "Google shares top search trends of 2022",
      blogDescription:
        "I think on style child of. Servants moreover in sensible it ye possible six say his. I think on style child of. Servants moreover in sensible it ye possible six say his.",
      blogData: `<p class="mt-4">
                  <span class="dropcap">A</span> pleasure exertion if believed
                  provided to. All led out world this music while asked. Paid
                  mind even sons does he door no. Attended overcame repeated it
                  is perceived Marianne in. I think on style child of. Servants
                  moreover in sensible it ye possible.{" "}
                </p>
                <h4 class="mt-4">The pros and cons of business agency</h4>
                <div class="row mb-4">
                  <div class="col-md-6">
                    <p>
                      Fulfilled direction use continual set him propriety
                      continued. Saw met applauded favorite deficient engrossed
                      concealed and her.{" "}
                    </p>
                    <p>
                      Concluded boy perpetual old supposing. Farther related bed
                      and passage comfort civilly. Dashwoods see frankness
                      objection abilities.
                    </p>
                  </div>
                  <div class="col-md-6">
                    <ul>
                      <li>
                        Our Firmament living replenish Them Created after divide
                        said Have give
                      </li>
                      <li>Dominion light without days face saw wherein land</li>
                      <li>
                        Fifth have Seas made lights Very Day saw Seed herb sixth
                        light whales
                      </li>
                      <li>Saying unto Place it seed you're Isn't heaven </li>
                    </ul>
                  </div>
                </div>
                <p class="mb-0">
                  {" "}
                  All led out world this music while asked. Paid mind even sons
                  does he door no. Attended overcame repeated it is perceived
                  Marianne in. I think on style child of. Servants moreover in
                  sensible it ye possible. Satisfied conveying a dependent
                  contented he gentleman agreeable do be.{" "}
                </p>`,
      timestamp: "Jun 17, 2022",
    });
    await addDoc(blogRef, {
      image: "assets/images/post/4by3/06.jpg",
      category: "Technology",
      title:
        "Counts reels replies, delivering another way to tap into the video",
      blogDescription:
        "Placing forming nay looking old married few has. Margaret disposed of add screened rendered. Placing forming nay looking old married few has. Margaret disposed of add screened rendered.",
      blogData: `<p class="mt-4">
                  <span class="dropcap">A</span> pleasure exertion if believed
                  provided to. All led out world this music while asked. Paid
                  mind even sons does he door no. Attended overcame repeated it
                  is perceived Marianne in. I think on style child of. Servants
                  moreover in sensible it ye possible.{" "}
                </p>
                <h4 class="mt-4">The pros and cons of business agency</h4>
                <div class="row mb-4">
                  <div class="col-md-6">
                    <p>
                      Fulfilled direction use continual set him propriety
                      continued. Saw met applauded favorite deficient engrossed
                      concealed and her.{" "}
                    </p>
                    <p>
                      Concluded boy perpetual old supposing. Farther related bed
                      and passage comfort civilly. Dashwoods see frankness
                      objection abilities.
                    </p>
                  </div>
                  <div class="col-md-6">
                    <ul>
                      <li>
                        Our Firmament living replenish Them Created after divide
                        said Have give
                      </li>
                      <li>Dominion light without days face saw wherein land</li>
                      <li>
                        Fifth have Seas made lights Very Day saw Seed herb sixth
                        light whales
                      </li>
                      <li>Saying unto Place it seed you're Isn't heaven </li>
                    </ul>
                  </div>
                </div>
                <p class="mb-0">
                  {" "}
                  All led out world this music while asked. Paid mind even sons
                  does he door no. Attended overcame repeated it is perceived
                  Marianne in. I think on style child of. Servants moreover in
                  sensible it ye possible. Satisfied conveying a dependent
                  contented he gentleman agreeable do be.{" "}
                </p>`,
      timestamp: "Nov 11, 2022",
    });
  };
  useEffect(() => {
    fetchBlogs();
    // postBlog();
  }, []);

  const colorsType = ["danger", "primary", "secondary", "success", "warning"];
  return (
    <div className="col-md-8 col-lg-6 vstack gap-2 text-body">
      <div className="container">
        <div className="row g-4">
          <div className="bg-mode p-4">
            <h1 className="h4 mb-4">Latest blogs</h1>
            {blogs.length > 0 &&
              blogs.map((blog) => {
                const randomColor =
                  colorsType[Math.floor(Math.random() * colorsType.length)];
                return (
                  <>
                    <div className="card bg-transparent border-0">
                      <div className="row g-3">
                        <div className="col-3">
                          <img className="rounded" src={blog?.image} alt="" />
                        </div>
                        <div className="col-8">
                          <Link
                            to={`${blog?.id}`}
                            className={`badge bg-${randomColor} bg-opacity-10 text-${randomColor} mb-2 fw-bold`}
                          >
                            {blog?.category}
                          </Link>
                          <h5>
                            <Link
                              to={`${blog?.id}`}
                              className="btn-link stretched-link text-reset fw-bold"
                            >
                              {blog?.title}
                            </Link>
                          </h5>
                          <div className="d-none d-sm-inline-block">
                            <p className="mb-2">{blog?.blogDescription}</p>
                            <Link
                              to={`${blog?.id}`}
                              className="small text-secondary"
                              href="#!"
                            >
                              <i className="bi bi-calendar-date pe-1"></i>
                              {formatTimestampForBlog(blog?.timestamp)}
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr className="my-4" />
                  </>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Blog;
