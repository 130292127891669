
import { useState, useEffect } from 'react';
import { getDoc, doc } from 'firebase/firestore';
import { db } from '../../FirebaseConfig';


export const useFetchBrandData = (brandName) => {
 const [BrandData, setBrandData] = useState(null);
 const [loading, setLoading] = useState(true);

 useEffect(() => {
    const fetchBrandData = async () => {
      if (!brandName) return;
      try {
        const BrandDocRef = doc(db, "brands", brandName);
        const BrandDocSnapshot = await getDoc(BrandDocRef);
        if (BrandDocSnapshot.exists()) {
          setBrandData({
            ...BrandDocSnapshot.data(),
          });
        } else {
          console.log("Brand document does not exist.");
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching Brand data:", error.message);
      }finally{
        setLoading(false);
      }
    };
    fetchBrandData();
 }, [brandName]);

 return { BrandData, loading };
};
