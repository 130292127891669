import React, { useEffect, useState } from "react";
import {
  collection,
  doc,
  limit,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import { db } from "../FirebaseConfig";
import { Link } from "react-router-dom";

import {
  JoinedUser,
  PostLike,
  FollowedMe,
  ShoppingListChatMessage,
  FirstTimeListView,
  ListProductLike,
  FriendRequest,
  AcceptFriendRequest,
  InviteAccepted,
  ShoppingListInvite,

} from "./NotificationTypes";
import { GroupedNotification } from "./GroupNotification";
import useNotificationStore from "../StatesStore/Notifications";
import ProductPriceChange from "./ProductPriceChange";
import BrandHandlerInvite from "./BrandHandlerInvite"
import PostComment from "./PostComment";
import { Dropdown } from "react-bootstrap";

const notificationComponents = {
  joined: JoinedUser,
  inviteAccepted: InviteAccepted,
  postLike: PostLike,
  postComment: PostComment,
  followed: FollowedMe,
  listChat: ShoppingListChatMessage,
  firstTimeListView: FirstTimeListView,
  listProductLike: ListProductLike,
  inviteOnList: ShoppingListInvite,
  friendRequest: FriendRequest,
  brandHandlerInvite :  BrandHandlerInvite ,
  acceptFriendRequest: AcceptFriendRequest,
  productPriceChange: ProductPriceChange,
};

function Notification({ user, userData }) {
  const [notifications, setNotifications] = useState([]);
  const [lastSeenNotification, setLastSeenNotification] = useState(
    userData?.lastSeenNotification?.toDate() || null
  );
  const [newNotificationsCount, setNewNotificationsCount] = useState(0);
  const addNotifications = useNotificationStore(
    (state) => state.addNotifications
  );

  useEffect(() => {
    if (!user) return;

    const notificationsRef = collection(db, `users/${user.uid}/notifications`);
    const q = query(notificationsRef, orderBy("timestamp", "desc"), limit(5));

    const unsubscribe = onSnapshot(
      q,
      (querySnapshot) => {
        const notificationData = querySnapshot.docs.map((docSnapshot) => ({
          notificationId: docSnapshot.id,
          ...docSnapshot.data(),
        }));
        setNotifications(notificationData);
        addNotifications(notificationData);

        // Count new notifications based on UTC comparison
        const newCount = notificationData.filter((notification) => {
          const notificationTimestamp = notification.timestamp
            .toDate()
            .getTime(); // Convert to millis
          const lastSeenMillis = lastSeenNotification
            ? lastSeenNotification.getTime()
            : null; // Convert to millis
          console.log("lastSeenMillis", lastSeenMillis);

          return !lastSeenMillis || notificationTimestamp > lastSeenMillis; // Compare in UTC
        }).length;

        setNewNotificationsCount(newCount);
      },
      (error) => {
        console.error("Error fetching notifications in real-time: ", error);
      }
    );

    return () => unsubscribe();
  }, [user]);

  const handleNotificationClick = async () => {
    if (newNotificationsCount > 0) {
      const userDocRef = doc(db, "users", user.uid);
      await updateDoc(userDocRef, {
        lastSeenNotification: serverTimestamp(), // Update with the server timestamp
      });
      setLastSeenNotification(new Date()); // Update in local state as well
      setTimeout(() => {
        setNewNotificationsCount(0); // Reset the new notifications count
      }, 2000);
    }
  };

  const renderNotification = (notification) => {
    if (Array.isArray(notification.id) && notification.id.length > 1) {
      return (
        <GroupedNotification
          key={notification.notificationId}
          notification={notification}
          groupedNotifications={notification}
          user={user}
        />
      );
    }

    const NotificationComponent = notificationComponents[notification.type];
    if (!NotificationComponent) return null;
    return (
      <NotificationComponent
        key={notification.notificationId}
        notification={notification}
        user={user}
      />
    );
  };

  return (
    <li className="nav-item ms-2 dropdown custom-dropdown">
      <style jsx>
        {`
          .custom-dropdown .dropdown-toggle:after {
            display: none;
          }
          .custom-dropdown .dropdown-menu {
            --bs-dropdown-link-hover-color: none;
            --bs-dropdown-link-active-color: none;
          }
        `}
      </style>

      <Dropdown align="end" autoClose onToggle={handleNotificationClick}>
        <Dropdown.Toggle
          variant="light"
          className="nav-link icon-md btn btn-light p-0"
        >
          {newNotificationsCount > 0 && (
            <span className="badge-notif animation-blink"></span>
          )}
          <i className="bi bi-bell-fill fs-6"></i>
        </Dropdown.Toggle>

        <Dropdown.Menu className="dropdown-animation dropdown-menu-size-md p-0 shadow-lg border-0 w-50">
          <Dropdown.Item as="div" className="px-0">
            <div
              className={`card-header d-flex justify-content-between align-items-center px-3 pt-2 pb-0 ${
                notifications.length < 4 ? "no-border-bottom" : ""
              }`}
            >
              <h6 className="m-0">
                Notifications{" "}
                <span className="badge bg-danger bg-opacity-10 text-danger ms-2">
                  {newNotificationsCount} new
                </span>
              </h6>
            </div>
            <div className="card-body p-0">
              {notifications.length > 0 && (
                <ul
                  className="list-group list-group-flush list-unstyled p-2 text-body-hover"
                  style={{
                    overflow: "hidden",
                    whiteSpace: "collapse",
                  }}
                >
                  {notifications.map(renderNotification)}
                </ul>
              )}
            </div>
            {notifications.length < 4 && <div className="noti-footer"></div>}
            {notifications.length > 4 && (
              <div className="card-footer text-center">
                <Link
                  to="/notifications"
                  className="btn btn-sm btn-primary-soft"
                >
                  See all notifications
                </Link>
              </div>
            )}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </li>
  );
}

export default Notification;
