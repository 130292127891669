import { useState } from "react";
import usePost from "../usePost";


const useLogActivity = (userId) => {
  const [error, setError] = useState(null);

  // Set up the API URL with the user ID
  const url = `${process.env.REACT_APP_API}/api/activity/${userId}`;
  const { data, post } = usePost(url);

  // Function to log activity
  const logActivity = async (activityData) => {
    if (!userId) {
      console.error("User ID is required to log activity.");
      return;
    }
    try {
      await post(activityData); // Using the post function from usePost
    } catch (err) {
      console.error("Failed to log activity", err);
      setError(err.message);
    }
  };

  return { logActivity, error };
};

export default useLogActivity;
