import React, { useState, useEffect } from "react";
import { Link, Route, Routes, useLocation } from "react-router-dom";
import uuid4 from "uuid4";
import { toast } from "react-toastify";
import {
  getDoc,
  doc,
  setDoc,
  onSnapshot,
  collection,
  getDocs,
  serverTimestamp,
  where,
  query,
} from "firebase/firestore";
import SideNavUserProfile from "./SideNav/SideNavUserProfile";
import { db } from "./FirebaseConfig";
import { toastOptions } from "./lib/toastOptions";
import { UserAuth } from "./Context";
import { Modal, Form, Button } from "react-bootstrap";
import MyList from "./ShoppingList/MyList";
import InviteLists from "./ShoppingList/InviteLists";
const ShoppingList = () => {
  // My Shopping list Data
  const [shoppingListData, setShoppingListData] = useState([]);
  // Invited ShoppingList Data
  const [invitedshoppingListData, setInvitedShoppingListData] = useState([]);
  // Current Selected Category
  const [selectedListCategory, setSelectedListCategory] = useState(
    "MyShoppingList"
  );

  // Two categories: true for MyShoppingList, false for Invited Shopping List
  const checkMyShoppingList = selectedListCategory === "MyShoppingList";
  // Used when createing new list
  const [newListName, setNewListName] = useState("");
  // Used to separate the invited list by listId and userId
  // const [invitedList, setInvitedList] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const { user } = UserAuth();
  const location = useLocation();
console.log("user auth",user);

  useEffect(() => {
    if (location.pathname === "/shoppingLists/inviteLists") {
      setSelectedListCategory("InvitedShoppingList");
    } else {
      setSelectedListCategory("MyShoppingList");
    }
  }, [location]);

  useEffect(() => {
    const fetchShoppingLists = async () => {
      if (!user) return;
      const userRef = doc(db, "users", user.uid);
      const shoppingListRef = collection(userRef, "shoppingList");

      const unsubscribe = onSnapshot(userRef, async () => {
        const shoppingListDocs = await getDocs(shoppingListRef);
        const shoppingListData = shoppingListDocs.docs.map(async (document) => {
          const shoppingList = document.data();
          const dateCreated =
            shoppingList.dateCreated?.seconds || new Date().getTime() / 1000;

          // Fetch products from the subcollection
          const productRef = collection(
            shoppingListRef,
            document.id,
            "products"
          );
          const productDocs = await getDocs(productRef);

          const products = productDocs.docs.map((productDoc) => {
            const productData = productDoc.data();
            return {
              id: productDoc.id,
              ...productData,
            };
          });

          return {
            name: document.id,
            dateCreated,
            products,
            listId: shoppingList.listId,
            sizes: shoppingList.sizes,
          };
        });

        const shoppingListDataArray = await Promise.all(shoppingListData);
        // shoppingListDataArray.sort((a, b) => a.dateCreated - b.dateCreated);
        shoppingListDataArray.sort(
          (a, b) =>
            b.products.length - a.products.length ||
            b.dateCreated - a.dateCreated
        );
        setShoppingListData(shoppingListDataArray);
      });

      return unsubscribe;
    };

    const fetchInvitedShoppingLists = async () => {
      if (!user) return;

      const invitedListRef = collection(db, "users", user.uid, "invitedList");
      const invitedListDocs = await getDocs(invitedListRef);
      const invitedLists = invitedListDocs.docs.map((doc) => {
        return {
          listId: doc.id,
          userId: doc.data().userId,
          role: doc.data().role,
        };
      });
      // setInvitedList(invitedLists);

      const shoppingLists = await Promise.all(
        invitedLists.map(async ({ listId, userId, role }) => {
          const shoppingListRef = collection(
            db,
            "users",
            userId,
            "shoppingList"
          );
          const q = query(shoppingListRef, where("listId", "==", listId));
          const querySnapshot = await getDocs(q);

          if (querySnapshot.empty) {
            console.log(`No shopping list found for listId: ${listId}`);
            return null;
          }

          const shoppingListSnapshot = querySnapshot.docs[0];
          const shoppingListData = shoppingListSnapshot.data();

          // Fetch products from the subcollection
          const productRef = collection(
            shoppingListRef,
            shoppingListSnapshot.id,
            "products"
          );
          const productDocs = await getDocs(productRef);

          const products = productDocs.docs.map((productDoc) => {
            const productData = productDoc.data();
            return {
              id: productDoc.id,
              ...productData,
            };
          });

          return {
            name: shoppingListSnapshot.id,
            dateCreated:
              shoppingListData.dateCreated?.seconds ||
              new Date().getTime() / 1000,
            products,
            listId: shoppingListData.listId,
            sizes: shoppingListData.sizes,
            userId: userId,
            role: role,
          };
        })
      );

      const filteredShoppingLists = shoppingLists.filter(
        (list) => list !== null
      );

      filteredShoppingLists.sort((a, b) => a.dateCreated - b.dateCreated);
      filteredShoppingLists.map(async (list) => {
        const userRef = doc(db, "users", list?.userId);
        const userData = await getDoc(userRef);
        const userCompleteData = userData?.data();
        list.userName =
          userCompleteData?.firstName + " " + userCompleteData?.lastName;
        list.photo = userCompleteData?.photo;
      });
      setInvitedShoppingListData(filteredShoppingLists);
    };

    window.scrollTo(0, 0);
    fetchShoppingLists();
    fetchInvitedShoppingLists();
  }, [user]);

  const handleCreateNewList = async () => {
    const listName = newListName.trim();

    if (listName) {
      const capitalizedListName =
        listName.charAt(0).toUpperCase() + listName.slice(1);

      await addToSavedList(capitalizedListName);
      const newListId = uuid4();

      setShoppingListData([
        ...shoppingListData,
        {
          name: capitalizedListName,
          dateCreated: new Date(),
          products: [],
          listId: newListId,
          visibility: "Private",
        },
      ]);

      toast.success(
        ` ${capitalizedListName} Shopping List has been created`,
        toastOptions
      );
      setNewListName("");
      handleClose();
    }
  };

  const addToSavedList = async (listName) => {
    if (!user) return;

    try {
      const shoppingListRef = collection(db, "users", user.uid, "shoppingList");
      const newListId = listName;

      const existingListDoc = await getDoc(doc(shoppingListRef, newListId));
      if (!existingListDoc.exists()) {
        await setDoc(doc(shoppingListRef, newListId), {
          dateCreated: serverTimestamp(),
          listId: uuid4(),
          visibility: "Private",
        });
      }
      console.log("New shopping list added successfully!");
    } catch (error) {
      console.error("Error creating New shopping list:", error.message);
    }
  };
  return (
    <>
      <SideNavUserProfile />
      <div
        className="col-md-8 col-lg-6 vstack gap-3 ps-sm-0 text-body"
        // style={{ paddingLeft: "2px" }}
      >
        <div className="card p-3 mb-2 pb-0 border border-0 rounded-2">
          {/* CARD HEADER */}
          <div className="container card-header bg-transparent d-flex text-center align-items-md-center justify-content-between border-0 p-0 mx-1 my-0">
            <ul className="nav nav-bottom-line d-sm-flex align-items-center justify-content-start border-bottom-0 w-75">
              {/* <div className="nav-item d-flex mb-sm-0 mb-2"> */}
              <li className="h5 nav-item mb-0">
                <Link
                  className={`nav-link py-3 pe-2 ${
                    checkMyShoppingList ? "active" : ""
                  }`}
                  onClick={() => {
                    setSelectedListCategory("MyShoppingList");
                  }}
                  to="/shoppingLists/"
                >
                  My Lists
                </Link>
              </li>
              <li className="h5 nav-item ">
                <Link
                  className={`nav-link py-3 px-2 ${
                    !checkMyShoppingList ? "active" : ""
                  }`}
                  onClick={() => {
                    setSelectedListCategory("InvitedShoppingList");
                  }}
                  to="/shoppingLists/inviteLists"
                >
                  <span>Invited Lists</span>
                </Link>
              </li>
              {/* </div> */}
            </ul>
            {checkMyShoppingList ? (
              <Link
                onClick={() => setShow(true)}
                className="h6 btn btn-sm btn-primary-soft mb-3"
              >
                <div className="d-block d-sm-none pt-1">
                  <i className="fa-solid fa-plus"></i>
                </div>
                <span className="d-none d-sm-block">
                  <i className="fa-solid fa-plus pe-1"></i> Create list
                </span>
              </Link>
            ) : (
              <div className="my-3 py-1"></div>
            )}
          </div>
          {/* Nav Strip under Header */}
        </div>
        <Routes>
          <Route
            path="/"
            element={
              <MyList
                shoppingListData={shoppingListData}
                setShoppingListData={setShoppingListData}
              />
            }
          />
          <Route
            path="/inviteLists"
            element={
              <InviteLists
                invitedshoppingListData={invitedshoppingListData}
                // invitedList={invitedList}
              />
            }
          />
        </Routes>
      </div>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title id="modalLabelCreateEvents">Create new list</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form
            className="d-flex"
            onSubmit={(e) => {
              e.preventDefault();
              handleCreateNewList();
            }}
          >
            <div className="p-0 w-75 me-1">
              <Form.Control
                type="text"
                placeholder="Shopping list name"
                value={newListName}
                onChange={(e) => setNewListName(e.target.value)}
              />
            </div>
            <div className="p-0 w-25">
              <Button
                variant="primary-soft"
                type="submit"
                className="container"
                data-bs-dismiss="modal"
              >
                <span className="d-none d-sm-block">Create now</span>
                <span className="d-sm-none">Create</span>
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ShoppingList;
