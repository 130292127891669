import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ShareMenu2 from "../components/ShareMenu2";
import AddToList from "../components/AddToList";
import AddOrRemoveLike from "./AddOrRemoveLike";
import { Card, Spinner } from "react-bootstrap";
import "./style.css";
import { UserAuth } from "../Context";
import AddOrRemoveFavourites from "./AddOrRemoveFavourites";
import BrandDisplay from "../NewsFeed/BrandDisplay";
import formatPrice from "../lib/formatPrice";
import { getBrandDetails } from "../lib/getBrandDetails";
import useFetch from "../hooks/useFetch";
import usePost from "../hooks/usePost";
import { CheckCircleFill, PlusCircleFill } from "react-bootstrap-icons";
import { db } from "../FirebaseConfig";
import { doc, getDoc } from "firebase/firestore";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import AddToChat from "../components/AddToChat";

const ProductCard5 = ({ product, isStrip, addButtonData }) => {
  const [likeCount, setLikeCount] = useState(product?.likes);
  const [favouritesCount, setFavouritesCount] = useState(product?.favourites);
  const [brandData, setBrandData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isInList, setIsInList] = useState(false);
  const [showToolTipLike, setShowToolTipLike] = useState(false);
  const [showToolTipFavorites, setShowToolTipFavorites] = useState(false);
  const [showToolTipShare, setShowToolTipShare] = useState(false);
  const [showToolTipAddToList, setShowToolTipAddToList] = useState(false);
  const handleDismiss = () => {
    setShowToolTipLike(false);
    setShowToolTipFavorites(false);
    setShowToolTipShare(false);
    setShowToolTipAddToList(false);
  };

  // const activeBrands = useActiveBrandsStore((state) => state.activeBrands);
  const { user } = UserAuth();
  const { data: activeBrands } = useFetch(
    `${process.env.REACT_APP_API}/api/activeBrands`
  );
  let url = process.env.REACT_APP_API + `/api/activity/${user?.uid}`;

  // let url = process.env.REACT_APP_API+`/api/logs/${user?.uid}`;
  const { data: postData, post } = usePost(url);

  const fetchBrandData = async () => {
    try {
      // Fetch the brand's display name and logo using the getDisplayName function
      const result = await getBrandDetails(product?.supplier, activeBrands);
      setBrandData(result);
      console.log("result",result);
      
    } catch (error) {
      console.error("Error getting brand data:", error);
    }
  };

  const checkProductInList = async () => {
    const userRef = doc(
      db,
      "users",
      addButtonData.userId,
      "shoppingList",
      addButtonData.selectedShopList,
      "products",
      product?.id
    );
    const userSnap = await getDoc(userRef);
    if (userSnap.exists()) {
      setIsInList(true);
    }
  };

  useEffect(() => {
    if (!product || !activeBrands) return;
    fetchBrandData();
  }, [product, activeBrands]);

  useEffect(() => {
    if (!product || !addButtonData) return;
    checkProductInList();
  }, [product, addButtonData]);

  const handleImageLoad = () => {
    setLoading(false);
  };

  return (
    <div className="m-1 m-sm-1">
    
      <Card>
        <div style={{ position: "relative", height: "auto" }}>
          {loading && (
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "100%",
                backgroundColor: "#eef0f2", // background for the placeholder
              }}
            >
              {/* Placeholder: Can be an image, a spinner, or any other component */}
              {/* <Spinner
                animation="border"
                style={{ color: "grey", width: "20px", height: "20px" }}
              /> */}
            </div>
          )}
          <Link to={`/ProductDetails/${product?.id}`}>
            <Card.Img
              src={product?.imageUrl}
              style={{
                height: `auto`,
                objectFit: "cover",
                opacity: loading ? 0 : 1, // Hide the image until it's fully loaded
                transition: "opacity 0.5s ease-in-out",
              }}
              onLoad={handleImageLoad}
              loading="lazy"
            />
          </Link>
        </div>

        {/* Price Tag */}
        <div
          className="position-absolute rounded-pill top-0 start-0 m-2"
          style={{
            fontSize: "10px",
            backgroundColor: "rgba(0, 0, 0, 0.6)",
          }}
        >
          {product?.newPrice && (
            <p className="m-0 text-white px-2 py-1">
              PKR{" "}
              {product?.newPrice
                ? formatPrice(product.newPrice)
                : formatPrice(product.oldPrice)}
            </p>
          )}
        </div>

        {/* Plus Button to add product in the list */}
        {/* {addButtonData && (
          <div
            className="position-absolute rounded-circle top-0 end-0 m-2"
            style={{
              fontSize: "10px",
              cursor: "pointer",
            }}
            onClick={() => {
              if (!isInList) {
                addButtonData.handleAddProductToList(product?.id, product);
                setIsInList(true);
              }
            }}
          >
            {isInList ? (
              <CheckCircleFill className="fs-4 text-body" />
            ) : (
              <PlusCircleFill className="fs-4 text-body" />
            )}
          </div>
        )} */}
        {/* Strip */}
        {(isStrip === undefined || isStrip === true) && (
          <div
            className="d-inline-flex flex-column align-items-center justify-content-center rounded-pill py-2 text-light position-absolute bottom-0 end-0 m-2"
            style={{
              backgroundColor: "rgba(0, 0, 0, 0.6)",
            }}
          >
           
              <Link
                className="text-center text-light"
                onClick={handleDismiss}
                onMouseLeave={handleDismiss}
                onMouseEnter={() => setShowToolTipLike(true)}
              >
                <AddOrRemoveLike
                  user={user}
                  product={product}
                  setLikeCount={setLikeCount}
                  componentName={"strip2"}
                />
                <div style={{ fontSize: "10px", marginTop: "-4px" }}>
                  {likeCount}
                </div>
              </Link>
      
            <Link className="text-center d-flex flex-column nav-link" to="">
              <i className="bi bi-eye"></i>
              <div
                style={{
                  fontSize: "10px",
                  marginTop: "-5px",
                  marginBottom: "-2px",
                }}
              >
                {product?.views ?? 0}
              </div>
            </Link>
           
                <Link
                className="text-center text-light"
                onClick={handleDismiss}
                onMouseLeave={handleDismiss}
                onMouseEnter={() => setShowToolTipFavorites(true)}
              >
                <AddOrRemoveFavourites product={product} user={user} setFavouritesCount={setFavouritesCount}/>
                <div style={{ fontSize: "10px", marginTop: "-7px" }}>
                  {favouritesCount ?? 0}
                </div>
              </Link>
           
              <span
                onClick={handleDismiss}
                onMouseLeave={handleDismiss}
                onMouseEnter={() => setShowToolTipShare(true)}
              >
                <ShareMenu2 product={product} />
              </span>
          
              <span
                onClick={handleDismiss}
                onMouseLeave={handleDismiss}
                onMouseEnter={() => setShowToolTipAddToList(true)}
              >
                <AddToList product={product} componentName={"strip2"} />
              </span>
           
              <span
                onClick={handleDismiss}
                onMouseLeave={handleDismiss}
                onMouseEnter={() => setShowToolTipAddToList(true)}
              >
                <AddToChat product={product}/>
              </span>
         
          </div>
        )}
      </Card>

      <div className="pe-1 pb-1">
        <div className="d-flex align-items-center">
          {/* Logo */}
          <div
            className="avatar me-2"
            style={{ width: "35px", height: "35px" }}
          >
            <Link to={"/BrandHome/" + product?.supplier}>
              <img
                className="avatar-img rounded"
                src={brandData?.logo}
                alt=""
              />
            </Link>
          </div>
          <div
            className="d-flex flex-column justify-content-start"
            style={{ width: "78%" }}
          >
            <div className="mb-0">
              <BrandDisplay
                brandData={brandData}
                post={product}
                type={"ProductCard"}
              />
            </div>
            <div
              className="fw-bold h6 position-relative"
              style={{
                marginTop: "2px",
                fontSize: "12px",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              {product?.name}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCard5;
