import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc, updateDoc, serverTimestamp, deleteDoc } from 'firebase/firestore';
import { db } from '../FirebaseConfig';
import { UserAuth } from '../Context';
import { toast } from 'react-toastify';
import { toastOptions } from '../lib/toastOptions';
import BrandPostForm from './CreateBrandPost';

function BrandHandlerSettings() {
    const { user } = UserAuth();
    const [brandHandlers, setBrandHandlers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [activeBrand, setActiveBrand] = useState(null);

    useEffect(() => {
        const fetchBrandHandlers = async () => {
            if (!user) {
                setError('User must be logged in');
                setLoading(false);
                return;
            }

            const handlerRef = collection(db, 'users', user.uid, 'brandHandler');
            try {
                const snapshot = await getDocs(handlerRef);
                const handlers = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setBrandHandlers(handlers);
            } catch (err) {
                console.error("Error fetching brand handlers:", err);
                setError("Failed to fetch brand handlers.");
            }
            setLoading(false);
        };

        fetchBrandHandlers();
    }, [user]);

    const handleUpdateStatus = async (brandName, newStatus) => {
        const handlerRef = doc(db, 'users', user.uid, 'brandHandler', brandName);
        const handlerBrandRef = doc(db, 'brands', brandName, 'brandHandler', user.uid);
        try {
            if (newStatus === 'rejected') {
                await deleteDoc(handlerBrandRef);
                await deleteDoc(handlerRef);
                setBrandHandlers(prev => prev.filter(bh => bh.id !== brandName));
                toast.warn(`You have been removed as brand handler for ${brandName}`, toastOptions);
            } else {
                const updatedFields = {
                    status: newStatus,
                    timestamp: serverTimestamp()
                };
                await updateDoc(handlerBrandRef, updatedFields);
                await updateDoc(handlerRef, updatedFields);
                setBrandHandlers(prev => prev.map(bh => bh.id === brandName ? { ...bh, ...updatedFields } : bh));
                toast.success(`You have been added as brand handler for ${brandName}`, toastOptions);
            }
        } catch (err) {
            console.error("Error updating status:", err);
            toast.error("Failed to update status.", toastOptions);
        }
    };

    function getStatusMessage(status) {
        switch (status) {
            case 'accepted':
                return 'Invite accepted!';
            case 'pending':
                return 'Brand handler invite pending';
            case 'cancelled':
                return 'Invite removed by admin';
            default:
                return 'Status Unknown'; 
        }
    }

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error}</p>;

    const handleCreatePosts = (brandName) => {
        setActiveBrand(brandName);
        setShowModal(true);
    };

    return (
        <>
        {showModal && <BrandPostForm brandName={activeBrand} UserID={user.uid} closeModal={() => setShowModal(false)} />}
        <div className='card'>
            {brandHandlers.length > 0 ? brandHandlers.map((handler) => (
                <div className='card m-2' key={handler.id}>
                    <h5 className='p-2'>Brand Handler Settings for {handler.id}</h5>
                    <p className='p-2'>Current status: {getStatusMessage(handler.status)}</p>
                    <div className='d-flex justify-content-start p-2'>
                        
                        <button 
                            className="btn btn-primary me-2 btn-sm"
                            onClick={() => handleUpdateStatus(handler.id, 'accepted')}
                            disabled={handler.status === 'accepted' || handler.status === 'cancelled'}
                            style={{ display: handler.status === 'accepted' ? 'none' : 'block' }} >
                            Accept
                        </button>
                        {handler.status === 'accepted' && (
                                <button 
                                className="btn btn-success me-2 btn-sm"
                                onClick={() => handleCreatePosts(handler.id)}
                                style={{ display: handler.status === 'accepted' ? 'block' : 'none' }}>
                                Create Posts
                            </button>
                        )}
                        
                        <button 
                            className="btn btn-danger me-2 btn-sm" style={{ marginLeft: '10px' }}
                            onClick={() => handleUpdateStatus(handler.id, 'rejected')}
                            disabled={handler.status === 'rejected' || handler.status === 'cancelled'}>
                            {handler.status === 'accepted' ? 'Remove' : 'Reject'}
                        </button>
                    </div>
                </div>
            )) : <h3>No brand handlers Invites found.</h3>}
        </div>
        </>
    );
}

export default BrandHandlerSettings;
