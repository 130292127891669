import React, { useEffect, useState } from "react";
import { UserAuth } from "../Context";
import formatTimestamp from "../lib/formatTimestamp";
import ProfilePicture from "../UserProfile/ProfilePicture";
import useFollowUser from "../hooks/useFollowUser";

function UserFollowedMe({ notification, firstUserData }) {

  const { user } = UserAuth();
  const [isSeen, setIsSeen] = useState(notification.seen);
  const { isFollowed, isFollowBack, followUser, unfollowUser } = useFollowUser(user?.uid, firstUserData?.userId);
  
  return (
    <li>
      <div
        className={`d-flex m-1 `}
        style={{ display: "inline-flex", gap: "6px", cursor: "pointer" }}
      >
        <div class="avatar text-center d-none d-sm-inline-block">
        <ProfilePicture userData={firstUserData} className='avatar-img rounded' />
        </div>
        <div className={`card w-100 d-flex align-items-center justify-content-center ${
          isSeen ? '' : 'badge-unread2'
        } `}>
        <div className="ms-2 w-100 d-flex justify-content-between align-items-center p-1">
        <div className="d-flex flex-grow-1">
            <p class="small mb-2">
              <b>{firstUserData?.userName}</b> started following you.   

              <button onClick={isFollowed ? unfollowUser : followUser}
          variant={isFollowed ? 'danger' : 'primary'}class="btn btn-sm py-1 btn-primary mt-1"> {isFollowed ? 'Following' : isFollowBack ? 'Follow back' : 'Follow'} </button>
            </p>
            <p class="small me-3">{formatTimestamp(notification.timestamp)}</p>
          </div>
          </div>
        </div>
      </div>
    </li>
  );
}

export default UserFollowedMe;
